export default {
    "iot-management": "GESTIÓN DEL IOT",
    "iot-dashboard": "Tablero de mando de IOT",
    "devices": "Dispositivos",
    "device-groups": "Grupos de dispositivos",
    "rules": "Reglas",
    "products": "PRODUCTOS",
    "products": "Productos",
    "categories": "Categorías",
    "dispatch": "DESPACHO",
    "dispatch": "Despacho",
    "incoming-dispatch": "Envío entrante",
    "transaction-history": "Historial de transacciones",
    "qr-scanner": "Escáner QR",
    "farm-management": "GESTIÓN DE GRANJAS",
    "farm-map": "Mapa de la granja",
    "fields-/-paddock-list": "Lista de campos y praderas",
    "livestock-list": "Lista de ganado",
    "actions-list": "Lista de acciones",
    "reports": "Informes",
    "settings": "AJUSTES",
    "teams": "Equipos",
    "settings": "Ajustes",
    "tutorial": "Tutorial",
    "support": "Soporte",
    "company": "Empresa",
    "settings": "Configuración",
    "sign-out": "Cerrar sesión",
    "rows-per-page": "Filas por página",
    "prev": "Anterior",
    "next": "Siguiente",
    "add-iot-device": "Añadir dispositivo IOT",
    "all-categories": "Todas las categorías",
    // "device-info":	"Información del dispositivo",
    "alert": "Alerta",
    "settings": "Configuración",
    "report": "Informe",
    // "device-info":	"Información del dispositivo",
    "search-settings": "Ajustes de búsqueda",
    "location": "Ubicación",
    "groups": "Grupos",
    "status": "Estado",
    "date-from": "Fecha desde",
    "date-to": "Fecha hasta",
    "reset-all-filters": "Restablecer todos los filtros",
    "apply": "Aplicar",
    "devices": "Dispositivos",
    "search": "Buscar en",
    "categories": "Categorías",
    "locations": "Ubicaciones",
    "status": "Estado",
    "type": "Tipo",
    "map": "Mapa",
    "device-name": "Nombre del dispositivo",
    "category": "Categoría",
    "date-of-purchase": "Fecha de compra",
    "type": "Tipo",
    "location": "Ubicación",
    "device-info": "Info del sensor",
    "rows-per-page": "Filas por página",
    "prev": "Anterior",
    "next": "Siguiente",
    "add-iot-device": "Añadir dispositivo IOT",
    "device-name": "Nombre del dispositivo",
    "device-type": "Tipo de dispositivo",
    "device-category": "Categoría de dispositivo",
    "description": "Descripción",
    "date-of-manufacture": "Fecha de fabricación",
    "date-of-purchase": "Fecha de compra",
    "device-id": "Identificación del dispositivo",
    "device-groups": "Grupos de dispositivos",
    "add-group": "Añadir Grupo",
    "name": "Nombre",
    "iot-devices": "Dispositivos IOT",
    "actions": "Acciones",
    "rename-group": "Cambiar el nombre del grupo",
    "edit-group": "Editar grupo",
    "report": "Informe",
    "add-a-group": "Añadir un grupo",
    "set-up-a-rule-for-each-of-the-onboarded-iot-devices.": "Establezca una regla para cada uno de los dispositivos IOT incorporados.",
    "group-name": "Nombre del grupo",
    "select-devices": "Dispositivos seleccionados",
    "selected-devices": "Dispositivos seleccionados",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Para configurar las reglas es necesario seleccionar algunos de los dispositivos IOT incorporados.",
    "previous": "Anterior",
    "cancel": "Cancelar",
    "submit": "Enviar",
    "rules": "Reglas",
    "add-a-rule": "Añadir una regla",
    "rule": "Regla",
    "iot-devices": "Dispositivos IOT",
    "actions": "Acciones",
    "enabled": "Activado",
    "disabled": "Desactivado",
    "add-a-rule": "Añadir una regla",
    "alert-me-when-all-the-selected-conditions-are-satisfied:": "Alertarme cuando se cumplan todas las condiciones seleccionadas:",
    "basic-info": "Información básica",
    "basic-setting-for-a-rule": "Configuración básica de una regla",
    "name": "Nombre",
    "selected-devices": "Dispositivos seleccionados",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Para configurar las reglas es necesario seleccionar algunos de los dispositivos IOT incorporados.",
    "behaviour": "Comportamiento",
    "specify-how-your-devices-should-behave-on-triger.": "Especifique cómo deben comportarse sus dispositivos en el triger.",
    "add-behaviour": "Añadir comportamiento",
    "observation-period": "Período de observación",
    "parameter": "Parámetro",
    "behaviour": "Comportamiento",
    "operator": "Operador",
    "value": "Valor",
    "alert-actions": "Acciones de alerta",
    "select-what-action-your-rule-should-take": "Seleccione la acción que debe realizar su regla",
    "email": "Correo electrónico",
    "display-name": "Nombre de la pantalla",
    "temperature-warning": "Advertencia de temperatura",
    "note": "Nota",
    "you-should-check-the-device!": "Debe comprobar el dispositivo.",
    "cancel": "Cancelar",
    "submit": "Enviar",
    "dashboard": "Panel de control",
    "machine-to-machine": "Máquina a máquina",
    "select-device": "Seleccione el dispositivo",
    "select-option": "Seleccionar opción",
    "action": "Acción",
    "previous": "Anterior",
    "create-a-rule": "Crear una regla",
    "products": "Productos",
    "add-a-product": "Añadir un producto",
    "search-by-name,-id,-rfid,-serial#...": "Buscar por nombre, ID, RFID, número de serie...",
    "product-name": "Nombre del producto",
    "category": "Categoría",
    "parent-product": "Producto principal",
    "serial": "Número de serie",
    "location": "Ubicación",
    "device": "Dispositivo",
    "status": "Estado",
    "manufacturing-date": "Fecha de fabricación",
    "expiry-date": "Fecha de caducidad",
    "add-a-product": "Añadir un producto",
    "fill-in-information-to-add-new-product": "Rellene la información para añadir un nuevo producto",
    "basic-info": "Información básica",
    "category": "Categoría",
    "select-option": "Seleccione una opción",
    "product-name": "Nombre del producto",
    "untitled-product": "Producto sin título",
    "quantity": "Cantidad",
    "units": "Unidades",
    "description": "Descripción",
    "write-product-description-here...": "Escriba aquí la descripción del producto...",
    "media": "Medios de comunicación",
    "drag-&-drop-or-browse-to-choose-a-file": "Arrastre y suelte o busque para elegir un archivo",
    "manufacture-info": "Información de fabricación",
    "serial-number": "Número de serie",
    "manufacturing-date": "Fecha de fabricación",
    "expiry-date": "Fecha de caducidad",
    "invoice-number": "Número de factura",
    "purchase-order": "Orden de compra",
    "rfid-number": "Número de RFID",
    "batch-id": "ID de lote",
    "location": "Ubicación",
    "assign-device": "Asignar dispositivo",
    "here-you-can-find-devices-assigned-to-your-product": "Aquí puede encontrar los dispositivos asignados a su producto",
    "parent-products": "Productos padre",
    "here-you-can-find-parent-products-assigned-to-your-product": "Aquí puede encontrar los productos principales asignados a su producto",
    "type-of-event": "Tipo de evento",
    "farm": "Granja",
    "link-to-a-farm": "Vincular a una granja",
    "here-you-can-link-farm-items-to-a-product": "Aquí puede vincular los artículos de la granja a un producto",
    "please-link-farm-items-to-a-product": "Por favor, vincule los artículos de la granja a un producto",
    "add-herd": "Añadir rebaño",
    "add-building": "Añadir edificio",
    "add-field": "Añadir campo",
    "add-paddock": "Añadir prado",
    "name": "Nombre",
    "type": "Tipo",
    "total-area": "Superficie total",
    "land-use": "Uso de la tierra",
    "crop-type": "Tipo de cultivo",
    "colour-on-map": "Color en el mapa",
    "qr-code-settings": "Configuración del código QR",
    "company-data": "Datos de la empresa",
    "select-info-that-you-want-to-show-in-qr-code": "Seleccione la información que desea mostrar en el código QR",
    "location": "Ubicación",
    "documentation": "Documentación",
    "production-area": "Área de producción",
    "productivity": "Productividad",
    "history-of-company": "Historia de la empresa",
    "year-of-foundation": "Año de fundación",
    "soils": "Suelos",
    "certifications": "Certificaciones",
    "media": "Medios de comunicación",
    "production-per-year": "Producción por año",
    "cellar-work": "Trabajo en la bodega",
    "philosophy": "Filosofía",
    "assign-devices": "Asignar dispositivos",
    "here-you-can-find-devices-assigned-to-your-product": "Aquí puede encontrar los dispositivos asignados a su producto",
    "product-chain-data": "Datos de la cadena de productos",
    "select-info-that-you-want-to-show-in-qr-code": "Seleccione la información que desea mostrar en el código QR",
    "datetime-creation": "Fecha de creación",
    "datetime-receive": "Fecha de recepción",
    "parent-products": "Productos padres",
    "smart-conditions": "Condiciones inteligentes",
    "rules-business-conditions": "Reglas de las condiciones comerciales",
    "datetime-return": "Fecha de retorno",
    "list-of-dispatches": "Lista de envíos",
    "datetime-dispatch": "Despacho datetime",
    "basic-info": "Información básica",
    "linked-iot-devices": "Dispositivos IOT vinculados",
    "actions-info": "Información de acciones",
    "create-a-product": "Crear un producto",
    "categories": "Categorías",
    "sort-by": "Ordenar por",
    "category": "Categoría",
    "number-of-products": "Número de productos",
    "last-update-date": "Fecha de la última actualización",
    "date-of-creation": "Fecha de creación",
    "add-products": "Añadir productos",
    "add-a-category": "Añadir una categoría",
    "here-you-can-create-new-category-and-add-products": "Aquí puede crear una nueva categoría y añadir productos",
    "basic-info": "Información básica",
    "category-name": "Nombre de la categoría",
    "selected-products": "Productos seleccionados",
    "to-create-a-category-you-need-to-select-some-products.": "Para crear una categoría necesita seleccionar algunos productos.",
    "add-new-product": "Añadir nuevo producto",
    "dispatch": "Despacho",
    "create-a-dispatch": "Crear un despacho",
    "date-of-creation": "Fecha de creación",
    "dispatch-id": "ID del envío",
    "status": "Estado",
    "owner": "Propietario",
    "no.-batch": "Número de lote",
    "no.-products": "Nº de productos",
    "smart-condition-status": "Estado de la condición inteligente",
    "business-condition-status": "Estado de la condición comercial",
    "view-the-dispatch": "Ver el Despacho",
    "delete-the-dispatch": "Borrar el Despacho",
    "create-a-dispatch": "Crear un despacho",
    "here-you-can-create-dispatch-for-your-products": "Aquí puede crear un despacho para sus productos",
    "basic-info": "Información básica",
    "dispatch-id": "ID del envío",
    "dispatch-documents": "Documentos del envío",
    "list-of-documents-attached-to-despatch": "Lista de documentos adjuntos al envío",
    "add-document": "Añadir documento",
    "select-companies": "Seleccionar empresas",
    "add-various-companies-to-your-dispatch": "Añadir varias empresas a su envío",
    "add-companies": "Añadir empresas",
    "products": "Productos",
    "dispatch-products": "Productos del despacho",
    "list-of-batches-and-products-attached-to-dispatch": "Lista de lotes y productos adjuntos a la expedición",
    "csv-example": "Ejemplo de CSV",
    "upload-csv": "Cargar CSV",
    "add-batch": "Añadir lote",
    "contract-conditions": "Condiciones del contrato",
    "add-conditions": "Añadir condiciones",
    "add-conditions-to-your-contract-dispatch": "Añada condiciones a su envío de contratos",
    "add-smart-condition": "Añadir condición inteligente",
    "select-batch-id": "Seleccionar ID de lote",
    "select-device": "Seleccione el dispositivo",
    "parameter-observed": "Parámetro observado",
    "operator": "Operador",
    "value": "Valor",
    "enter-number...": "Introduzca el número...",
    "from-date": "Desde la fecha",
    "from-time": "Desde la hora",
    "to-date": "Hasta la fecha",
    "to-time": "A la hora",
    "add-business-condition": "Añadir condición comercial",
    "type-1": "Tipo 1",
    "event-to-be-monitored": "Evento a supervisar",
    "description-of-certifications-required...": "Descripción de las certificaciones requeridas...",
    "event-to-be-monitored": "Evento a supervisar",
    "incoming-dispatch": "Envío entrante",
    "no-incoming-dispatches-yet!": "Todavía no hay despachos entrantes.",
    "transaction-history": "Historial de transacciones",
    "product-name": "Nombre del producto",
    "quantity/units": "Cantidad/unidades",
    "batch-id": "ID de lote",
    "name-of-the-dispatch": "Nombre del envío",
    "date": "Fecha",
    "type": "Tipo",
    "conditions": "Condiciones",
    "status-of-the-product": "Estado del producto",
    "sort-by": "Ordenar por",
    "qr-scanner": "Escáner QR",
    "qr-code-scanner": "Escáner de códigos QR",
    "here-you-can-scan-your-enismaro-qr-code": "Aquí puede escanear su código QR de Enismaro",
    "start-cam-scanner": "Iniciar el escáner de levas",
    "stop-cam-scanner": "Detener el escáner de levas",
    "scan-from--file": "Escanear desde un archivo",
    "farm-map": "Mapa de la granja",
    "filter": "Filtrar",
    "tool-/-equipment": "Herramienta / Equipo",
    "herd-zones": "Zonas de rebaño",
    "building-zones": "Zonas de construcción",
    "infrastructure-zones": "Zonas de infraestructura",
    "field-zones": "Zonas de campo",
    "paddock-zones": "Zonas de prado",
    "add-building-/-infrastructure": "Añadir edificio/infraestructura",
    "add-paddock-": "Añadir prado",
    "add-animal-": "Añadir animal",
    "add-tool-/-equipment": "Añadir herramienta/equipo",
    "basic-info": "Información básica",
    "type": "Tipo",
    "select-option": "Seleccione una opción",
    "name": "Nombre",
    "description": "Descripción",
    "write-description-here...": "Escriba la descripción aquí...",
    "position": "Posición",
    "brand": "Marca",
    "model": "Modelo",
    "working-width-(meters)": "Ancho de trabajo (metros)",
    "power-(kw-or-hp)": "Potencia (kw o hp)",
    "production-year": "Año de producción",
    "plate-number": "Número de placa",
    "frame-number": "Número de bastidor",
    "date-of-purchase": "Fecha de compra",
    "revision-date": "Fecha de revisión",
    "date-of-technical-check": "Fecha del control técnico",
    "registration-document": "Documento de matriculación",
    "attach-file": "Adjuntar archivo",
    "insurance-document": "Documento de seguro",
    "user-manual": "Manual de usuario",
    "photo": "Foto",
    "drag-&-drop-or-browse-to-choose-a-file": "Arrastre y suelte o busque para elegir un archivo",
    "add-point": "Añadir punto",
    "add-field": "Añadir campo",
    "draw-and-describe-your-field-on-map": "Dibuje y describa su campo en el mapa",
    "borders-definition": "Definición de bordes",
    "pivot-area": "Área de pivote",
    "map": "Mapa",
    "basic-info": "Información básica",
    "field-name": "Nombre del campo",
    "total-area": "Área total",
    "colour-on-map": "Color en el mapa",
    "crop": "Cultivo",
    "variety": "Variedad",
    "crop-year": "Año de cultivo",
    "description": "Descripción",
    "land-register": "Registro de tierras",
    "add-cadastral-parcel": "Añadir parcela catastral",
    "country": "País",
    "city": "Ciudad",
    "sheet-land-registry": "Hoja de registro de la propiedad",
    "particle-land-registry": "Parcela catastral",
    "area-land-registry": "Registro de la propiedad de la zona",
    "actions": "Acciones",
    "soil": "Suelo",
    "soil-map": "Mapa del suelo",
    "granulometry": "Granulometría",
    "%-sand": "% Arena",
    "%-silt": "% Limo",
    "%-clay": "% Arcilla",
    "ph": "pH",
    "carbonates-%": "Carbonatos %",
    "organic-substance-%": "Sustancia orgánica %",
    "organic-carbon-%": "Carbono orgánico %",
    "mineral-nitrogen": "Nitrógeno mineral",
    "assign-devices": "Asignar dispositivos",
    "here-you-can-find-devices-assigned-to-your-field": "Aquí puede encontrar los dispositivos asignados a su campo",
    "add-building-/-infrastructure": "Añadir edificio/infraestructura",
    "draw-and-describe-your-building-/-infrastructure-on-map": "Dibuje y describa su edificio / infraestructura en el mapa",
    "basic-info": "Información básica",
    "name-of-the-building-/-infrastructure": "Nombre del edificio/infraestructura",
    "type-of-building-/-infrastructure": "Tipo de edificio / infraestructura",
    "colour-on-map": "Color en el mapa",
    "description": "Descripción",
    "add-paddock": "Añadir un prado",
    "draw-and-describe-your-paddock-on-map": "Dibuja y describe tu potrero en el mapa",
    "basic-info": "Información básica",
    "paddock-name": "Nombre del potrero",
    "total-area": "Superficie total",
    "land-use": "Uso del terreno",
    "colour-on-map": "Color en el mapa",
    "crop": "Cultivo",
    "variety": "Variedad",
    "crop-year": "Año de cultivo",
    "add-animal": "Añade un animal",
    "draw-and-describe-your-animal-on-map": "Dibuja y describe tu animal en el mapa",
    "basic-info": "Información básica",
    "name-of-the-animal": "Nombre del animal",
    "type-of-animal": "Tipo de animal",
    "breed": "Raza",
    "status": "Estado",
    "sex": "Sexo",
    "tag-number": "Número de etiqueta",
    "date-of-birth": "Fecha de nacimiento",
    "birth-weight": "Peso al nacer",
    "raised-or-purchased": "Criado o comprado",
    "disposal": "Eliminación",
    "assign-paddock-/-building-/-field": "Asignación de potrero/edificio/campo",
    "here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your-herd": "Aquí puede encontrar los potreros / edificios / campos asignados a su rebaño",
    "fields-/-paddock-list": "Campos / Lista de Paddock",
    "date-creation-(from)": "Fecha de creación (desde)",
    "date-creation-(to)": "Fecha de creación (hasta)",
    "livestock-list": "Lista de ganado",
    "actions-list": "Lista de acciones",
    "reports": "Informes",
    "herd-movement-records": "Registros de movimientos del rebaño",
    "feed-records": "Registros de alimentación",
    "edx-analysis": "Análisis EDX",
    "treatments/action-details": "Detalles de los tratamientos/acciones",
    "this-report-is-empty": "Este informe está vacío",
    "create-edx-report": "Crear informe EDX",
    "edx-analysis-create": "Crear análisis EDX",
    "add--entry": "Añadir entrada",
    "name": "Nombre",
    "vet1-map-sum-spectrum": "VET1 Map Sum Spectrum",
    "line-type": "Tipo de línea",
    "apparent-concentration": "Concentración aparente",
    "k-ratio": "Relación k",
    "wt%": "Wt%",
    "wt%-sigma": "Wt% Sigma",
    "standard-label": "Etiqueta estándar",
    "actions": "Acciones",
    "report-name": "Nombre del informe",
    "image": "Imagen",
    "comments": "Comentarios",
    "add-comment": "Añadir comentario",
    "comment-decsription": "Descripción del comentario",
    "no-data": "No hay datos",
    "field-logbook": "Cuaderno de campo",
    "date": "Fecha",
    "name-of-the-operation": "Nombre de la operación",
    "name-of-the-product": "Nombre del producto",
    "weight-": "Peso",
    "size": "Tamaño",
    "cultivation-type": "Tipo de cultivo",
    "adversity": "Adversidad",
    "field-details": "Detalles del campo",
    "paddock-details": "Detalles del prado",
    "building-details": "Detalles del edificio",
    "iot-sensors": "Sensores IOT",
    "custom-reports": "Informes personalizados",
    "create-custom-report": "Crear informe personalizado",
    "add-column": "Añadir columna",
    "add-entry": "Añadir entrada",
    "report-name": "Nombre del informe",
    "actions": "Acciones",
    "teams": "Equipos",
    "add-user": "Añadir usuario",
    "role": "Función",
    "grants": "Subvenciones",
    "status": "Estado",
    "add-a-user": "Añadir un usuario",
    "basic-info": "Información básica",
    "add-a-temporal-validity": "Añadir una validez temporal",
    "grants": "Subvenciones",
    "all": "Todo",
    "iot-management": "Gestión del IOT",
    "products": "Productos",
    "dispatch": "Despacho",
    "farm-management": "Gestión de fincas",
    "location": "Ubicación",
    "assign-device": "Asignar dispositivo",
    "settings": "Configuración",
    "account-settings": "Configuración de la cuenta",
    "profile-photo": "Foto de perfil",
    "upload": "Subir",
    "remove": "Eliminar",
    "full-name": "Nombre completo",
    "password": "Contraseña",
    "change-password": "Cambiar contraseña",
    "old-password": "Contraseña antigua",
    "new-password": "Nueva contraseña",
    "repeat-new-password": "Repita la nueva contraseña",
    "confirm": "Confirmar",
    "delete-account": "Eliminar la cuenta",
    "by-deleting-your-account-you-will-lose-all-your-data": "Al eliminar su cuenta perderá todos sus datos",
    "do-you-confirm-delete-for-your-account?": "¿Confirma la eliminación de su cuenta?",
    "company-info": "Información de la empresa",
    "company-photo": "Foto de la empresa",
    "company-name": "Nombre de la empresa",
    "year-of-foundation": "Año de fundación",
    "website": "Página web",
    "history-of-the-company": "Historia de la empresa",
    "product-&-farm-info": "Información sobre el producto y la explotación",
    "production-/-year": "Producción / año",
    "production-area": "Zona de producción",
    "soils": "Suelos",
    "cellar-work": "Trabajo en la bodega",
    "productivity": "Productividad",
    "philosophy": "Filosofía",
    "vat-number": "Número de IVA",
    "fiscal-code": "Código fiscal",
    "location": "Ubicación",
    "country": "País",
    "city": "Ciudad",
    "province": "Provincia",
    "certifications": "Certificaciones",
    "download": "Descargar",
    "documentation": "Documentación",
    "media": "Medios de comunicación",
    "user-management": "Gestión de usuarios",
    "plan-&-billing": "Plan y facturación",
    "payment-method": "Método de pago",
    "expires": "Expira",
    "your-plan": "Su plan",
    "see-plan-details": "Ver detalles del plan",
    "upgrade-plan": "Actualizar plan",
    "cancel-plan": "Cancelar plan",
    "iot-dashboard": "Tablero IOT",
    "devices": "Dispositivos",
    "device-groups": "Grupos de dispositivos",
    "rules": "Reglas",
    "products": "Productos",
    "categories": "Categorías",
    "teams": "Equipos",
    "settings": "Ajustes",
    "billing-cycles": "Ciclos de facturación",
    "next-payment": "Siguiente pago",
    "switch-billing-cycle": "Cambiar de ciclo de facturación",
    "new-to-enismaro?": "¿Eres nuevo en Enismaro?",
    "create-an-account": "Crear una cuenta",
    "login": "Iniciar sesión",
    "password": "Contraseña",
    "forgot-your-password?": "¿Olvidó su contraseña?",
    "all-rights-reserved": "Todos los derechos reservados",
    "terms": "Condiciones",
    "privacy": "Privacidad",
    "security": "Seguridad",
    "support": "Apoye a",
    "create-an-account": "Crear una cuenta",
    "confirm-password": "Confirmar contraseña",
    "sign-up": "Registrarse",
    "already-have-an-account?": "¿Ya tiene una cuenta?",
    "email-recepients": "Correo electrónico recepciones"
}