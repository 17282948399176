<template>
	<div>
		<slot name="auth-header"></slot>

		<Spinner v-if="loading" />

		<div class="wrapper_main none-padding">
			<main class="content content-center">
				<div class="container">
					<form class="renew-plan" @submit.prevent="login">
						<div class="renew-plan-header">
							<h1>{{ $translate('login') }}</h1>
						</div>				
						<div class="renew-plan-form">
							<div class="items-group cards">
								<div class="cards__col">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('email') }}</label>
										<input required type="text" class="input" v-model="userAuth.login">
									</div>
								</div>
								<div class="cards__col mt-15">
									<div class="el-form">
										<label class="el-form__title flex items-center">
											<span class="flex-auto">{{ $translate('password') }}</span> 
											<a href="#" class="form-link" @click.prevent="$emit('goto', 'forgotpassword')">{{ $translate('forgot-your-password?') }}</a>
										</label>
										<input required type="password" class="input" v-model="userAuth.pw">
									</div>
								</div>
							</div>
							<button type="submit" class="btn btn-primary btn-standart mt-30">{{ $translate('login') }}</button>
						</div>
					</form>
				</div>
			</main>
		</div>

		<slot name="auth-footer"></slot>
	</div>	
</template>

<script>
import { Auth } from 'aws-amplify';
import Base from '@/components/base';
export default {
	name: 'Login',
	components: {
		...Base
	},
	data() {
		return {
			userAuth: {
				login: '',
				pw: ''
			},
			loading: false
		}
	},
	methods: {
		async login() {
			this.loading = true;
			// TODO: validation
			try {
				const result = await Auth.signIn(this.userAuth.login, this.userAuth.pw);
				console.log(result);
			} catch(err) {
				console.log(err);
			} finally {
				this.loading = false;
				this.$emit('bridge', { login: this.userAuth.login, pw: this.userAuth.pw });
			}
		},
	},
}
</script>

<style>

</style>