import axios from 'axios';

import {
    POSITION_STACK_API_URL,
    POSITION_STACK_API_KEY
} from '@/config/';

export default class PositionStack {
    constructor() {

    }

    async getForward(query) { // Address
        const result = await axios({
            url: `${POSITION_STACK_API_URL}forward`,
            method: 'POST', // get
            data: { // params
                access_key: POSITION_STACK_API_KEY,
                query
            }
        }).then(res => res.data)

        console.log(result);
        return result
    }

    async getReverse(query) { // Cords
        const result = await axios({
            url: `${POSITION_STACK_API_URL}reverse`,
            method: 'POST', // get
            data: { // params
                access_key: POSITION_STACK_API_KEY,
                query
            }
        }).then(res => res.data)
        let firstEntry=result.data[0]
        if(firstEntry.country&&firstEntry.locality){
            firstEntry.locationName=firstEntry.country+", "+firstEntry.locality
        }
        result.data[0]=firstEntry
        console.log("getReverseTransform",result);
        // console.log(result);
        return result
    }
}