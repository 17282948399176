export default {
    "iot-management": "GESTÃO IOT ",
    "iot-dashboard": "IOT Panel de Controle",
    "devices": "Dispositivos",
    "device-groups": "Grupos de dispositivos",
    "rules": "Regras",
    "products": "PRODUTOS",
    "products": "Produtos",
    "categories": "Categorias",
    "dispatch": "ENVIO",
    "dispatch": "Envio",
    "incoming-dispatch": "Envios entrantes",
    "transaction-history": "Histórico de transações",
    "qr-scanner": "QR scanner",
    "farm-management": "FARM MANAGEMENT",
    "farm-map": "Farm Map",
    "fields-/-paddock-list": "Campos/Cercados lista",
    "livestock-list": "Gado lista",
    "actions-list": "Diário de campo",
    "reports": "Relatórios",
    "settings": "CONFIGURAÇÕES",
    "teams": "Equipes",
    "settings": "Configurações",
    "tutorial": "Tutorial",
    "support": "Assistência",
    "company": "Empresa",
    "settings": "Configurações",
    "sign-out": "Desconectar",
    "rows-per-page": "Linhas por página",
    "prev": "Prec",
    "next": "Seguinte",
    "add-iot-device": "Adicionar dispositivo IOT",
    "all-categories": "Todas Categorias",
    // "device-info":	"Info Dispos.",
    "alert": "Alerta",
    "settings": "Configurações",
    "report": "Relatorios",
    // "device-info":	"Info Dispos.",
    "search-settings": "Configurações de pesquisa",
    "location": " Localização ",
    "groups": "Groupos",
    "status": "Status",
    "date-from": "Data desde",
    "date-to": "A",
    "reset-all-filters": "Reset todos os filtros",
    "apply": "Aplicar",
    "devices": "Dispositivos",
    "search": "Pesquisa",
    "categories": "Categorias",
    "locations": "Localizações ",
    "status": "Status",
    "type": "Tipo",
    "map": "Mapa",
    "device-name": "Nome dispositivo",
    "category": "Categoria",
    "date-of-purchase": "Data da compra",
    "type": "Tipo",
    "location": "Localização ",
    "device-info": "info do sensor",
    "rows-per-page": "Linhas por página",
    "prev": "Prec",
    "next": "Seguinte",
    "add-iot-device": "Adicionar dispositivo IOT",
    "device-name": "Nome dispositivo",
    "device-type": "Tipo de dispositivo",
    "device-category": "Categoria do dispositivo",
    "description": " Descrição",
    "date-of-manufacture": "Data de fabricação",
    "date-of-purchase": "Data da compra",
    "device-id": "ID dispositivo",
    "device-groups": "Groupos dispositivos",
    "add-group": "Adicionar grupo",
    "name": "Nome",
    "iot-devices": "Dispositivo IOT",
    "actions": "Acções ",
    "rename-group": "Renomear grupo",
    "edit-group": "Modificar grupo",
    "report": "Report",
    "add-a-group": "Adicionar grupo",
    "set-up-a-rule-for-each-of-the-onboarded-iot-devices.": "Estabeleça uma regra para cada um dos dispositivos IOT instalados",
    "group-name": "Nome grupo",
    "select-devices": "Selecionar os dispositivos",
    "selected-devices": "Dispositivos selecionados",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Para estabelecer regras, é necessário selecionar os dispositivos IOT instalados",
    "previous": "Precedente",
    "cancel": "Anular",
    "submit": "Enviar",
    "rules": "Regras",
    "add-a-rule": "Adicionar uma regra",
    "rule": "Regra",
    "iot-devices": "Dispositivos IOT",
    "actions": "Acçoes",
    "enabled": "Ativado",
    "disabled": "Desativado",
    "add-a-rule": "Adicionar uma regra",
    "alert-me-when-all-the-selected-conditions-are-satisfied:": "Alerte-me quando todas as condições selecionadas estão satisfeitas:",
    "basic-info": "Basic info",
    "basic-setting-for-a-rule": "Configuração de base para uma regra",
    "name": "Nome",
    "selected-devices": "Dispositivos selecionados",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Para estabelecer regras, é necessário selecionar alguns dos dispositivos IOT instalados",
    "behaviour": "Comportamento",
    "specify-how-your-devices-should-behave-on-triger.": "Especifique como seus dispositivos devem se comportar no triger.",
    "add-behaviour": "Adicionar Comportamento",
    "observation-period": "Período de observação",
    "parameter": "Parâmetros ",
    "behaviour": "Comportamento",
    "operator": "Operador",
    "value": "Valor",
    "alert-actions": "Ações de alerta",
    "select-what-action-your-rule-should-take": "Selecione a ação que sua regra deve seguir",
    "email": "Email",
    "display-name": "Display name",
    "temperature-warning": "Aviso de temperatura",
    "note": "Notas",
    "you-should-check-the-device!": "Deveria verificar o dispositivo!",
    "cancel": "Cancelar",
    "submit": "Enviar",
    "dashboard": "Painel de instrumentos",
    "machine-to-machine": "Machine to Machine",
    "select-device": "Selecione dispositivos",
    "select-option": "Selecione Opção",
    "action": "Acçoes",
    "previous": "Precedente",
    "create-a-rule": "Criar uma regra",
    "products": "Produtos",
    "add-a-product": "Adicione um produto",
    "search-by-name,-id,-rfid,-serial#...": "Busca por nome, ID, RFID, serial#...",
    "product-name": "Nome produto",
    "category": "Categoria",
    "parent-product": "Produto de origem",
    "serial": "Serial",
    "location": "Localização",
    "device": "Dispositivo",
    "status": "Status",
    "manufacturing-date": "Data de fabricação",
    "expiry-date": "Data de expiração",
    "add-a-product": "Adicionar um produto",
    "fill-in-information-to-add-new-product": "Preencher informações para adicionar novo produto",
    "basic-info": "Basic info",
    "category": "Categoria",
    "select-option": "Selecione Opção",
    "product-name": "Nome produto",
    "untitled-product": "Produto sem título",
    "quantity": "Quantidade",
    "units": "Unidades",
    "description": "Descrição",
    "write-product-description-here...": "Escreva aqui a descrição do produto...",
    "media": "Media",
    "drag-&-drop-or-browse-to-choose-a-file": "Arraste & solte ou navegue para escolher um ficheiro",
    "manufacture-info": "Informações de fabricação",
    "serial-number": "Número de série",
    "manufacturing-date": "Data de fabricação",
    "expiry-date": "Data de expiração",
    "invoice-number": "Número da fatura",
    "purchase-order": "Pedido de compra",
    "rfid-number": "Número RFID",
    "batch-id": "ID do lote",
    "location": "Localização",
    "assign-device": "Assignar um dispositivo",
    "here-you-can-find-devices-assigned-to-your-product": "Aqui pode-se encontrar os dispositivos atribuídos ao seu produto",
    "parent-products": "Produto de origem",
    "here-you-can-find-parent-products-assigned-to-your-product": "Aqui você pode encontrar os produtos de origem atribuídos ao seu produto",
    "type-of-event": "Tipo de Evento",
    "farm": "Farm",
    "link-to-a-farm": "Link a uma fazenda",
    "here-you-can-link-farm-items-to-a-product": "Aqui é possível vincular artigos da fazenda a um produto",
    "please-link-farm-items-to-a-product": "Por favor, vincule artigos da fazenda a um produto",
    "add-herd": "Adicione um gado",
    "add-building": "Adicionar Construção",
    "add-field": "Adicionar campo",
    "add-paddock": "Adicionar um cercado",
    "name": "Nome",
    "type": "Tipo",
    "total-area": "Área total",
    "land-use": "Uso do solo",
    "crop-type": "Tipo de coltura",
    "colour-on-map": "Cor no mapa",
    "qr-code-settings": "Configurações do Código QR",
    "company-data": "Dados da empresa",
    "select-info-that-you-want-to-show-in-qr-code": "Seleccione a informação que deseja mostrar no Código QR",
    "location": "Localização",
    "documentation": "Documentação",
    "production-area": "Área de produção",
    "productivity": "Produtividade",
    "history-of-company": "História da empresa",
    "year-of-foundation": "Ano de fundação",
    "soils": "Solos",
    "certifications": "Certificações",
    "media": "Media",
    "production-per-year": "Produção por ano",
    "cellar-work": "Processos",
    "philosophy": "Filosofia",
    "assign-devices": "atribuir dispositivos",
    "here-you-can-find-devices-assigned-to-your-product": "Aqui pode encontrar os dispositivos associados ao seu produto",
    "product-chain-data": "Dados sobre a cadeia de produção",
    "select-info-that-you-want-to-show-in-qr-code": "Seleccione a informação que deseja exibir no Código QR",
    "datetime-creation": "Data e hora de criação",
    "datetime-receive": "Data e hora de recepção",
    "parent-products": "Produtos pai",
    "smart-conditions": "Smart Conditions",
    "rules-business-conditions": "Regras Business Conditions",
    "datetime-return": "Data de retorno",
    "list-of-dispatches": "Lista de expedições",
    "datetime-dispatch": "Data e hora de envio",
    "basic-info": "Basic Info",
    "linked-iot-devices": "Dispositivos IOT ligados",
    "actions-info": "Informações sobre acções",
    "create-a-product": "Criar um produto",
    "categories": "Categorias",
    "sort-by": "Classificar por",
    "category": "Categoria",
    "number-of-products": "Número de produtos",
    "last-update-date": "Data de última actualização",
    "date-of-creation": "Data de criação",
    "add-products": "Adicionar Produtos",
    "add-a-category": "Adicionar uma categoria",
    "here-you-can-create-new-category-and-add-products": "Aqui pode criar uma nova categoria e adicionar produtos",
    "basic-info": "Basic info",
    "category-name": "Nome da categoria",
    "selected-products": "Produtos seleccionados",
    "to-create-a-category-you-need-to-select-some-products.": "Para criar uma categoria, é necessário seleccionar alguns produtos.",
    "add-new-product": "Adicionar Novo Produto",
    "dispatch": "Expedição",
    "create-a-dispatch": "Criar uma Expedição",
    "date-of-creation": "Data de criação",
    "dispatch-id": "ID de expedição",
    "status": "Status",
    "owner": "Proprietário",
    "no.-batch": "N.º Lote",
    "no.-products": "N° produtos",
    "smart-condition-status": "Estado das Smart Conditions",
    "business-condition-status": "Estado das Business Conditions",
    "view-the-dispatch": "Ver a Expedição",
    "delete-the-dispatch": "Cancelar a Expedição",
    "create-a-dispatch": "Ciar uma Expedição",
    "here-you-can-create-dispatch-for-your-products": "Aqui pode criar despacho para os seus produtos",
    "basic-info": "Basic Info",
    "dispatch-id": "ID de expedição",
    "dispatch-documents": "Documentos das expedição",
    "list-of-documents-attached-to-despatch": "Lista de documentos anexos ao envio",
    "add-document": "Adicionar documento",
    "select-companies": "Seleccionar empresas",
    "add-various-companies-to-your-dispatch": "Adicione várias empresas ao seu envio",
    "add-companies": "Adicionar empresas",
    "products": "Produtos",
    "dispatch-products": "expedição produtos",
    "list-of-batches-and-products-attached-to-dispatch": "Lista de lotes e produtos ligados à expedição",
    "csv-example": "Exemplo CSV",
    "upload-csv": "Carregar CSV",
    "add-batch": "Adicionar Lote",
    "contract-conditions": "Condições contratuais",
    "add-conditions": "Adicionar condições",
    "add-conditions-to-your-contract-dispatch": "Acrescentar condições ao seu envio contratual",
    "add-smart-condition": "Adicionar uma Smart Condition",
    "select-batch-id": "Seleccione ID do lote",
    "select-device": "Seleccione dispositivo",
    "parameter-observed": "Parâmetro observado",
    "operator": "Operador",
    "value": "Valor",
    "enter-number...": "Introduza o número...",
    "from-date": "A partir de",
    "from-time": "Da hora",
    "to-date": "Até",
    "to-time": "Até",
    "add-business-condition": "Adicionar Business Condition",
    "type-1": "Tipo 1",
    "event-to-be-monitored": "Evento a observar",
    "description-of-certifications-required...": "Descrição das certificações requeridas...",
    "event-to-be-monitored": "Evento a observar",
    "incoming-dispatch": "Correio entrante",
    "no-incoming-dispatches-yet!": "Ainda não há expedições entrantes!",
    "transaction-history": "Histórico das transacções",
    "product-name": "Nome produto",
    "quantity/units": "Quantidade/unidades",
    "batch-id": "ID do Lote",
    "name-of-the-dispatch": "Nome do envio",
    "date": "Data",
    "type": "Tipo",
    "conditions": "condições",
    "status-of-the-product": "condição do produto",
    "sort-by": "ordenar por",
    "qr-scanner": "QR scanner",
    "qr-code-scanner": "QR code scanner",
    "here-you-can-scan-your-enismaro-qr-code": "Aqui pode digitalizar o seu código QR Enismaro",
    "start-cam-scanner": "Iniciar o scanner da câmara",
    "stop-cam-scanner": "Stop o scanner da camara",
    "scan-from--file": "Scan de file",
    "farm-map": "Farm mapa",
    "filter": "Filtro",
    "tool-/-equipment": "Instrumentos / Equipamento",
    "herd-zones": "Zonas onde há gado",
    "building-zones": "Zonas de construção",
    "infrastructure-zones": "Áreas de infra-estruturas",
    "field-zones": "Áreas de campo",
    "paddock-zones": "Zonas dos cercados",
    "add-building-/-infrastructure": "Adicionar edifício / infra-estrutura",
    "add-paddock-": "Adicionar cercado",
    "add-animal-": "Adicionar animais",
    "add-tool-/-equipment": "adicionar instrumento/equipamento",
    "basic-info": "Basic info",
    "type": "tipo",
    "select-option": "Seleccione a opção",
    "name": "Nome",
    "description": "Descripçao",
    "write-description-here...": "Escreva aqui a descrição...",
    "position": "Posiçao",
    "brand": "Marca",
    "model": "Modelo",
    "working-width-(meters)": "Largura de trabalho (metros)",
    "power-(kw-or-hp)": "Potência (kw ou hp)",
    "production-year": "Ano de produção",
    "plate-number": "Número da placa",
    "frame-number": "Número do quadro",
    "date-of-purchase": "Data de aquisição",
    "revision-date": "Data da revisão",
    "date-of-technical-check": "Data da inspecção técnica",
    "registration-document": "Documento de registo",
    "attach-file": "Anexar um ficheiro",
    "insurance-document": "Documento de seguro",
    "user-manual": "Manual de utilização",
    "photo": "foto",
    "drag-&-drop-or-browse-to-choose-a-file": "Arrastar e largar ou navegar para escolher um ficheiro",
    "add-point": "Acrescentar um ponto",
    "add-field": "Adicionar um campo ",
    "draw-and-describe-your-field-on-map": "Desenhe e descreva o seu campo na mapa",
    "borders-definition": "Definição de fronteiras",
    "pivot-area": "área Pivot",
    "map": "Mapa",
    "basic-info": "Basic info",
    "field-name": "Nome campo",
    "total-area": "área total",
    "colour-on-map": "cor da mapa",
    "crop": "Cultura",
    "variety": "Variedade",
    "crop-year": "Ano de colheita",
    "description": "Descrição",
    "land-register": "Registo Predial",
    "add-cadastral-parcel": "Adicionar uma parcela cadastral",
    "country": "País",
    "city": "Cidade",
    "sheet-land-registry": "Registo Predial",
    "particle-land-registry": "Registo de partículas",
    "area-land-registry": "área do Registo Predial",
    "actions": "acçao",
    "soil": "solo",
    "soil-map": "Mapa de solos",
    "granulometry": "Granulometría",
    "%-sand": "% areia",
    "%-silt": "% silte",
    "%-clay": "% barro",
    "ph": "pH",
    "carbonates-%": "carbonatos",
    "organic-substance-%": "Substância orgânica (%)",
    "organic-carbon-%": "Carbono orgânico (%)",
    "mineral-nitrogen": "Azoto mineral",
    "assign-devices": "Atribuição de dispositivos",
    "here-you-can-find-devices-assigned-to-your-field": "Aqui você pode encontrar os dispositivos designados para seu campo",
    "add-building-/-infrastructure": "Adicionar um edifício / infra-estrutura",
    "draw-and-describe-your-building-/-infrastructure-on-map": "Desenhe e descreva seu prédio / infra-estrutura em um mapa",
    "basic-info": "Basic info",
    "name-of-the-building-/-infrastructure": "Nome edificio/infra-estrutura",
    "type-of-building-/-infrastructure": "Tipo edificio/infra-estrutura",
    "colour-on-map": "cor da mapa",
    "description": "descripçao",
    "add-paddock": "addicionar cercado",
    "draw-and-describe-your-paddock-on-map": "Desenhe e descreva seu recinto em um mapa.",
    "basic-info": "Basic info",
    "paddock-name": "Nome cercado",
    "total-area": "área total",
    "land-use": "uso do solo",
    "colour-on-map": "cor da mapa",
    "crop": "Cultura",
    "variety": "Variedade",
    "crop-year": "ano de colheita",
    "add-animal": "Adicione animais",
    "draw-and-describe-your-animal-on-map": "Desenhe e descreva seu animal na mapa",
    "basic-info": "Basic info",
    "name-of-the-animal": "Nome do animal",
    "type-of-animal": "Tipo do animal",
    "breed": "raça",
    "status": "estado",
    "sex": "gênero",
    "tag-number": "número de identificação",
    "date-of-birth": "data de nascimento",
    "birth-weight": "peso ao nascer",
    "raised-or-purchased": "Elevado ou adquirido",
    "disposal": "disposição",
    "assign-paddock-/-building-/-field": "Atribuir um recinto / edifício / campo",
    "here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your-herd": "Aqui você encontrará os recintos/construções/campos destinados a seu rebanho.",
    "fields-/-paddock-list": "Lista de campos / recintos",
    "date-creation-(from)": "Data de criação (a partir de)",
    "date-creation-(to)": "Data de criação (até)",
    "livestock-list": "Lista de gado",
    "actions-list": "Lista de ações",
    "reports": "Reports",
    "herd-movement-records": "Registros dos movimentos de gado",
    "feed-records": "Registros de alimentos",
    "edx-analysis": "Análise EDX",
    "treatments/action-details": "Detalhes do tratamento/ação",
    "this-report-is-empty": "Este reporte está vazio",
    "create-edx-report": "Criar um relatório EDX",
    "edx-analysis-create": "Criar análise EDX",
    "add--entry": "Adicione item",
    "name": "nome",
    "vet1-map-sum-spectrum": "VET1 Map Sum Spectrum",
    "line-type": "Tipo de linha",
    "apparent-concentration": "Concentração aparente",
    "k-ratio": "k Ratio",
    "wt%": "Wt%",
    "wt%-sigma": "Wt% Sigma",
    "standard-label": "Standard Label",
    "actions": "Ações",
    "report-name": "Nome reporte",
    "image": "Imagem",
    "comments": "Comentários",
    "add-comment": "Adicionar um comentário",
    "comment-decsription": "Descrição do comentário",
    "no-data": "No data",
    "field-logbook": "Diário de campo",
    "date": "Data",
    "name-of-the-operation": "Nome da operação",
    "name-of-the-product": "Nome do produto",
    "weight-": "Peso ",
    "size": "Tamanho",
    "cultivation-type": "Tipo de cultura",
    "adversity": "Adversidade",
    "field-details": "Detalhes sobre o terreno",
    "paddock-details": "Detalhes sobre o cercado",
    "building-details": "Detalhes do edifício",
    "iot-sensors": "IOT sensors",
    "custom-reports": "Relatórios personalizados",
    "create-custom-report": "Criar um relatório personalizado",
    "add-column": "Adicionar uma coluna",
    "add-entry": "Adicionar uma entrada",
    "report-name": "Nome do relatório",
    "actions": "acçoes",
    "teams": "Equipes",
    "add-user": "Adicionar um user",
    "role": "Função",
    "grants": "Perfil",
    "status": "estado",
    "add-a-user": "Adicionar um usuário",
    "basic-info": "Basic info",
    "add-a-temporal-validity": "Adicionar validade temporal",
    "grants": "Perfil",
    "all": "Todos",
    "iot-management": "Gestão do IOT",
    "products": "Produtos",
    "dispatch": "Despacho",
    "farm-management": "gestão da fazenda",
    "location": "Localização",
    "assign-device": "Atribuir um dispositivo",
    "settings": "Parâmetros",
    "account-settings": "Configurações da conta",
    "profile-photo": "Foto do perfil",
    "upload": "Upload",
    "remove": "Apagar",
    "full-name": "Nome completo",
    "password": "Palavra-passe",
    "change-password": "Editar a palavra-passe",
    "old-password": "Antiga palavra-passe",
    "new-password": "Nova palavra-passe",
    "repeat-new-password": "Repita a nova palavra-passe",
    "confirm": "Confirme",
    "delete-account": "Apagar a conta",
    "by-deleting-your-account-you-will-lose-all-your-data": "Ao apagar sua conta, você perderá todos os seus dados.",
    "do-you-confirm-delete-for-your-account?": "Você confirma a exclusão de sua conta?",
    "company-info": "Informações sobre a empresa",
    "company-photo": "Foto da empresa",
    "company-name": "Nome da empresa",
    "year-of-foundation": "Ano de fundação",
    "website": "Website",
    "history-of-the-company": "História da empresa",
    "product-&-farm-info": "Info Produtos&Fazendas",
    "production-/-year": "Produção / ano",
    "production-area": "Área de produção",
    "soils": "Solos",
    "cellar-work": "Trabalho em adega",
    "productivity": "Produtividade",
    "philosophy": "Filosofia",
    "vat-number": "Número de VAT",
    "fiscal-code": "Código fiscal",
    "location": "Localização",
    "country": "País",
    "city": "Cidade",
    "province": "Província",
    "certifications": "Certificações",
    "download": "Download",
    "documentation": "Documentação",
    "media": "Mídia",
    "user-management": "Gestão de usuários",
    "plan-&-billing": "Planejamento e faturamento",
    "payment-method": "Forma de pagamento",
    "expires": "Expiração",
    "your-plan": "Seu plano",
    "see-plan-details": "Ver detalhes do plano",
    "upgrade-plan": "Plano de atualização",
    "cancel-plan": "Cancelar o plano",
    "iot-dashboard": "Painel IOT",
    "devices": "Dispositivos",
    "device-groups": "Grupos de dispositivos",
    "rules": "Regras",
    "products": "Produtos",
    "categories": "Categorias",
    "teams": "Equipes",
    "settings": "Parâmetros",
    "billing-cycles": "Ciclos de faturamento",
    "next-payment": "Pagamento seguinte",
    "switch-billing-cycle": "Mudança do ciclo de faturamento",
    "new-to-enismaro?": "Novo no Enismaro?",
    "create-an-account": "Criar uma conta",
    "login": "Login",
    "password": "Palavra-passe",
    "forgot-your-password?": "Esqueceu sua senha?",
    "all-rights-reserved": "Todos os direitos reservados",
    "terms": "Termos de uso",
    "privacy": "Privacy",
    "security": "Segurança",
    "support": "assistência",
    "create-an-account": "Criar uma conta",
    "confirm-password": "Confirmar senha",
    "sign-up": "Inscreva-se",
    "already-have-an-account?": "Já tem uma conta?",
    "email-recepients": "Recepients de e-mail"
}