export const MAPS_API_KEY = 'AIzaSyCmqZQeQvan7nXF8TrxN_HP4MMa3YrYWc0'; // Maps, Places PROD
// export const MAPS_API_KEY = '/AIzaSyA3g4O2RDX1iQQe8_LrJiWhLl5r-u4LBbI'; // Maps, Places DEV
export const POSITION_STACK_API_KEY = 'cf543a454ed6db93f171398537a087a0';
//export const POSITION_STACK_API_URL = 'http://api.positionstack.com/v1/'; // https ?
export const POSITION_STACK_API_URL = 'https://cyje2u9j06.execute-api.eu-central-1.amazonaws.com/dev/positionstack/'; // Proxy
export const CENTAUR_API_URL = 'https://cloud.centaur.ag/api/v1';
export const XFarm_API_URL = 'https://api.xfarm.ag/api';

/*
Temperature
Soil moisture // влажность почты
Carbon dioxide (CO2)
Phosphine (PH3)
PH level
Leaf moisture // влажность листвы
Weather station
GPS tracker

purple
green
blue
orange
yellow
sky
grey
red
*/

export const devCats = [
    { id: 'temperature', name: 'Temperature', color: 'purple', centaur_type_id: 'temp', xFarmVN: 'at' },
    { id: 'soil-moisture', name: 'Soil moisture', color: 'blue', centaur_type_id: '', xFarmVN: 'sm1' },
    { id: 'leaf-moisture', name: 'Leaf moisture', color: 'green', centaur_type_id: '', xFarmVN: 'lw' },
    { id: 'carbon-dioxide', name: 'Carbon dioxide (CO2)', color: 'grey', centaur_type_id: 'co2', xFarmVN: '', centaur_types_config:['carbon-dioxide','rh','temperature']},
    { id: 'ph-level', name: 'PH level', color: 'red', centaur_type_id: 'ph3', xFarmVN: '' },
    { id: 'rh', name: 'Relative humidity', color: 'purple', centaur_type_id: 'rh', xFarmVN: '' },
    { id: 'phosphine', name: 'Phosphine (PH3)', color: 'green', centaur_type_id: '', xFarmVN: '', centaur_types_config:['phosphine','temperature'] },
    { id: 'weather-station', name: 'Weather station', color: 'orange', centaur_type_id: '', xFarmVN: '' },
    { id: 'gps-tracker', name: 'GPS tracker', color: 'yellow', centaur_type_id: '', xFarmVN: '' },
    //new cats
    { id: 'soil-moisture-teros-10', name: 'Soil moistureTeros 10', color: 'blue', centaur_type_id: '', xFarmVN: '' },
    { id: 'temperature-humidity', name: 'Temperature & Humidity', color: 'purple', centaur_type_id: '', xFarmVN: '' },
    { id: 'ah', name: 'Air Humidity', color: 'purple', centaur_type_id: 'ah', xFarmVN: '' },
    { id: 'leaf-moisture-phytos-31', name: 'Leaf moisture Phytos 31', color: 'green', centaur_type_id: '', xFarmVN: '' },
    { id: 'weather-station-xsense', name: 'Weather station xSense PRO Davis', color: 'orange', centaur_type_id: '', xFarmVN: '' },
    { id: 'rain-meter', name: 'Rain meter', color: 'blue', centaur_type_id: '', xFarmVN: 'rm' },
    { id: 'average-wind-speed', name: 'Average wind speed', color: 'blue', centaur_type_id: '', xFarmVN: 'aws' },
    { id: 'max-wind-speed', name: 'Max wind speed', color: 'blue', centaur_type_id: '', xFarmVN: 'mws' },
    { id: 'solar-radiation', name: 'Solar Radiation', color: 'yellow', centaur_type_id: '', xFarmVN: 'sr' },
    { id: 'dew-point-temp', name: 'Dew point temp', color: 'purple', centaur_type_id: '', xFarmVN: 'atd' },
    { id: 'air-humidity', name: 'Air humidity', color: 'blue', centaur_type_id: '', xFarmVN: 'ah' },
    { id: 'dynamo-db-device', name: 'Dynamo DB Device', color: 'black', centaur_type_id: '',  },
]

export const devicesDataCategories = {
    "temperature": {
        category: "temp",
        unit: "°C",
        title: 'Temperature'
    },
    "temp": {
        category: "temp",
        unit: "°C",
        title: 'Temperature'
    },
    "dew-point-temp": {
        category: 'dew-point-temp',
        unit: "°C",
        title: 'Dew point temp', 
    },
    "temperature-humidity": {
        category: "temperature-humidity",
        unit: "°C",
        title: 'Temperature & humidity'
    },
    "phosphine": {
        category: "ph3",
        unit: "ph3",
        title: 'Phosphine'
    },
    "carbon-dioxide": {
        category: "co2",
        unit: "ppm",
        title: 'Co2'
    },
    "co2": {
        category: "co2",
        unit: "ppm",
        title: 'Co2'
    },
    "rh": {
        category: "rh",
        unit: "%",
        title: 'Relative humidity'
    },
    "ah": {
        category: "ah",
        unit: "%",
        title: 'Air humidity'
    },
    "leaf-moisture": {
        category: "leaf-moisture",
        unit: "mV",
        title: 'Leaf moisture'
    },
    "soil-moisture": {
        category: "soil-moisture",
        unit: "%",
        title: 'Soil moisture'
    },
    "weather-station-xsense": {
        category: "soil-moisture",
        unit: "°C",
        title: 'Weather station xSense PRO Davis'
    },
    "rain-meter": {
        category: "rain-meter",
        unit: "mm",
        title: 'Rain meter'
    },
    "average-wind-speed": {
        category: "average-wind-speed",
        unit: "km/h",
        title: 'Average wind speed'
    },
    "max-wind-speed": {
        category: "max-wind-speed",
        unit: "km/h",
        title: 'Max wind speed'
    },
    "solar-radiation": {
        category: "solar-radiation",
        unit: "W/m2",
        title: 'Solar Radiation'
    },
}

export const devTypes = [
    { id: 'sensor', name: 'Sensor' },
    { id: 'gateway', name: 'Gateway' }
]

export const devTypesConfigs = {
    sensor: [
        { field: 'device_id', name: 'Device id' },

    ],
    gateway: [
        { field: 'getaway_id', name: 'Gateway ID' },
        { field: 'band', name: 'Frequency band (RFRegion)', options: ['EU863-870', 'US902-928', 'AU915', 'AS923-1'] }
    ]
}

export const billingPlans = {
    "BASIC": {
        features: {
            "IOT management": [
                "IOT Dashboard", "Devices", "Device Groups", "Rules",
            ],
            "Products": [
                "Products", "Categories",
            ],
            "Dispatch": [],
            "Farm management": [],
            "Settings": [
                "Teams", "Settings",
            ],
        }
    },
    "PRO": {
        features: {
            "IOT management": [
                "IOT Dashboard", "Devices", "Device Groups", "Rules",
            ],
            "Products": [
                "Products", "Categories",
            ],
            "Dispatch": [],
            "Farm management": [
                "Farm Map", "Fields / Paddock List", "Livestock List", "Actions List", "Reports",
            ],
            "Settings": [
                "Teams", "Settings",
            ],
        }
    },
    "GOLD": {
        features: {
            "IOT management": [
                "IOT Dashboard", "Devices", "Device Groups", "Rules",
            ],
            "Products": [
                "Products", "Categories",
            ],
            "Dispatch": [
                "Dispatch", "Incoming Dispatch", "Transaction History",
            ],
            "Farm management": [
                "Farm Map", "Fields / Paddock List", "Livestock List", "Actions List", "Reports",
            ],
            "Settings": [
                "Teams", "Settings",
            ],
        }
    },
    "PLATINUM": {
        features: {
            "IOT management": [
                "IOT Dashboard", "Devices", "Device Groups", "Rules",
            ],
            "Products": [
                "Products", "Categories",
            ],
            "Dispatch": [
                "Dispatch", "Incoming Dispatch", "Transaction History",
            ],
            "Farm management": [
                "Farm Map", "Fields / Paddock List", "Livestock List", "Actions List", "Reports",
            ],
            "Settings": [
                "Teams", "Settings",
            ],
        }
    },
};

/*
All data coming from Dynamo DB table is only related to sensors.
Gateway will not show any value, it will be only «Active» or «Inactive»
This will be manually added by the Administrator
*/

export const devStatus = {
    enabled: true,
    disabled: false,
    'true': 'enabled',
    'false': 'disabled'
}

export default {
    MAPS_API_KEY,
    POSITION_STACK_API_KEY,
    POSITION_STACK_API_URL,
    CENTAUR_API_URL,

    devCats,
    devTypesConfigs,
    devTypes,

    billingPlans,
}