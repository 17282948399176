import { MAPS_API_KEY } from '@/config/';

export default class GMap {
    constructor() {
        this.inited = false;
        this.activeMaps = new Map();
        this.mapStyle = {};
        this.events = {};
        this.objects = [];
        this.mapObjectLabelsArray = [];
        this.editObject = null;
        this.undoBtnContent = null;

        this.init();
    }

    on(event, callback){
        if(!this.events[event]){
            this.events[event] = []
        }

        this.events[event].push(callback);
    }

    emit(event, payload){
        if(!this.events[event]){
            return;
        }

        this.events[event].forEach(element => {
            element(payload);
        });
    }

    init() {
        if (this.inited) return

        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&libraries=drawing`);
        // scriptTag.setAttribute('src', `https://maps.googleapis.com/maps/api/js?libraries=drawing`);
        document.body.append(scriptTag);
        scriptTag.onload = () => {
            console.log('Map loaded');
        }

        this.inited = true;
    }

    createMap(ID, payload = {}, cb, drawing=false, mapDrawingObjects = []) {
        const el = document.getElementById(ID);
        let self = this;

        const center = {
            lat: (payload.lat||45.6219172254747),
            lng: (payload.lng||9.284690169503811),
        };
        const map = new google.maps.Map(el, {
            center,
            zoom: 16,
            mapTypeId: 'satellite',
            fullscreenControl: !payload.disableDefaultUI&&!payload.disableFullscreenControl,
            disableDefaultUI:payload.disableDefaultUI
        })
        if(payload.fullscreenButtonElement){
            // var myControl = new MyControl(controlDiv);
            // map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
            console.log("btn",payload.fullscreenButtonElement);
        }



        if(!drawing){
            let iconUrl="/img/map/companyMarker.svg"
            if(payload.markerType=="product"){
                iconUrl="/img/map/productMarker.svg"
            }
            let markers= payload.markers||[center]
            markers.forEach((markerCords,index)=>{
 
                const marker = new google.maps.Marker({
                    position: markerCords,
                    map,
                    icon: {
                        url: iconUrl,
                    },
                    draggable: true,
                    animation: 'DROP'
                })
                const entry = {
                    id: ID,
                    map,
                    marker
                }
                this.activeMaps.set(ID, entry);
                if(!payload.notEditable && !payload.markers){
                    map.addListener('click', (ev) => {
                        const lat = ev.latLng.lat();
                        const lng = ev.latLng.lng();
        
                        map.panTo({
                            lat,
                            lng
                        })
        
                        marker.setPosition({
                            lat,
                            lng
                        })
        
                        cb({ lat, lng })
                    })
                    marker.addListener('dragend', (ev) => {
                        const lat = ev.latLng.lat();
                        const lng = ev.latLng.lng();
        
                        map.panTo({
                            lat,
                            lng
                        })
        
                        cb({ lat, lng })
                    })
                }
            })
        } else {
            this.undoBtnContent = null;

            this.activeMaps.set(ID, {
                id: ID,
                map,
            });

            map.setOptions({
                disableDefaultUI: true,
                fullscreenControl: false
            })

            function initZoomControl(map) {
                document.querySelector(".zoom-control-in").onclick = function () {
                    map.setZoom(map.getZoom() + 1);
                };
              
                document.querySelector(".zoom-control-out").onclick = function () {
                    map.setZoom(map.getZoom() - 1);
                };
            }
            let mapTypeHybrid = false;

            function initMapTypeControl(map) {
                const mapTypeControl = document.querySelector(".maptype-control");
                mapTypeControl.onclick = function () {
                    mapTypeHybrid = !mapTypeHybrid;
                    if(mapTypeHybrid){
                        map.setMapTypeId("hybrid");
                        mapTypeControl.classList.add("active");
                    } else {
                        map.setMapTypeId("satellite");
                        mapTypeControl.classList.remove("active");
                    }
                };
              }

            initZoomControl(map);
            initMapTypeControl(map);

            let colorOptions = {
                "Red": {
                    main: "rgba(237, 28, 36, 0.3)", stroke: "#ED1C24"
                },
                "Orange": {
                    main: "rgba(247, 148, 30, 0.3)", stroke: "#F7941E"
                },
                "Yellow": {
                    main: "rgba(255, 222, 23, 0.3)", stroke: "#FFDE17"
                },
                "Green": {
                    main: "rgba(57, 204, 74, 0.3)", stroke: "#39CC4A"
                },
                "Blue": {
                    main: "rgba(39, 170, 225, 0.3)", stroke: "#27AAE1"
                },
                "Dark Blue": {
                    main: "rgba(0, 87, 255, 0.3)", stroke: "#0057FF"
                },
                "Purple": {
                    main: "rgba(108, 0, 174, 0.3)", stroke: "#6C00AE"
                },
            }

            class DeleteMenu extends google.maps.OverlayView {
                div_;
                divListener_;
                constructor() {
                    super();
                    this.div_ = document.createElement("div");
                    this.div_.className = "delete-menu";
                    this.div_.innerHTML = "Delete";

                    const menu = this;

                    google.maps.event.addDomListener(this.div_, "click", () => {
                        menu.removeVertex();
                    });
                }
                onAdd() {
                    const deleteMenu = this;
                    const map = this.getMap();

                    this.getPanes().floatPane.appendChild(this.div_);
                    // mousedown anywhere on the map except on the menu div will close the
                    // menu.
                    this.divListener_ = google.maps.event.addDomListener(map.getDiv(), "mousedown", (e) => {
                        if (e.target != deleteMenu.div_) {
                            deleteMenu.close();
                        }
                    }, true);
                }
                onRemove() {
                    if (this.divListener_) {
                        google.maps.event.removeListener(this.divListener_);
                    }

                    this.div_.parentNode.removeChild(this.div_);
                    // clean up
                    this.set("position", null);
                    this.set("path", null);
                    this.set("vertex", null);
                }
                close() {
                    this.setMap(null);
                }
                draw() {
                    const position = this.get("position");
                    const projection = this.getProjection();

                    if (!position || !projection) {
                        return;
                    }

                    const point = projection.fromLatLngToDivPixel(position);

                    this.div_.style.top = point.y + "px";
                    this.div_.style.left = point.x + "px";
                }
                /**
                 * Opens the menu at a vertex of a given path.
                 */
                open(map, path, vertex) {
                    this.set("position", path.getAt(vertex));
                    this.set("path", path);
                    this.set("vertex", vertex);
                    this.setMap(map);
                    this.draw();
                }
                /**
                 * Deletes the vertex from the path.
                 */
                removeVertex() {
                    const path = this.get("path");
                    const vertex = this.get("vertex");

                    if (!path || vertex == undefined) {
                        this.close();
                        return;
                    }

                    path.removeAt(vertex);
                    this.close();
                }
            }

            const deleteMenu = new DeleteMenu();

            class MapLabel extends google.maps.OverlayView {
                position;
                containerDiv;
                constructor(position, titleText) {
                    super();
                    this.position = position;
                    let content = document.createElement("div");
                    content.classList.add("popup-bubble");
                    content.innerHTML = titleText;
                    // This zero-height div is positioned at the bottom of the bubble.
                    const bubbleAnchor = document.createElement("div");

                    bubbleAnchor.classList.add("popup-bubble-anchor");
                    bubbleAnchor.appendChild(content);
                    // This zero-height div is positioned at the bottom of the tip.
                    this.containerDiv = document.createElement("div");
                    this.containerDiv.classList.add("popup-container");
                    this.containerDiv.appendChild(bubbleAnchor);
                    // Optionally stop clicks, etc., from bubbling up to the map.
                    MapLabel.preventMapHitsAndGesturesFrom(this.containerDiv);
                }
                /** Called when the popup is added to the map. */
                onAdd() {
                    this.getPanes().floatPane.appendChild(this.containerDiv);
                }
                /** Called when the popup is removed from the map. */
                onRemove() {
                    if (this.containerDiv.parentElement) {
                        this.containerDiv.parentElement.removeChild(this.containerDiv);
                    }
                }
                /** Called each frame when the popup needs to draw itself. */
                draw(addedPosition) {
                    if(addedPosition){
                        this.position = addedPosition;
                    }

                    const divPosition = this.getProjection().fromLatLngToDivPixel(
                        this.position
                    );
                    // Hide the popup when it is far out of view.
                    const display =
                        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                        ? "block"
                        : "none";

                    if (display === "block") {
                        this.containerDiv.style.left = divPosition.x + "px";
                        this.containerDiv.style.top = divPosition.y + "px";
                    }

                    if (this.containerDiv.style.display !== display) {
                        this.containerDiv.style.display = display;
                    }
                }
            }

            class areaSizeBox extends google.maps.OverlayView {
                position;
                containerDiv;
                constructor(position, titleText) {
                    super();
                    this.position = position;
                    let content = document.createElement("div");
                    content.classList.add("area-size-box");
                    content.innerHTML = titleText;
                    // This zero-height div is positioned at the bottom of the bubble.
                    // const bubbleAnchor = document.createElement("div");

                    // bubbleAnchor.classList.add("popup-bubble-anchor");
                    // bubbleAnchor.appendChild(content);
                    // This zero-height div is positioned at the bottom of the tip.
                    this.containerDiv = document.createElement("div");
                    this.containerDiv.classList.add("area-size-box-container");
                    this.containerDiv.appendChild(content);
                    // Optionally stop clicks, etc., from bubbling up to the map.
                    areaSizeBox.preventMapHitsAndGesturesFrom(this.containerDiv);
                }
                onAdd() {
                    this.getPanes().floatPane.appendChild(this.containerDiv);
                }
                onRemove() {
                    if (this.containerDiv.parentElement) {
                        this.containerDiv.parentElement.removeChild(this.containerDiv);
                    }
                }
                draw(addedPosition, changeTitleText) {
                    if(addedPosition){
                        this.position = addedPosition;
                    }

                    if(changeTitleText){
                        this.containerDiv.querySelector('.area-size-box').innerHTML = changeTitleText;
                    }

                    const divPosition = this.getProjection().fromLatLngToDivPixel(
                        this.position
                    );
                    const display =
                        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                        ? "block"
                        : "none";
                    if (display === "block") {
                        this.containerDiv.style.left = divPosition.x + "px";
                        this.containerDiv.style.top = divPosition.y + "px";
                    }
                    if (this.containerDiv.style.display !== display) {
                        this.containerDiv.style.display = display;
                    }
                }
            }

            function setMapCenterFromObject(type, element){
                switch (type) {
                    case 'polygon':
                        map.setCenter(getCenterFromBounds(element));
                        break;                
                    case 'circle':
                        map.setCenter(element.getCenter())
                        break;                
                    case 'marker':
                        map.setCenter({
                            lat: element.position.lat(),
                            lng: element.position.lng(),
                        })
                        break;                
                    default:
                        break;
                }
            }

            function getCenterFromBounds(figureObject){
                var bounds = new google.maps.LatLngBounds();
                for (var i = 0; i < figureObject.getPath().getArray().length; i++) {
                    bounds.extend(figureObject.getPath().getArray()[i]);
                }
                return { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() }
            }

            let selfThis = this;
            function undoBtnViewChange(){
                if(!selfThis.undoBtnContent) {
                    selfThis.undoBtnContent = document.createElement("div");
                    selfThis.undoBtnContent.classList.add("map-undo-btn");
                    selfThis.undoBtnContent.innerHTML = 'Undo';
                    if(document.querySelector('img[src$="undo_poly.png"')) {
                        document.querySelector('img[src$="undo_poly.png"').parentNode.appendChild(selfThis.undoBtnContent);
                        document.querySelector('img[src$="undo_poly.png"').style.opacity = '0';
                        document.querySelector('img[src$="undo_poly.png"').parentNode.classList.add("map-undo-btn-wrpr");
                    }
                } 
            }

            if(mapDrawingObjects.length){
                var mapCenter = new google.maps.LatLngBounds();
                this.mapObjectLabelsArray = [];

                for (let index = 0; index < mapDrawingObjects.length; index++) {
                    if(mapDrawingObjects[index].map_info){
                        let newMapObject = null;
                        let mapObjectLabel = null;

                        switch (mapDrawingObjects[index].map_info.type) {
                            case 'polygon':
                                for (var i = 0; i < mapDrawingObjects[index].map_info.points.length; i++) {
                                    mapCenter.extend(mapDrawingObjects[index].map_info.points[i]);
                                }

                                newMapObject = new google.maps.Polygon({
                                    paths: mapDrawingObjects[index].map_info.points,
                                    // editable: true,
                                    fillColor: mapDrawingObjects[index].basic_info.colour_on_map ? 
                                        colorOptions[mapDrawingObjects[index].basic_info.colour_on_map].main : 'rgba(38, 167, 225, 0.3)',
                                    strokeColor: mapDrawingObjects[index].basic_info.colour_on_map ? 
                                        colorOptions[mapDrawingObjects[index].basic_info.colour_on_map].stroke : '#26A7E1',
                                    // draggable: true,
                                    map,
                                });

                                break;
                            case 'circle':
                                mapCenter.extend({
                                    lat: mapDrawingObjects[index].map_info.center_lat, 
                                    lng: mapDrawingObjects[index].map_info.center_lng
                                });

                                newMapObject = new google.maps.Circle({
                                    fillColor: mapDrawingObjects[index].basic_info.colour_on_map ? 
                                        colorOptions[mapDrawingObjects[index].basic_info.colour_on_map].main : 'rgba(38, 167, 225, 0.3)',
                                    strokeColor: mapDrawingObjects[index].basic_info.colour_on_map ? 
                                        colorOptions[mapDrawingObjects[index].basic_info.colour_on_map].stroke : '#26A7E1',
                                    map,
                                    center: { lat: mapDrawingObjects[index].map_info.center_lat, lng: mapDrawingObjects[index].map_info.center_lng },
                                    radius: mapDrawingObjects[index].map_info.radius,
                                });

                                break;
                            case 'marker':
                                mapCenter.extend({
                                    lat: mapDrawingObjects[index].map_info.center_lat, 
                                    lng: mapDrawingObjects[index].map_info.center_lng
                                });

                                newMapObject = new google.maps.Marker({
                                    position: { 
                                        lat: mapDrawingObjects[index].map_info.center_lat, 
                                        lng: mapDrawingObjects[index].map_info.center_lng
                                    },
                                    map,
                                    icon: {
                                        url: '/img/map/map_marker_circle_icon.svg',
                                        anchor: new google.maps.Point(13, 12)
                                    },
                                    animation: 'DROP',
                                })
                                break;
                            default:
                                break;
                        }

                        // скрыть лейбл у активного, а у остальных показать
                        function labelEditObjectHide(){
                            self.mapObjectLabelsArray.find(label => label.containerDiv.innerText === mapDrawingObjects[index].basic_info.name).containerDiv.querySelector('.popup-bubble').style.opacity = '0';
                            self.mapObjectLabelsArray.forEach(labelItem => {
                                if(labelItem.containerDiv.innerText !== mapDrawingObjects[index].basic_info.name) {
                                    labelItem.containerDiv.querySelector('.popup-bubble').style.opacity = '1';
                                }
                            })
                        }
                        
                        if(!payload.dontClickPaddocks){
                            newMapObject.addListener("click", function(event){
                                labelEditObjectHide();

                                newMapObject.setOptions({
                                    editable: true,
                                    draggable: true,
                                })

                                self.emit('editMapDrawingObjects', mapDrawingObjects[index]);
                                self.objects.forEach(element => {
                                    if(element !== newMapObject){
                                        element.setOptions({
                                            editable: false,
                                            draggable: false,
                                        })
                                    }
                                });
                            });
                        }
                        
                        let labelCoords = mapDrawingObjects[index].map_info.center_lat ? {
                            lat: mapDrawingObjects[index].map_info.center_lat,
                            lng: mapDrawingObjects[index].map_info.center_lng,
                        } : getCenterFromBounds(newMapObject);
                        
                        if(labelCoords) {
                            mapObjectLabel = new MapLabel(
                                new google.maps.LatLng(
                                    labelCoords.lat-0.00007, 
                                    labelCoords.lng
                                ), mapDrawingObjects[index].basic_info.name
                            );
                            mapObjectLabel.setMap(map);
                            this.mapObjectLabelsArray.push(mapObjectLabel);
                        }

                        // редактирование загруженных фигур
                        function polygonObjectDataCalc(polygonObject){
                            let points = [];
                            for (let index = 0; index < polygonObject.getPath().getArray().length; index++) {
                                points.push({ 
                                    lat: polygonObject.getPath().getArray()[index].lat(), 
                                    lng: polygonObject.getPath().getArray()[index].lng() 
                                });
                            }

                            self.emit('editDrawing', { 
                                type: 'polygon',
                                data_emited: {
                                    points,
                                    total_area: google.maps.geometry.spherical.computeArea(polygonObject.getPath()).toFixed(2)
                                }
                            });
                        }

                        function labelSetPosition(position){
                            self.mapObjectLabelsArray.find(label => label.containerDiv.innerText === mapDrawingObjects[index].basic_info.name).draw(position);
                            undoBtnViewChange();
                        }
                        
                        if(!payload.dontClickPaddocks){
                            if(mapDrawingObjects[index].map_info.type === 'polygon') {
                                google.maps.event.addListener(newMapObject, "mouseup", function(e) {
                                    polygonObjectDataCalc(newMapObject);
                                    let labelNewCoords = getCenterFromBounds(newMapObject);
                                    labelSetPosition({ ...labelNewCoords, lat:labelNewCoords.lat-0.00007 });
                                    
                                });
                                google.maps.event.addListener(newMapObject, "drag", function(e) {
                                    polygonObjectDataCalc(newMapObject);
                                    let labelNewCoords = getCenterFromBounds(newMapObject);
                                    labelSetPosition({ ...labelNewCoords, lat:labelNewCoords.lat-0.00007 });
                                });
                            }

                            if(mapDrawingObjects[index].map_info.type === 'circle') {
                                newMapObject.addListener("center_changed", function(event) {
                                    self.emit('editDrawing', { ...newMapObject, type: 'circle' });
                                    labelSetPosition({ lat: newMapObject.center.lat()-0.00007, lng: newMapObject.center.lng() });
                                });
                                newMapObject.addListener("radius_changed", function(event) {
                                    self.emit('editDrawing', { ...newMapObject, type: 'circle' });
                                    undoBtnViewChange();
                                });
                            }

                            if(mapDrawingObjects[index].map_info.type === 'marker') {
                                newMapObject.addListener("drag", function(event) {
                                    self.emit('editDrawing', { ...newMapObject, type: 'marker' });
                                    labelSetPosition({ lat: newMapObject.position.lat()-0.00007, lng: newMapObject.position.lng() });
                                });
                            }

                            // редактирование фигуры с query роута
                            if(this.editObject && this.editObject === mapDrawingObjects[index].id){
                                newMapObject.setOptions({
                                    editable: true,
                                    draggable: true,
                                })

                                self.mapObjectLabelsArray.find(label => label.containerDiv.innerText === mapDrawingObjects[index].basic_info.name).containerDiv.querySelector('.popup-bubble').style.opacity = '0';
                                
                                setTimeout(() => {
                                    self.emit('editMapDrawingObjects', mapDrawingObjects[index]);
                                    setMapCenterFromObject(mapDrawingObjects[index].map_info.type, newMapObject);
                                }, 1500);
                            }
                        }

                        self.objects.push(newMapObject);
                    }
                }

                map.fitBounds(mapCenter);
                // map.setCenter({ lat: mapCenter.getCenter().lat(), lng: mapCenter.getCenter().lng() });
            }

            let drawingManager = new google.maps.drawing.DrawingManager({
                // drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: false,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        google.maps.drawing.OverlayType.POLYGON,
                        google.maps.drawing.OverlayType.CIRCLE
                    ],
                },
                polygonOptions: {
                    editable: true,
                    fillColor: 'rgba(38, 167, 225, 0.3)',
                    strokeColor: '#26A7E1',
                    draggable: true,
                },
                circleOptions: {
                    editable: true,
                    fillColor: 'rgba(38, 167, 225, 0.3)',
                    strokeColor: '#26A7E1'
                },
                markerOptions: {
                    editable: true,
                    draggable: true,
                    icon: {
                        url: '/img/map/map_marker_circle_icon.svg',
                        anchor: new google.maps.Point(13, 12)
                    },
                    animation: 'DROP',
                }
            });
            drawingManager.setMap(map);
            
            google.maps.event.addListener(drawingManager, "overlaycomplete", function(event) {
                var newShape = event.overlay;
                newShape.type = event.type;
            });
            
            // Создание фигуры
            google.maps.event.addListener(drawingManager, "overlaycomplete", function(event) {
                self.emit('addDrawing', event.overlay);
                self.objects.push(event.overlay);
                let addedAreaSizeBox = null;
                let addedAreaSizeBoxOptions = {
                    coords: null,
                    value: null
                };

                switch (event.type) {
                    case 'polygon':
                        function polygonAreaChange(){
                            addedAreaSizeBox.draw(getCenterFromBounds(event.overlay), 
                            `Area: ${ (google.maps.geometry.spherical.computeArea(event.overlay.getPath())/10000).toFixed(2) } ha`);
                            undoBtnViewChange();
                        }

                        google.maps.event.addListener(event.overlay, "drag", function(e) {                
                            self.emit('editDrawing', event.overlay);
                            polygonAreaChange();
                        });
                        google.maps.event.addListener(event.overlay, "contextmenu", (e) => {
                            if (e.vertex == undefined) {
                              return;
                            }
                        
                            deleteMenu.open(map, event.overlay.getPath(), e.vertex);
                        });

                        google.maps.event.addListener(event.overlay, "mouseup", function(e) {
                            self.emit('editDrawing', event.overlay);
                            polygonAreaChange();
                        });

                        addedAreaSizeBoxOptions.coords = getCenterFromBounds(event.overlay);
                        addedAreaSizeBoxOptions.value = (google.maps.geometry.spherical.computeArea(event.overlay.getPath())/10000).toFixed(2);

                        break;                
                    case 'circle':
                        function circleAreaChange(){
                            addedAreaSizeBox.draw({
                                lat: event.overlay.center.lat()-0.00012, 
                                lng: event.overlay.center.lng()
                            }, `Area: ${ ((Math.PI * Math.pow(event.overlay.radius, 2))/10000).toFixed(2) } ha`);
                            undoBtnViewChange();
                        }
                        google.maps.event.addListener(event.overlay, "center_changed", function(e) {
                            self.emit('editDrawing', event.overlay);
                            circleAreaChange();
                        });
    
                        google.maps.event.addListener(event.overlay, "radius_changed", function(e) {  
                            self.emit('editDrawing', event.overlay);
                            circleAreaChange();
                        });

                        google.maps.event.addListener(event.overlay, "mouseup", function(e) {
                            self.emit('editDrawing', event.overlay);
                            undoBtnViewChange();
                        });

                        addedAreaSizeBoxOptions.coords = {
                            lat: event.overlay.center.lat()-0.00012, 
                            lng: event.overlay.center.lng()
                        };
                        addedAreaSizeBoxOptions.value = ((Math.PI * Math.pow(event.overlay.radius, 2))/10000).toFixed(2);
        
                        break;                
                    default:
                        break;
                }

                addedAreaSizeBox = new areaSizeBox(
                    new google.maps.LatLng(addedAreaSizeBoxOptions.coords), 
                    `Area: ${addedAreaSizeBoxOptions.value} ha`
                );
                addedAreaSizeBox.setMap(map);
            });

            return { google, drawingManager };
        }
    }

    getMap(ID) {
        return this.activeMaps.get(ID);
    }

    setCords(ID, latLng, drawing=false) {
        console.log('this.activeMaps.get(ID):', this.activeMaps.get(ID));
        const { map, marker } = this.activeMaps.get(ID);
        map.panTo(latLng);
        if(!drawing) marker.setPosition(latLng);
    }
    setViewMode(value){
        this.isViewMode=value
    }
    // openFullscreen(ID){
    //     console.log("openFullscreen",this.activeMaps,this.getMap(ID).map.fullscreenControlOptions );
    //     this.getMap(ID).map.fullscreenControl=true
    //     // google.maps.fullscreenControlOptions 
    // }
}