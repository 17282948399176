<template>
    <div class="categories dropdown-wrapper" v-click-outside="closeMe">
        <button 
            type="button" 
            class="btn btn-standart dropdown-btn" 
            :class="[{ active: open }, btnClass, { 'placeholder':value_object ? !current : !current || !current.length }]" 
            @click="toggleMe" :title="value_object && current ? current.title : current || 'Select Option'"
        >
            <span class="active-categories">{{ value_object && current ? current.title : current || 'Select Option' }}</span>
            <!-- <span><img src="@/assets/img/Arrow-down.svg"></span> -->
             <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 10L12 14L16 10" stroke="#397490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </button>

        <div class="categories__dropdown dropdown-content" :class="{active: open}">
            <ul :class="{ 'colorpicker-list':colorpicker }">
                <li v-if="search" class="li_item_search">
                    <input type="text" class="input" v-model="searchString" placeholder="Search">
                </li>
                <li 
                    v-for="option in computedOptions" :key="value_object ? option.id : option" 
                    @click="change(value_object ? option.id : option)" 
                    :class="{ 'active': value_object && current ? current.id === option.id : current ? current === option : false }"
                    :title="!colorpicker ? value_object ? option.title : option : ''"
                >
                    <span v-if="!colorpicker">{{ value_object ? option.title : option }}</span>
                    <span v-else><div class="colorpicker-color" :style="{ background: colorOptions[option] }"></div> {{ option }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: ['options', 'current', 'name', 'shadow', 'colorpicker', 'search', 'value_object',],
    data() {
        return {
            open: false,
            searchString: '',
            colorOptions: {
                "Red": "#ED1C24",
                "Orange": "#F7941E",
                "Yellow": "#FFDE17",
                "Green": "#39CC4A",
                "Blue": "#27AAE1",
                "Dark Blue": "#0057FF",
                "Purple": "#6C00AE",
            }
        }
    },
    computed: {
        btnClass() {
            return this.shadow ? 'btn-w-shadow' : 'none-shadow'
        },
        computedOptions(){
            if(this.value_object) {
                return this.options.filter(option => option.title.toLowerCase().includes(this.searchString.toLowerCase()));
            } else {
                return this.options.filter(option => option.toLowerCase().includes(this.searchString.toLowerCase()));
            }
        }
    },
    methods: {
        change(option) {
            const event = {
                target: {
                    value: option,
                    name: this.name
                }
            }
            this.$emit('change', event);
            this.closeMe();
        },
        closeMe() {
            this.open = false;
            this.searchString = '';
        },
        toggleMe() {
            this.open = !this.open
        }
    }
}
</script>

<style>
.li_item_search {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0 8px 8px;
    background: #fff;
    width: calc(100% + 16px);
    margin-left: -8px;
}
.open_to_right .dropdown-content {
    left: auto;
    right: 0;
}
</style>