export default {
    "iot-management": "IOT MANAGEMENT",
    "iot-dashboard": "IOT Tableau de bord",
    "devices": "Appareils",
    "device-groups": "Groupes d'appareils",
    "rules": "Règles",
    "products": "PRODUITS",
    "products": "Produits",
    "categories": "Catégories",
    "dispatch": "EXPÉDITION",
    "dispatch": "Expédition",
    "incoming-dispatch": "Dépêches entrants",
    "transaction-history": "Historique des transactions",
    "qr-scanner": "QR scanner",
    "farm-management": "FARM MANAGEMENT",
    "farm-map": "Farm Map",
    "fields-/-paddock-list": "Champs/Enclos liste",
    "livestock-list": "Bétail liste",
    "actions-list": "Carnet de terrain",
    "reports": "Rapports",
    "settings": "RÉGLAGES",
    "teams": "Equipes",
    "settings": "Réglages",
    "tutorial": "Tutorial",
    "support": "Support",
    "company": "Entreprise",
    "settings": "Réglages",
    "sign-out": "Déconnecter",
    "rows-per-page": "Lignes par page",
    "prev": "Préc",
    "next": "Suiv",
    "add-iot-device": "Ajouter un appareil IOT",
    "all-categories": "Toute Catégorie",
    // "device-info":	"Info Apparèil",
    "alert": "Alerte",
    "settings": "Réglages",
    "report": "Rapports",
    // "device-info":	"Info apparèil",
    "search-settings": "Options de recherche",
    "location": "Localisation",
    "groups": "Groupes",
    "status": "état",
    "date-from": "Date à partir de",
    "date-to": "à",
    "reset-all-filters": "Remove tous les filtres",
    "apply": "Appliquer",
    "devices": "Apparéils",
    "search": "Recherche",
    "categories": "Catégories",
    "locations": "Lieux",
    "status": "état",
    "type": "Type",
    "map": "Plan",
    "device-name": "Nom Apparéil",
    "category": "Catégorie",
    "date-of-purchase": "Date d'achat",
    "type": "Type",
    "location": "Localisation",
    // "device-info":	"Info d'apparéil",
    "device-info": "Info sur le capteur",
    "rows-per-page": "Lignes par page",
    "prev": "Préc",
    "next": "Suiv",
    "add-iot-device": "Ajouter un dispositif IOT",
    "device-name": "Nom Dispositif",
    "device-type": "Type apparèil",
    "device-category": "Categorie apparèil",
    "description": "Déscription",
    "date-of-manufacture": "Date de fabrication",
    "date-of-purchase": "Date d'achat",
    "device-id": "ID apparèil",
    "device-groups": "Groups appareils",
    "add-group": "Ajouter un groupe",
    "name": "Nom",
    "iot-devices": "Appareil IOT",
    "actions": "Actions",
    "rename-group": "Renommer groupe",
    "edit-group": "Modifier groupe",
    "report": "Report",
    "add-a-group": "Ajouter un groupe",
    "set-up-a-rule-for-each-of-the-onboarded-iot-devices.": "Configurez une règle pour chacun des appareils IOT connectés.",
    "group-name": "Nom groupe",
    "select-devices": "Sélectionner les appareils",
    "selected-devices": "Dispositifs sélectionnés",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Pour définir des règles, vous devez sélectionner des appareils IOT installés",
    "previous": "Précédent",
    "cancel": "Annuler",
    "submit": "Soumettre",
    "rules": "Règles",
    "add-a-rule": "Ajouter un règle",
    "rule": "Règle",
    "iot-devices": "Apparèils IOT",
    "actions": "Actions",
    "enabled": "Activité",
    "disabled": "Désactivé",
    "add-a-rule": "Ajouter un règle",
    "alert-me-when-all-the-selected-conditions-are-satisfied:": "Alertes-moi lorsque toutes les conditions sélectionnées sont satisfaites :",
    "basic-info": "Informations de base",
    "basic-setting-for-a-rule": "Paramètres de base pour une règle",
    "name": "Nom",
    "selected-devices": "Apparèils selectionnés",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Pour définir des règles, vous devez sélectionner aucuns entre lesappareils IOT installés",
    "behaviour": "Comportement",
    "specify-how-your-devices-should-behave-on-triger.": "Spécifiez comment vos appareils doivent se comporter sur triger.",
    "add-behaviour": "Ajouter un comportement",
    "observation-period": "Période d'observation",
    "parameter": "Paramètres",
    "behaviour": "Comportement",
    "operator": "Opérateur",
    "value": "Valeur",
    "alert-actions": "Actions d'alerte",
    "select-what-action-your-rule-should-take": "Sélectionnez l'action que votre règle doit exécuter",
    "email": "Email",
    "display-name": "Nom complet",
    "temperature-warning": "Alerte de température",
    "note": "Note",
    "you-should-check-the-device!": "Vous devriez vérifier l'appareil !",
    "cancel": "Effacer",
    "submit": "Soumettre",
    "dashboard": "Tableau de bord",
    "machine-to-machine": "Machine to Machine",
    "select-device": "Sélection apparéil",
    "select-option": "Choisir l'option",
    "action": "Actions",
    "previous": "Précédent",
    "create-a-rule": "Créer une règle",
    "products": "Produits",
    "add-a-product": "Ajouter un produit",
    "search-by-name,-id,-rfid,-serial#...": "Recherche par nom, ID, RFID, numéro de série...",
    "product-name": "Nom Produit",
    "category": "Categorie",
    "parent-product": "Produit parent",
    "serial": "Série",
    "location": "Location",
    "device": "Apparèil",
    "status": "état",
    "manufacturing-date": "Date de fabrication",
    "expiry-date": "Date d'expiration",
    "add-a-product": "Ajouter un produit",
    "fill-in-information-to-add-new-product": "Remplir les informations pour ajouter un nouveau produit",
    "basic-info": "Informations de base",
    "category": "Categorie",
    "select-option": "Sélectionnez une option",
    "product-name": "Nom Produit",
    "untitled-product": "Produit sans titre",
    "quantity": "Quantité",
    "units": "Unités",
    "description": "Description",
    "write-product-description-here...": "Écrire la description du produit ici...",
    "media": "Mèdia",
    "drag-&-drop-or-browse-to-choose-a-file": "Glisser & déposer ou explorer pour choisir un fichier",
    "manufacture-info": "Informations sur la fabrication",
    "serial-number": "Numéro de série",
    "manufacturing-date": "Date de production",
    "expiry-date": "Date d'expiration",
    "invoice-number": "Numéro de facture",
    "purchase-order": "Ordre d'achat",
    "rfid-number": "Numéro RFID",
    "batch-id": "ID du lot",
    "location": "Location",
    "assign-device": "Assigner un apparèil",
    "here-you-can-find-devices-assigned-to-your-product": "Vous trouverez ici les dispositifs assignés à votre produit",
    "parent-products": "Produit parent",
    "here-you-can-find-parent-products-assigned-to-your-product": "Vous trouverez ici les produits parents associés à votre produit.",
    "type-of-event": "Type d'événement",
    "farm": "Farm",
    "link-to-a-farm": "Lien à une ferme",
    "here-you-can-link-farm-items-to-a-product": "Ici, vous pouvez lier des éléments de la ferme à un produit",
    "please-link-farm-items-to-a-product": "Veuillez relier les articles agricoles à un produit",
    "add-herd": "Ajouter un bétail",
    "add-building": "Ajouter un bâtiment",
    "add-field": "Ajouter un champ",
    "add-paddock": "Ajouter un enclos",
    "name": "Nom",
    "type": "Type",
    "total-area": "Surface totale",
    "land-use": "Utilisation des terres",
    "crop-type": "Type de cultures",
    "colour-on-map": "Couleur sur la carte",
    "qr-code-settings": "Paramètres de code QR",
    "company-data": "Données sur la compagnie",
    "select-info-that-you-want-to-show-in-qr-code": "Sélectionnez les informations que vous souhaitez afficher dans le code QR",
    "location": "Location",
    "documentation": "Documentation",
    "production-area": "Zone de production",
    "productivity": "Productivité",
    "history-of-company": "Historique de l'entreprise",
    "year-of-foundation": "Année de fondation",
    "soils": "Sols",
    "certifications": "Certifications",
    "media": "Mèdia",
    "production-per-year": "Production par an",
    "cellar-work": "Procédures",
    "philosophy": "Philosophie",
    "assign-devices": "Attribuer des dispositifs",
    "here-you-can-find-devices-assigned-to-your-product": "Vous trouverez ici les dispositifs associés à votre produit.",
    "product-chain-data": "Données sur la chaîne de production",
    "select-info-that-you-want-to-show-in-qr-code": "Sélectionnez les informations que vous souhaitez afficher dans le code QR.",
    "datetime-creation": "Date et heure de création",
    "datetime-receive": "Date et heure de reception",
    "parent-products": "Produits parents",
    "smart-conditions": "Smart Conditions ",
    "rules-business-conditions": "Règles Business conditions",
    "datetime-return": "Date de retour",
    "list-of-dispatches": "Liste des envois",
    "datetime-dispatch": "Date et heure d'envoi",
    "basic-info": "Informations de base",
    "linked-iot-devices": "Appareils IOT liés",
    "actions-info": "Actions info",
    "create-a-product": "Créer un produit",
    "categories": "Catégories",
    "sort-by": "Trier par",
    "category": "Catégorie",
    "number-of-products": "Nombre de produits",
    "last-update-date": "Dernière mise à jour",
    "date-of-creation": "Date de création",
    "add-products": "Ajouter des produits",
    "add-a-category": "Ajouter une catégorie",
    "here-you-can-create-new-category-and-add-products": "Ici, vous pouvez créer une nouvelle catégorie et ajouter des produits",
    "basic-info": "Basic info",
    "category-name": "Nom de la catégorie",
    "selected-products": "Produits sélectionnés",
    "to-create-a-category-you-need-to-select-some-products.": "Pour créer une catégorie, vous devez sélectionner certains produits.",
    "add-new-product": "Ajouter un nouveau produit",
    "dispatch": "Expédition",
    "create-a-dispatch": "Créer une expédition",
    "date-of-creation": "Date de création",
    "dispatch-id": "ID de l'expédition",
    "status": "Statut",
    "owner": "Propriétaire",
    "no.-batch": "N° Lot",
    "no.-products": "N° produits",
    "smart-condition-status": "État de la Smart Conditions",
    "business-condition-status": "État des Business Conditions",
    "view-the-dispatch": "Voir l'expédition",
    "delete-the-dispatch": "Anuler l'expédition",
    "create-a-dispatch": "Créer une expédition",
    "here-you-can-create-dispatch-for-your-products": "Ici, vous pouvez créer des expéditions pour vos produits",
    "basic-info": "Basic Info",
    "dispatch-id": "ID de l'expédition",
    "dispatch-documents": "Documents des expédition",
    "list-of-documents-attached-to-despatch": "Liste des documents joints à l'envoi",
    "add-document": "Ajouter un document",
    "select-companies": "Sélectionner les entreprises",
    "add-various-companies-to-your-dispatch": "Ajoutez diverses entreprises à votre envoi",
    "add-companies": "Ajouter des entreprises",
    "products": "Produits",
    "dispatch-products": "expédition des produits",
    "list-of-batches-and-products-attached-to-dispatch": "Liste des lots et produits attachés à l'envoi",
    "csv-example": "Example CSV",
    "upload-csv": "Télécharger CSV",
    "add-batch": "Ajouter un lot",
    "contract-conditions": "Conditions du contrat",
    "add-conditions": "Ajouter des conditions",
    "add-conditions-to-your-contract-dispatch": "Ajouter des conditions au contrat d'envoie",
    "add-smart-condition": "Ajouter une Smart Conditions",
    "select-batch-id": "Choisir ID du lot",
    "select-device": "Sélectionne un appareil",
    "parameter-observed": "Paramètre observés",
    "operator": "Operateur",
    "value": "Valeur",
    "enter-number...": "Saisissez le numéro...",
    "from-date": "De ",
    "from-time": "De l'heure",
    "to-date": "Jusqu'à",
    "to-time": "Jusqu'à",
    "add-business-condition": "Ajouter Business Condition",
    "type-1": "Type 1",
    "event-to-be-monitored": "Événement à surveiller",
    "description-of-certifications-required...": "Description des certifications requises...",
    "event-to-be-monitored": "Événement à surveiller",
    "incoming-dispatch": "Envoi entrant",
    "no-incoming-dispatches-yet!": "Pas encore de dépêches entrantes !",
    "transaction-history": "Historique des transactions",
    "product-name": "Nom produit",
    "quantity/units": "Quantité/unités",
    "batch-id": "ID du Lot",
    "name-of-the-dispatch": "Nom de l'expédition",
    "date": "Date",
    "type": "Type",
    "conditions": "Conditions",
    "status-of-the-product": "état du produit",
    "sort-by": "Trier par",
    "qr-scanner": "QR scanner",
    "qr-code-scanner": "QR code scanner",
    "here-you-can-scan-your-enismaro-qr-code": "Ici vous pouvez scanner votre QR Code Enismaro",
    "start-cam-scanner": "Démarrer le scanner de caméra",
    "stop-cam-scanner": "Stop scanner de caméra",
    "scan-from--file": "Scan d'un fichier",
    "farm-map": "Farm map",
    "filter": "Filtre",
    "tool-/-equipment": "Outil / Équipement",
    "herd-zones": "Zones de bétail",
    "building-zones": "Zones de bâtiment",
    "infrastructure-zones": "Zones d'infrastructure",
    "field-zones": "Zones de champ",
    "paddock-zones": "Zones des enclos",
    "add-building-/-infrastructure": "Ajouter bâtiment / infrastructure",
    "add-paddock-": "Ajouter enclos",
    "add-animal-": "Ajouter animaux",
    "add-tool-/-equipment": "Ajouter outil/equipement",
    "basic-info": "Basic info",
    "type": "type",
    "select-option": "Sélectionnez l'option",
    "name": "Nom",
    "description": "description",
    "write-description-here...": "Écrire la description ici...",
    "position": "Position",
    "brand": "Marque",
    "model": "Modèle",
    "working-width-(meters)": "Largeur de travail (mètres)",
    "power-(kw-or-hp)": "Puissance (kw ou hp)",
    "production-year": "Année de production",
    "plate-number": "Numéro de plaque",
    "frame-number": "Numéro de cadre",
    "date-of-purchase": "Date d'achat",
    "revision-date": "Date de révision",
    "date-of-technical-check": "Date du contrôle technique",
    "registration-document": "Document d'enregistrement",
    "attach-file": "Joindre un fichier",
    "insurance-document": "Document d'assurance",
    "user-manual": "Manuel d'utilisation",
    "photo": "foto",
    "drag-&-drop-or-browse-to-choose-a-file": "Glisser-déposer ou parcourir pour choisir un fichier",
    "add-point": "Ajouter un point",
    "add-field": "Ajouter un champ",
    "draw-and-describe-your-field-on-map": "Dessinez et décrivez votre champ sur la carte",
    "borders-definition": "Définition des frontières",
    "pivot-area": "zone Pivot",
    "map": "Carte",
    "basic-info": "Basic info",
    "field-name": "Nom champ",
    "total-area": "zone totale",
    "colour-on-map": "coleur sur carte",
    "crop": "Culture",
    "variety": "Variétés",
    "crop-year": "Année de récolte",
    "description": "Description",
    "land-register": "Registre foncier",
    "add-cadastral-parcel": "Ajouter une parcelle cadastrale",
    "country": "Pays",
    "city": "Ville",
    "sheet-land-registry": "Registre cadastal",
    "particle-land-registry": "Registre des particules",
    "area-land-registry": "Registre foncier zone",
    "actions": "actions",
    "soil": "sol",
    "soil-map": "Carte du sol",
    "granulometry": "Granulométrie",
    "%-sand": "% sable",
    "%-silt": "% sédiments",
    "%-clay": "% terre battue",
    "ph": "pH",
    "carbonates-%": "carbonates",
    "organic-substance-%": "Substance organique (%)",
    "organic-carbon-%": "Carbone organique (%)",
    "mineral-nitrogen": "Azote minéral",
    "assign-devices": "Affecter des dispositifs",
    "here-you-can-find-devices-assigned-to-your-field": "Vous trouverez ici les appareils affectés à votre champ",
    "add-building-/-infrastructure": "Ajouter un bâtiment / une infrastructure",
    "draw-and-describe-your-building-/-infrastructure-on-map": "Dessinez et décrivez votre bâtiment / infrastructure sur une carte",
    "basic-info": "Basic info",
    "name-of-the-building-/-infrastructure": "Nom du batiment/infrastructure",
    "type-of-building-/-infrastructure": "Type de batiment/ infrastructure",
    "colour-on-map": "Coleur sur carte",
    "description": "description",
    "add-paddock": "Ajouter enclos",
    "draw-and-describe-your-paddock-on-map": "Dessinez et décrivez votre enclos sur une carte.",
    "basic-info": "Basic info",
    "paddock-name": "Nom enclos",
    "total-area": "Surface totale",
    "land-use": "utilisation des sols",
    "colour-on-map": "Coleur sur carte",
    "crop": "Culture",
    "variety": "Variétés",
    "crop-year": "Année de  récolte",
    "add-animal": "Ajouter animal",
    "draw-and-describe-your-animal-on-map": "Dessinez et décrivez votre animal sur la carte",
    "basic-info": "Basic info",
    "name-of-the-animal": "Nom de l'animal",
    "type-of-animal": "Type d'animal",
    "breed": "Race",
    "status": "état",
    "sex": "sexe",
    "tag-number": "Numéro d'étiquette",
    "date-of-birth": "Date de naissance",
    "birth-weight": "Poids à la naissance",
    "raised-or-purchased": "Levée ou achetée",
    "disposal": "disposition",
    "assign-paddock-/-building-/-field": "Attribuer un enclos / un bâtiment / un champ",
    "here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your-herd": "Vous trouverez ici les enclos / bâtiments / champs attribués à votre troupeau.",
    "fields-/-paddock-list": "Liste des champs / enclos",
    "date-creation-(from)": "Date de création (de)",
    "date-creation-(to)": "Date de création (jusqu'à)",
    "livestock-list": "Liste de bétail",
    "actions-list": "Liste des actions",
    "reports": "Rapports",
    "herd-movement-records": "Enregistrements des mouvements de bétail",
    "feed-records": "Enregistrements d'aliments",
    "edx-analysis": "Analyse EDX",
    "treatments/action-details": "Traitements/ détails de l'action",
    "this-report-is-empty": "Ce rapport est vide",
    "create-edx-report": "Créer un rapport EDX",
    "edx-analysis-create": "Créer Analyse EDX",
    "add--entry": "Ajouter une donnée",
    "name": "Nom",
    "vet1-map-sum-spectrum": "VET1 Map Sum Spectrum",
    "line-type": "Type de ligne",
    "apparent-concentration": "Concentration apparente",
    "k-ratio": "k Ratio",
    "wt%": "Wt%",
    "wt%-sigma": "Wt% Sigma",
    "standard-label": "Étiquette standard",
    "actions": "Actions",
    "report-name": "Nom rapport",
    "image": "Image",
    "comments": "Commentaires",
    "add-comment": "Ajouter un commentaire",
    "comment-decsription": "Description du commentaire",
    "no-data": "No data",
    "field-logbook": "Carnet de terrain",
    "date": "Data",
    "name-of-the-operation": "Nom de l'opération",
    "name-of-the-product": "Nom du produit",
    "weight-": "Poids ",
    "size": "Taille",
    "cultivation-type": "Type de culture",
    "adversity": "Adversité",
    "field-details": "Détails sur le terrain",
    "paddock-details": "Détails de l'enclos",
    "building-details": "Détails du bâtiment",
    "iot-sensors": "IOT sensors",
    "custom-reports": "Rapports personnalisés",
    "create-custom-report": "Créer un rapport personnalisé",
    "add-column": "Ajouter une colonne",
    "add-entry": "Ajouter une entrée",
    "report-name": "Nom du rapport",
    "actions": "Actions",
    "teams": "Équipes",
    "add-user": "Ajouter un utilisateur",
    "role": "Rôle",
    "grants": "Profil",
    "status": "Etat",
    "add-a-user": "Ajouter un utilisateur",
    "basic-info": "Basic info",
    "add-a-temporal-validity": "Ajouter une validité temporelle",
    "grants": "Profil",
    "all": "Tous",
    "iot-management": "Gestion IOT",
    "products": "Produits",
    "dispatch": "Dépêche",
    "farm-management": "Gestion de la ferme",
    "location": "Localisation",
    "assign-device": "Affecter un dispositif",
    "settings": "Paramètres",
    "account-settings": "Paramètres du compte",
    "profile-photo": "Photo de profil",
    "upload": "Télécharger",
    "remove": "Supprimer",
    "full-name": "Nom complet",
    "password": "Mot de passe",
    "change-password": "Changer le mot de passe",
    "old-password": "Ancien mot de passe",
    "new-password": "Nouveau mot de passe",
    "repeat-new-password": "Répéter le nouveau mot de passe",
    "confirm": "Confirmer",
    "delete-account": "Supprimer le compte",
    "by-deleting-your-account-you-will-lose-all-your-data": "En supprimant votre compte, vous perdrez toutes vos données.",
    "do-you-confirm-delete-for-your-account?": "Confirmez-vous la suppression de votre compte ?",
    "company-info": "Informations sur l'entreprise",
    "company-photo": "Photo de l'entreprise",
    "company-name": "Nom de l'entreprise",
    "year-of-foundation": "Année de la fondation",
    "website": "Site web",
    "history-of-the-company": "Histoire de l'entreprise",
    "product-&-farm-info": "Info Produits & Ferme",
    "production-/-year": "Production / année",
    "production-area": "Zone de production",
    "soils": "Sols",
    "cellar-work": "Travaux de cave",
    "productivity": "Productivité",
    "philosophy": "Philosophie",
    "vat-number": "Numéro de TVA",
    "fiscal-code": "Code fiscal",
    "location": "Localisation",
    "country": "Pays",
    "city": "Ville",
    "province": "Province",
    "certifications": "Certifications",
    "download": "Télécharger",
    "documentation": "Documentation",
    "media": "Médias",
    "user-management": "Gestion des utilisateurs",
    "plan-&-billing": "Plan et facturation",
    "payment-method": "Mode de paiement",
    "expires": "Expiration",
    "your-plan": "Votre plan",
    "see-plan-details": "Voir les détails du plan",
    "upgrade-plan": "Plan de mise à niveau",
    "cancel-plan": "Annuler le plan",
    "iot-dashboard": "Tableau de bord IOT",
    "devices": "Dispositifs",
    "device-groups": "Groupes de dispositifs",
    "rules": "Règles",
    "products": "Produits",
    "categories": "Catégories",
    "teams": "Equipes",
    "settings": "Paramètres",
    "billing-cycles": "Cycles de facturation",
    "next-payment": "Paiement suivant",
    "switch-billing-cycle": "Changement de cycle de facturation",
    "new-to-enismaro?": "Nouveau sur Enismaro ?",
    "create-an-account": "Créer un compte",
    "login": "Login",
    "password": "Mot de passe",
    "forgot-your-password?": "Vous avez oublié votre mot de passe ?",
    "all-rights-reserved": "Tous droits réservés",
    "terms": "Conditions d'utilisation",
    "privacy": "Vie privée",
    "security": "Sécurité",
    "support": "Assistance",
    "create-an-account": "Créer un compte",
    "confirm-password": "Confirmer le mot de passe",
    "sign-up": "S'inscrire",
    "already-have-an-account?": "Vous avez déjà un compte ?",
    "email-recepients": "Réception de courriels"
}