import { CENTAUR_API_URL } from '@/config/';
import axios from 'axios';
// import response_device_data from '@/assets/json/response_device_data'
export default class CentaurAPI {
    constructor(store) {
        this.store = store;
        this.authToken = null;
        this.login();
    }

    async login(){
        let data = {
            "username": "enismaro",
            "password": "enismaro2021"
        };

        await axios.post(`${CENTAUR_API_URL}/users/login`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => this.authToken = res.data.id);
    }

    async getRealDeviceData(id, params){
        if(this.authToken){
            let url = `${CENTAUR_API_URL}/services/devices-data?deviceId=${id}`;
            if(params){
                Object.entries(params).forEach(item => {
                    url += `&${item[0]}=${item[1]}`;
                });
            }

            const result = await axios({
                method: 'get',
                url: url,
                headers: {
                    'Authorization': this.authToken
                },
                // params: { params }
            }).then(res => res.data);

            if(result && result.length){
                return result
            } else {
                return null;
            }
        } else {
            console.log('Not have Centaur API authorization token');
            //alert('Not have Centaur API authorization token')
        }
    }

    async getDevicesInfo(id){
        if(this.authToken){
            const result = await axios({
                method: 'get',
                url: `${CENTAUR_API_URL}/services/devices-info?id=${id}`,
                headers: {
                    'Authorization': this.authToken
                }
            }).then(res => res.data[0])

            if(result){
                return result
            } else {
                return null;
            }
        } else {
            alert('Not have Centaur API authorization token')
        }
    }
}