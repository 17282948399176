export default {
    "iot-management": "IOT-MANAGEMENT",
    "iot-dashboard": "IOT-Ubersichtsseite",
    "devices": "Geräte",
    "device-groups": "Gerätegruppen",
    "rules": "Regeln",
    "products": "PRODUKTE",
    "products": "Produkte",
    "categories": "Kategorien",
    "dispatch": "Absendung",
    "dispatch": "Absendung",
    "incoming-dispatch": "Eingehende Sendung",
    "transaction-history": "Verlauf der Transaktionen",
    "qr-scanner": "QR-Scanner",
    "farm-management": "FARM-MANAGEMENT",
    "farm-map": "Farmkarte",
    "fields-/-paddock-list": "Felder / Fahrerlagerliste",
    "livestock-list": "Tierliste",
    "actions-list": "Logbuch",
    "reports": "Reports",
    "settings": "EINSTELLUNGEN",
    "teams": "Mannschaften",
    "settings": "Einstellungen",
    "tutorial": "Leitfaden",
    "support": "Hilfe",
    "company": "Unternehmen",
    "settings": "Einstellungen",
    "sign-out": "Abmelden",
    "rows-per-page": "Zeilen pro Seite",
    "prev": "Zuruck",
    "next": "Nächste",
    "add-iot-device": "IOT-Gerät hinzufügen",
    "all-categories": "Alle Kategorien",
    // "device-info":	"Geräteinformationen",
    "alert": "Alarm",
    "settings": "Einstellungen",
    "report": "Bericht",
    // "device-info":	"Geräteinformationen",
    "search-settings": "Sucheinstellungen",
    "location": "Standort",
    "groups": "Gruppen",
    "status": "Status",
    "date-from": "Vom",
    "date-to": "Bis zum",
    "reset-all-filters": "Alle Filter zurücksetzen",
    "apply": "Sich bewerben",
    "devices": "Geräte",
    "search": "Suche",
    "categories": "Kategorien",
    "locations": "Standorte",
    "status": "Status",
    "type": "Typ",
    "map": "Karte",
    "device-name": "Gerätename",
    "category": "Kategorie",
    "date-of-purchase": "Kaufdatum",
    "type": "Typ",
    "location": "Standort",
    "device-info": "Sensorinfo",
    "rows-per-page": "Zeilen pro Seite",
    "prev": "Zuruck",
    "next": "Nächste",
    "add-iot-device": "IOT-Gerät hinzufügen",
    "device-name": "Gerätename",
    "device-type": "Gerätetyp",
    "device-category": "Gerätekategorie",
    "description": "Beschreibung",
    "date-of-manufacture": "Herstellungsdatum",
    "date-of-purchase": "Kaufdatum",
    "device-id": "Geräte ID",
    "device-groups": "Gerätegruppen",
    "add-group": "Gruppe hinzufügen",
    "name": "Name",
    "iot-devices": "IOT-Geräte",
    "actions": "Aktionen",
    "rename-group": "Gruppe umbenennen",
    "edit-group": "Gruppe bearbeiten",
    "report": "Bericht",
    "add-a-group": "Gruppe hinzufügen",
    "set-up-a-rule-for-each-of-the-onboarded-iot-devices.": "Richten Sie eine Regel für jedes der integrierten IoT-Geräte ein.",
    "group-name": "Gruppenname",
    "select-devices": "Wählen Sie Geräte aus",
    "selected-devices": "Ausgewählte Geräte",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Um Regeln einzurichten, müssen Sie einige der integrierten IoT-Geräte auswählen.",
    "previous": "Zuruck",
    "cancel": "Loschen",
    "submit": "Absenden",
    "rules": "Regeln",
    "add-a-rule": "Regel hinzufügen",
    "rule": "Regel",
    "iot-devices": "IOT-Geräte",
    "actions": "Aktionen",
    "enabled": "Aktiviert",
    "disabled": "Deaktiviert",
    "add-a-rule": "Regel hinzufügen",
    "alert-me-when-all-the-selected-conditions-are-satisfied:": "Benachrichtigen Sie mich, wenn alle ausgewählten Bedingungen erfüllt sind:",
    "basic-info": "Basisinformation",
    "basic-setting-for-a-rule": "Basiseinstellungen",
    "name": "Name",
    "selected-devices": "Ausgewählte Geräte",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Um Regeln einzurichten, müssen Sie einige der integrierten IoT-Geräte auswählen.",
    "behaviour": "Verhalten",
    "specify-how-your-devices-should-behave-on-triger.": "Geben Sie an, wie sich Ihre Geräte beim Auslösen verhalten sollen.",
    "add-behaviour": "Verhalten hinzufügen",
    "observation-period": "Beobachtungszeitraum",
    "parameter": "Parameter",
    "behaviour": "Verhalten",
    "operator": "Operator",
    "value": "Wert",
    "alert-actions": "Alarmaktionen",
    "select-what-action-your-rule-should-take": "Wählen Sie aus, welche Aktion Ihre Regel ausführen soll",
    "email": "Email",
    "display-name": "Anzeigename",
    "temperature-warning": "Temperaturwarnung",
    "note": "Notiz",
    "you-should-check-the-device!": "Sie sollten das Gerät überprüfen!",
    "cancel": "Loschen",
    "submit": "Absenden",
    "dashboard": "Ubersichtsseite",
    "machine-to-machine": "Maschine zu Maschine",
    "select-device": "Wählen Sie Gerät aus",
    "select-option": "Wähle eine Option",
    "action": "Aktion",
    "previous": "Zuruck",
    "create-a-rule": "Erstellen Sie eine Regel",
    "products": "Produkte",
    "add-a-product": "Fügen Sie ein Produkt hinzu",
    "search-by-name,-id,-rfid,-serial#...": "Suche nach Name, ID, RFID, Seriennummer...",
    "product-name": "Produktname",
    "category": "Kategorie",
    "parent-product": "Übergeordnetes Produkt",
    "serial": "Seriell",
    "location": "Standort",
    "device": "Gerät",
    "status": "Status",
    "manufacturing-date": "Herstellungsdatum",
    "expiry-date": "Ablaufdatum",
    "add-a-product": "Fügen Sie ein Produkt hinzu",
    "fill-in-information-to-add-new-product": "Füllen Sie die Informationen aus, um ein neues Produkt hinzuzufügen",
    "basic-info": "Basisinformation",
    "category": "Kategorie",
    "select-option": "Wähle eine Option",
    "product-name": "Produktname",
    "untitled-product": "Unbenanntes Produkt",
    "quantity": "Menge",
    "units": "Einheiten",
    "description": "Beschreibung",
    "write-product-description-here...": "Produktbeschreibung hier schreiben...",
    "media": "Medien",
    "drag-&-drop-or-browse-to-choose-a-file": "Drag & Drop oder Durchsuchen, um eine Datei auszuwählen",
    "manufacture-info": "Herstellungsinfo",
    "serial-number": "Seriennummer",
    "manufacturing-date": "Herstellungsdatum",
    "expiry-date": "Ablaufdatum",
    "invoice-number": "Rechnungsnummer",
    "purchase-order": "Einkaufbestellung",
    "rfid-number": "RFID-Nummer",
    "batch-id": "Batch-ID",
    "location": "Standort",
    "assign-device": "Gerät zuweisen",
    "here-you-can-find-devices-assigned-to-your-product": "Hier finden Sie Geräte, die Ihrem Produkt zugeordnet sind",
    "parent-products": "Übergeordnete Produkte",
    "here-you-can-find-parent-products-assigned-to-your-product": "Hier finden Sie übergeordnete Produkte, die Ihrem Produkt zugeordnet sind",
    "type-of-event": "Art der Veranstaltung",
    "farm": "Bauernhof",
    "link-to-a-farm": "Link zu einem Bauernhof",
    "here-you-can-link-farm-items-to-a-product": "Hier können Sie Farmartikel mit einem Produkt verknüpfen",
    "please-link-farm-items-to-a-product": "Bitte verknüpfen Sie Farmartikel mit einem Produkt",
    "add-herd": "Herde hinzufügen",
    "add-building": "Gebäude hinzufügen",
    "add-field": "Feld hinzufügen",
    "add-paddock": "Koppel hinzufügen",
    "name": "Name",
    "type": "Typ",
    "total-area": "Gesamtfläche",
    "land-use": "Landnutzung",
    "crop-type": "Ernteart",
    "colour-on-map": "Farbe auf der Karte",
    "qr-code-settings": "QR-Code-Einstellungen",
    "company-data": "Unternehmen Daten",
    "select-info-that-you-want-to-show-in-qr-code": "Zeigen Sie Informationen aus, die Sie im QR-Code anzeigen möchten",
    "location": "Standort",
    "documentation": "Dokumentation",
    "production-area": "Produktionsbereich",
    "productivity": "Produktivität",
    "history-of-company": "Geschichte des Unternehmens",
    "year-of-foundation": "Gründungsjahr",
    "soils": "Böden",
    "certifications": "Zertifizierungen",
    "media": "Medien",
    "production-per-year": "Produktion pro Jahr",
    "cellar-work": "Kellerarbeit",
    "philosophy": "Philosophie",
    "assign-devices": "Geräte zuweisen",
    "here-you-can-find-devices-assigned-to-your-product": "Hier finden Sie Geräte, die Ihrem Produkt zugeordnet sind",
    "product-chain-data": "Produktkettendaten",
    "select-info-that-you-want-to-show-in-qr-code": "Wählen Sie Informationen aus, die Sie im QR-Code anzeigen möchten",
    "datetime-creation": "Datetime-Erstellung",
    "datetime-receive": "Datetime erhalten",
    "parent-products": "Elternprodukte",
    "smart-conditions": "Smarte Bedingungen",
    "rules-business-conditions": "Regeln Geschäftsbedingungen",
    "datetime-return": "Datetime-Rückgabe",
    "list-of-dispatches": "Liste der Sendungen",
    "datetime-dispatch": "Datetime-Versand",
    "basic-info": "Basisinformation",
    "linked-iot-devices": "Verknüpfte IOT-Geräte",
    "actions-info": "Aktionsinfo",
    "create-a-product": "Erstellen Sie ein Produkt",
    "categories": "Kategorien",
    "sort-by": "Sortieren nach",
    "category": "Kategorie",
    "number-of-products": "Anzahl der Produkte",
    "last-update-date": "Datum des letzten Updates",
    "date-of-creation": "Erstelldatum",
    "add-products": "Produkte hinzufügen",
    "add-a-category": "Kategorie hinzufügen",
    "here-you-can-create-new-category-and-add-products": "Hier können Sie eine neue Kategorie erstellen und Produkte hinzufügen",
    "basic-info": "Basisinformation",
    "category-name": "Kategoriename",
    "selected-products": "Ausgewählte Produkte",
    "to-create-a-category-you-need-to-select-some-products.": "Um eine Kategorie zu erstellen, müssen Sie einige Produkte auswählen.",
    "add-new-product": "Neues Produkt hinzufügen",
    "dispatch": "Absendung",
    "create-a-dispatch": "Erstellen Sie eine Sendung",
    "date-of-creation": "Erstelldatum",
    "dispatch-id": "Absendung-ID",
    "status": "Status",
    "owner": "Eigentümer",
    "no.-batch": "Nr. Charge",
    "no.-products": "Keine Produkte",
    "smart-condition-status": "Intelligenter Zustandsstatus",
    "business-condition-status": "Geschäftszustandsstatus",
    "view-the-dispatch": "Sehen Sie sich den Versand an",
    "delete-the-dispatch": "Löschen Sie die Sendung",
    "create-a-dispatch": "Erstellen Sie eine Sendung",
    "here-you-can-create-dispatch-for-your-products": "Hier können Sie den Absendung für Ihre Produkte erstellen",
    "basic-info": "Basisinformation",
    "dispatch-id": "Absendung-ID",
    "dispatch-documents": "Absendungdokumente",
    "list-of-documents-attached-to-despatch": "Liste der dem Versand beigefügten Dokumente",
    "add-document": "Dokument hinzufügen",
    "select-companies": "Unternehmen auswählen",
    "add-various-companies-to-your-dispatch": "Fügen Sie Ihrem Absendung verschiedene Firmen hinzu",
    "add-companies": "Unternehmen hinzufügen",
    "products": "Produkte",
    "dispatch-products": "Absendung von Produkten",
    "list-of-batches-and-products-attached-to-dispatch": "Chargen- und Produktliste dem Absendung beigelegt",
    "csv-example": "CSV-Beispiel",
    "upload-csv": "CSV-Datei hochladen",
    "add-batch": "Stapel hinzufügen",
    "contract-conditions": "Vertragsbedingungen",
    "add-conditions": "Bedingungen hinzufügen",
    "add-conditions-to-your-contract-dispatch": "Konditionen zu Ihrem Vertragsabsendung hinzufügen",
    "add-smart-condition": "Intelligente Bedingung hinzufügen",
    "select-batch-id": "Wählen Sie Chargen-ID aus",
    "select-device": "Wählen Sie Gerät aus",
    "parameter-observed": "Parameter eingehalten",
    "operator": "Operator",
    "value": "Wert",
    "enter-number...": "Nummer eingeben...",
    "from-date": "Vom",
    "from-time": "Von Zeit",
    "to-date": "Bis zum",
    "to-time": "Zur Zeit",
    "add-business-condition": "Geschäftsbedingung hinzufügen",
    "type-1": "Typ 1",
    "event-to-be-monitored": "Zu überwachendes Ereignis",
    "description-of-certifications-required...": "Beschreibung der Zertifizierungen erforderlichen...",
    "event-to-be-monitored": "Zu überwachendes Ereignis",
    "incoming-dispatch": "Eingehende Sendung",
    "no-incoming-dispatches-yet!": "Noch keine eingehenden Sendungen!",
    "transaction-history": "Verlauf der Transaktionen",
    "product-name": "Produktname",
    "quantity/units": "Menge/Einheiten",
    "batch-id": "Batch-ID",
    "name-of-the-dispatch": "Name der Sendung",
    "date": "Datum",
    "type": "Typ",
    "conditions": "Bedingungen",
    "status-of-the-product": "Status des Produkts",
    "sort-by": "Sortieren nach",
    "qr-scanner": "QR-Scanner",
    "qr-code-scanner": "QR-Code-Scanner",
    "here-you-can-scan-your-enismaro-qr-code": "Hier können Sie Ihren Enismaro-QR-Code scannen",
    "start-cam-scanner": "Starten Sie den Cam-Scanner",
    "stop-cam-scanner": "Stoppen Sie den Cam-Scanner",
    "scan-from--file": "Aus Datei scannen",
    "farm-map": "Farmkarte",
    "filter": "Filter",
    "tool-/-equipment": "Werkzeug / Ausrüstung",
    "herd-zones": "Herdenzonen",
    "building-zones": "Bauzonen",
    "infrastructure-zones": "Infrastrukturzonen",
    "field-zones": "Feldzonen",
    "paddock-zones": "Paddock-Zonen",
    "add-building-/-infrastructure": "Gebäude / Infrastruktur hinzufügen",
    "add-paddock-": "Koppel hinzufügen",
    "add-animal-": "Tier hinzufügen",
    "add-tool-/-equipment": "Werkzeug / Ausrüstung hinzufügen",
    "basic-info": "Basisinformation",
    "type": "Typ",
    "select-option": "Wähle eine Option",
    "name": "Name",
    "description": "Beschreibung",
    "write-description-here...": "Beschreibung hier schreiben...",
    "position": "Position",
    "brand": "Marke",
    "model": "Modell",
    "working-width-(meters)": "Arbeitsbreite (Meter)",
    "power-(kw-or-hp)": "Leistung (kw oder PS)",
    "production-year": "Produktionsjahr",
    "plate-number": "Kennzeichen",
    "frame-number": "Rahmennummer",
    "date-of-purchase": "Kaufdatum",
    "revision-date": "Änderungsdatum",
    "date-of-technical-check": "Datum der technischen Prüfung",
    "registration-document": "Anmeldebogen",
    "attach-file": "Datei anhängen",
    "insurance-document": "Versicherungsdokument",
    "user-manual": "Benutzerhandbuch",
    "photo": "Foto",
    "drag-&-drop-or-browse-to-choose-a-file": "Drag & Drop oder Durchsuchen, um eine Datei auszuwählen",
    "add-point": "Punkt hinzufügen",
    "add-field": "Feld hinzufügen",
    "draw-and-describe-your-field-on-map": "Zeichne und beschreibe dein Feld auf der Karte",
    "borders-definition": "Grenzdefinition",
    "pivot-area": "Pivot-Bereich",
    "map": "Karte",
    "basic-info": "Basisinformation",
    "field-name": "Feldname",
    "total-area": "Gesamtfläche",
    "colour-on-map": "Farbe auf der Karte",
    "crop": "Ernte",
    "variety": "Vielfalt",
    "crop-year": "Erntejahr",
    "description": "Beschreibung",
    "land-register": "Grundbuch",
    "add-cadastral-parcel": "Flurstück hinzufügen",
    "country": "Land",
    "city": "Stadt",
    "sheet-land-registry": "Blatt Grundbuch",
    "particle-land-registry": "Partikelgrundbuch",
    "area-land-registry": "Gebietsgrundbuchamt",
    "actions": "Aktionen",
    "soil": "Boden",
    "soil-map": "Bodenkarte",
    "granulometry": "Granulometrie",
    "%-sand": "% Sand",
    "%-silt": "% geschlitzt",
    "%-clay": "% Ton",
    "ph": "pH-Wert",
    "carbonates-%": "Karbonate %",
    "organic-substance-%": "Organische Substanz %",
    "organic-carbon-%": "Organischer Kohlenstoff %",
    "mineral-nitrogen": "Mineralischer Stickstoff",
    "assign-devices": "Geräte zuweisen",
    "here-you-can-find-devices-assigned-to-your-field": "Hier finden Sie Geräte, die Ihrem Feld zugeordnet sind",
    "add-building-/-infrastructure": "Gebäude / Infrastruktur hinzufügen",
    "draw-and-describe-your-building-/-infrastructure-on-map": "Zeichnen und beschreiben Sie Ihr Gebäude / Ihre Infrastruktur auf der Karte",
    "basic-info": "Basisinformation",
    "name-of-the-building-/-infrastructure": "Name des Gebäudes / der Infrastruktur",
    "type-of-building-/-infrastructure": "Art des Gebäudes / Infrastruktur",
    "colour-on-map": "Farbe auf der Karte",
    "description": "Beschreibung",
    "add-paddock": "Koppel hinzufügen",
    "draw-and-describe-your-paddock-on-map": "Zeichne und beschreibe deine Koppel auf der Karte",
    "basic-info": "Basisinformation",
    "paddock-name": "Paddock-Name",
    "total-area": "Gesamtfläche",
    "land-use": "Landnutzung",
    "colour-on-map": "Farbe auf der Karte",
    "crop": "Ernte",
    "variety": "Vielfalt",
    "crop-year": "Erntejahr",
    "add-animal": "Tier hinzufügen",
    "draw-and-describe-your-animal-on-map": "Zeichne und beschreibe dein Tier auf der Karte",
    "basic-info": "Basisinformation",
    "name-of-the-animal": "Name des Tieres",
    "type-of-animal": "Tierart",
    "breed": "Züchten",
    "status": "Status",
    "sex": "Geschlecht",
    "tag-number": "Tag-Nummer",
    "date-of-birth": "Geburtsdatum",
    "birth-weight": "Geburtsgewicht",
    "raised-or-purchased": "Aufgezogen oder gekauft",
    "disposal": "Verfügung",
    "assign-paddock-/-building-/-field": "Paddock / Gebäude / Feld zuweisen",
    "here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your-herd": "Hier finden Sie Paddocks / Gebäude / Felder, die Ihrer Herde zugeordnet sind",
    "fields-/-paddock-list": "Felder / Fahrerlagerliste",
    "date-creation-(from)": "Erstellungsdatum (von)",
    "date-creation-(to)": "Erstellungsdatum (bis)",
    "livestock-list": "Tierliste",
    "actions-list": "Aktionsliste",
    "reports": "Berichte",
    "herd-movement-records": "Herdenbewegungsaufzeichnungen",
    "feed-records": "Datensätze füttern",
    "edx-analysis": "EDX-Analyse",
    "treatments/action-details": "Details zu Behandlungen/Aktionen",
    "this-report-is-empty": "Dieser Bericht ist leer",
    "create-edx-report": "EDX-Bericht erstellen",
    "edx-analysis-create": "EDX-Analyse erstellen",
    "add--entry": "Eintrag hinzufügen",
    "name": "Name",
    "vet1-map-sum-spectrum": "VET1 Map Summenspektrum",
    "line-type": "Linientyp",
    "apparent-concentration": "Scheinbare Konzentration",
    "k-ratio": "k Ratio",
    "wt%": "Wt%",
    "wt%-sigma": "Wt% Sigma",
    "standard-label": "Standardetikett",
    "actions": "Aktionen",
    "report-name": "Berichtsname",
    "image": "Bild",
    "comments": "Kommentare",
    "add-comment": "Einen Kommentar hinzufügen",
    "comment-decsription": "Kommentarbeschreibung",
    "no-data": "Keine Daten",
    "field-logbook": "Logbuch",
    "date": "Datum",
    "name-of-the-operation": "Name des Vorgangs",
    "name-of-the-product": "Produktname",
    "weight-": "Gewicht",
    "size": "Größe",
    "cultivation-type": "Anbauart",
    "adversity": "Unglück",
    "field-details": "Felddetails",
    "paddock-details": "Paddock-Details",
    "building-details": "Gebäudedetails",
    "iot-sensors": "IOT-Sensoren",
    "custom-reports": "Benutzerdefinierte Berichte",
    "create-custom-report": "Benutzerdefinierten Bericht erstellen",
    "add-column": "Spalte hinzufügen",
    "add-entry": "Eintrag hinzufügen",
    "report-name": "Berichtsname",
    "actions": "Aktionen",
    "teams": "Mannschaften",
    "add-user": "Nutzer hinzufügen",
    "role": "Rolle",
    "grants": "Profil",
    "status": "Status",
    "add-a-user": "Fügen Sie einen Benutzer hinzu",
    "basic-info": "Basisinformation",
    "add-a-temporal-validity": "Fügen Sie eine zeitliche Gültigkeit hinzu",
    "grants": "Profil",
    "all": "Alle",
    "iot-management": "IOT-Management",
    "products": "Produkte",
    "dispatch": "Absendung",
    "farm-management": "Farmmanagement",
    "location": "Standort",
    "assign-device": "Gerät zuweisen",
    "settings": "Einstellungen",
    "account-settings": "Account Einstellungen",
    "profile-photo": "Profilbild",
    "upload": "Hochladen",
    "remove": "Entfernen",
    "full-name": "Vollständiger Name",
    "password": "Passwort",
    "change-password": "Passwort ändern",
    "old-password": "Altes Passwort",
    "new-password": "Neues Passwort",
    "repeat-new-password": "Wiederhole das neue Passwort",
    "confirm": "Bestätigen",
    "delete-account": "Konto löschen",
    "by-deleting-your-account-you-will-lose-all-your-data": "Durch das Löschen Ihres Kontos verlieren Sie all Ihre Daten",
    "do-you-confirm-delete-for-your-account?": "Bestätigen Sie das Löschen Ihres Konto?",
    "company-info": "Unternehmeninfo",
    "company-photo": "Unternehmenfoto",
    "company-name": "Firmenname",
    "year-of-foundation": "Gründungsjahr",
    "website": "Webseite",
    "history-of-the-company": "Geschichte des Unternehmens",
    "product-&-farm-info": "Produkt- und Farminformationen",
    "production-/-year": "Produktionsjahr",
    "production-area": "Produktionsbereich",
    "soils": "Böden",
    "cellar-work": "Kellerarbeit",
    "productivity": "Produktivität",
    "philosophy": "Philosophie",
    "vat-number": "Umsatzsteuer-Identifikationsnummer",
    "fiscal-code": "Steueridentifikationsnummer",
    "location": "Standort",
    "country": "Land",
    "city": "Stadt",
    "province": "Provinz",
    "certifications": "Zertifizierungen",
    "download": "Herunterladen",
    "documentation": "Dokumentation",
    "media": "Medien",
    "user-management": "Benutzerverwaltung",
    "plan-&-billing": "Abo & Abrechnung",
    "payment-method": "Zahlungsmethode",
    "expires": "Läuft ab",
    "your-plan": "Dein Abo",
    "see-plan-details": "Siehe Abodetails",
    "upgrade-plan": "Upgrade-Abo",
    "cancel-plan": "Abo kündigen",
    "iot-dashboard": "IOT-Ubersichtsseite",
    "devices": "Geräte",
    "device-groups": "Gerätegruppen",
    "rules": "Regeln",
    "products": "Produkte",
    "categories": "Kategorien",
    "teams": "Mannschaften",
    "settings": "Einstellungen",
    "billing-cycles": "Abrechnungszeitraume",
    "next-payment": "Nächste Zahlung",
    "switch-billing-cycle": "Abrechnungszeitraum wechseln",
    "new-to-enismaro?": "Neu bei Enismaro?",
    "create-an-account": "Ein Konto erstellen",
    "login": "Anmeldung",
    "password": "Passwort",
    "forgot-your-password?": "Haben Sie Ihr Passwort vergessen?",
    "all-rights-reserved": "Alle Rechte vorbehalten",
    "terms": "Bedingungen",
    "privacy": "Privatsphäre",
    "security": "Sicherheit",
    "support": "Hilfe",
    "create-an-account": "Ein Konto erstellen",
    "confirm-password": "Passwort bestätigen",
    "sign-up": "Anmeldung",
    "already-have-an-account?": "Sie haben bereits ein Konto?",
    "email-recepients": "E-Mail Empfänger"
}