<template>
    <div class="date-single-picker relative w-full" @click="showMyPicker" >
        <el-date-picker
            ref="picker"
            v-model="value"
            type="datetime"
            placeholder="Select date and time"
            prefix-icon='none' 
            format="dd.MM.yyyy,"
            :picker-options="pickerOptions"
            class="date-single-picker__container"
            />
        <p class="date-single-picker__value">  
          <span v-if="value">{{ fullDate }} </span>
        </p>
        <div class="date-single-picker__icon"  :class="{ active: value }" >
                <inline-svg :src="require(`@/assets/img/ico-calendar.svg`)" />
        </div> 

        <div ref="customFooter" class="date-picker__footer invisible">
          <div class="date-picker__time-inputs">
            <input v-mask="'##:##'" @change="changeInputTimeStart" type="tel" name="start-time" maxlength="5" v-model="timeStart" placeholder='00:00'>
            <span></span>
            <input v-mask="'##:##'" @change="changeInputTimeEnd" type="tel" name="end-time" maxlength="5" v-model="timeEnd" placeholder='23:59'>
          </div>

          <div class="date-picker__buttons">
            <button  class="date-picker__btn-cancel date-picker__btn " @click.prevent="cancelPicker" >Cancel</button>
            <button  class="date-picker__btn-apply date-picker__btn" @click.prevent="applyPicker" >Apply</button>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  import { mask } from 'vue-the-mask'
  export default {
    data() {
      return {
        directives: { mask },
        pickerOptions: {
          firstDayOfWeek: 1,
        },
        value: '',
        timeStart: '',
        timeEnd: '',
        fullDate: '',
        isValidFullDate: false,
        isValidTimeStart: false,
        isValidTimeEnd: false
      };
    },
    methods: {
      cancelPicker() {
        this.$refs.picker.handleClose()
      },
      applyPicker() {
        !this.isValidFullDate ? this.dateValidation : ''
        this.cancelPicker()
      },
      showMyPicker() {
        const customFooter = this.$refs.customFooter
        const picker = document.querySelector('.el-date-picker.has-time')

        picker.appendChild(customFooter)
        customFooter.classList.remove('invisible')
      },

      changeInputTimeStart() {
        this.validationHoursAndMinutes(this.timeStart, 'start')
        !this.isValidTimeStart ? this.timeStart = '00:00' : ''
        this.dateValidation()
      },

      changeInputTimeEnd() {
        this.validationHoursAndMinutes(this.timeEnd, 'end')
        !this.isValidTimeEnd ? this.timeEnd = '23:59' : ''
        this.dateValidation()
      },

      dateValidation() {
        if (this.value) {
          const formatDate = moment(this.value).format('DD.MM.YYYY')

          this.isValidFullDate = true
          this.fullDate = `${formatDate}, 00:00 - 23:59`

          if (this.isValidTimeStart && this.isValidTimeEnd) {
            !this.comparisonTimes(this.timeStart, this.timeEnd)
              ? this.timeEnd = this.timeStart
              : ''

            this.fullDate = `${formatDate}, ${this.timeStart} - ${this.timeEnd}`
            return
          }
          if (this.isValidTimeStart && !this.isValidTimeEnd) this.fullDate = `${formatDate}, ${this.timeStart} - 23:59`
          if (!this.isValidTimeStart && this.isValidTimeEnd) this.fullDate = `${formatDate}, 00:00 - ${this.timeEnd}`
        }
      },

      validationHoursAndMinutes(date, type) {
        if (date.length === 5) {
          const hours = date.slice(0, 2);
          const minutes = date.slice(3);
          type === 'start'
            ? this.isValidTimeStart = hours <= 23 && minutes <= 59
            : this.isValidTimeEnd = hours <= 23 && minutes <= 59
        } else {
          type === 'start' ? this.isValidTimeStart = false : this.isValidTimeEnd = false
        }

      },

      comparisonTimes(start, end) {
        const hoursStart = start.slice(0, 2);
        const minutesStart = start.slice(3);
        const hoursEnd = end.slice(0, 2);
        const minutesEnd = end.slice(3);

        return hoursStart <= hoursEnd && minutesStart <= minutesEnd
      }

    },
    watch: {
      'value': function () {
        this.dateValidation()
      }
    },
  };
</script>
