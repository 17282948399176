export default {
    "iot-management": "GESTIONE IOT",
    "iot-dashboard": "IOT dashboard",
    "devices": "Dispositivi",
    "device-groups": "Gruppi di dispositivi",
    "rules": "Regole",
    "products": "PRODOTTI",
    "products": "Prodotti",
    "categories": "Categorie",
    "dispatch": "SPEDIZIONI",
    "dispatch": "Spedizioni",
    "incoming-dispatch": "Spedizioni in arrivo",
    "transaction-history": "Cronologia delle transazioni",
    "qr-scanner": "QR Scanner",
    "farm-management": "FARM MANAGEMENT",
    "farm-map": "Mappa",
    "fields-/-paddock-list": "Lista Campi/Recinti",
    "livestock-list": "Lista Bestiame",
    "actions-list": "Quaderno di campagna",
    "reports": "Reports",
    "settings": "IMPOSTAZIONI",
    "teams": "Teams",
    "settings": "Impostazioni",
    "tutorial": "Tutorial",
    "support": "Supporto",
    "company": "Azienda",
    "settings": "Impostazioni",
    "sign-out": "Esci",
    "rows-per-page": "Righe per pagina",
    "prev": "Indietro",
    "next": "Avanti",
    "add-iot-device": "Aggiungi Dispositivo IOT",
    "all-categories": "Tutte le categorie",
    // "device-info":	"Info Dispositivo",
    "alert": "Allarme",
    "settings": "Impostazioni",
    "report": "Report",
    // "device-info":	"Info Dispositivo",
    "search-settings": "Impostazioni ricerca",
    "location": "Location",
    "groups": "Gruppi",
    "status": "Stato",
    "date-from": "Data da",
    "date-to": "A",
    "reset-all-filters": "Cancella tutti i filtri",
    "apply": "Applica",
    "devices": "Dispositivi",
    "search": "Cerca",
    "categories": "Categorie",
    "locations": "Locations",
    "status": "Stato",
    "type": "Tipo",
    "map": "Mappa",
    "device-name": "Nome Dispositivo",
    "category": "Categoria",
    "date-of-purchase": "Data di acquisto",
    "type": "Tipo",
    "location": "Location ",
    "device-info": "Info sul sensore",
    "rows-per-page": "Righe per pagina",
    "prev": "Indietro",
    "next": "Avanti",
    "add-iot-device": "Aggiungi Dispositivo IOT",
    "device-name": "Nome Dispositivo",
    "device-type": "Tipo Dispositivo",
    "device-category": "Categoria Dispositivo",
    "description": "Descrizione",
    "date-of-manufacture": "Data di produzione",
    "date-of-purchase": "Data di acquisto",
    "device-id": "ID Dispositivo",
    "device-groups": "Gruppi Dispositivi",
    "add-group": "Aggiungi un Gruppo",
    "name": "Nome",
    "iot-devices": "Dispositivo IOT",
    "actions": "Azioni",
    "rename-group": "Rinomina Gruppo",
    "edit-group": "Modifica Gruppo",
    "report": "Report",
    "add-a-group": "Aggiungi un Gruppo",
    "set-up-a-rule-for-each-of-the-onboarded-iot-devices.": "Imposta una regola per ciascuno dei dispositivi IOT",
    "group-name": "Nome Gruppo",
    "select-devices": "Seleziona i dispositivi",
    "selected-devices": "Dispositivi selezionati",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Per impostare una regola è necessario selezionare i dispositivi IOT associati",
    "previous": "Indietro",
    "cancel": "Cancella",
    "submit": "Conferma",
    "rules": "Regole",
    "add-a-rule": "Aggiungi una Regola",
    "rule": "Regola",
    "iot-devices": "Dispositivi IOT",
    "actions": "Azioni",
    "enabled": "Abilitato",
    "disabled": "Disabilitato",
    "add-a-rule": "Aggiungi una Regola",
    "alert-me-when-all-the-selected-conditions-are-satisfied:": "Avvisami quando tutte le condizioni impostate sono soddisfatte",
    "basic-info": "Informazioni di base",
    "basic-setting-for-a-rule": "Impostazioni di base per la regola",
    "name": "Nome",
    "selected-devices": "Dispositivi selezionati",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.": "Per impostare una regola è necessario selezionare i dispositivi IOT associati",
    "behaviour": "Comportamento",
    "specify-how-your-devices-should-behave-on-triger.": "Specifica i parametri per la rilevazione dell'allarme",
    "add-behaviour": "Aggiungi un comportamento",
    "observation-period": "Periodo di osservazione",
    "parameter": "Parametri",
    "behaviour": "Comportamento",
    "operator": "Operatore",
    "value": "Valore",
    "alert-actions": "Alert Azioni",
    "select-what-action-your-rule-should-take": "Seleziona quale azione viene generata dall'allarme",
    "email": "Email",
    "display-name": "Nome da visualizzare",
    "temperature-warning": "Avviso della temperatura",
    "note": "Note",
    "you-should-check-the-device!": "Dovresti controllare il dispositivo!",
    "cancel": "Cancella",
    "submit": "Conferma",
    "dashboard": "Dashboard",
    "machine-to-machine": "Macchina a macchina",
    "select-device": "Seleziona Dispositivo",
    "select-option": "Seleziona Opzione",
    "action": "Azioni",
    "previous": "Indietro",
    "create-a-rule": "Crea una regola",
    "products": "Prodotti",
    "add-a-product": "Aggiungi un Prodotto",
    "search-by-name,-id,-rfid,-serial#...": "Cerca per nome, ID, RFID, seriale...",
    "product-name": "Nome Prodotto",
    "category": "Categoria",
    "parent-product": "Prodotto padre",
    "serial": "Seriale",
    "location": "Location",
    "device": "Dispositivo",
    "status": "Stato",
    "manufacturing-date": "Data di produzione",
    "expiry-date": "Data di scadenza",
    "add-a-product": "Aggiungi un Prodotto",
    "fill-in-information-to-add-new-product": "Compila le informazioni per aggiungere un nuovo prodotto",
    "basic-info": "Informazioni di base",
    "category": "Categoria",
    "select-option": "Seleziona Opzioni",
    "product-name": "Nome Prodotto",
    "untitled-product": "Prodotto senza nome",
    "quantity": "Quantità",
    "units": "Unità",
    "description": "Descrizione",
    "write-product-description-here...": "Scrivi qui la descrizione del prodotto...",
    "media": "Media",
    "drag-&-drop-or-browse-to-choose-a-file": "Trascina e rilascia o seleziona un file",
    "manufacture-info": "Informazioni sulla Produzione",
    "serial-number": "Numero seriale",
    "manufacturing-date": "Data di produzione",
    "expiry-date": "Data di scadenza",
    "invoice-number": "Numero della fattura",
    "purchase-order": "Ordine di acquisto",
    "rfid-number": "Numero RFID",
    "batch-id": "ID del lotto",
    "location": "Location",
    "assign-device": "Assegna Dispositivo",
    "here-you-can-find-devices-assigned-to-your-product": "Qui puoi trovare i dispositivi associati al tuo prodotto",
    "parent-products": "Prodotto padre",
    "here-you-can-find-parent-products-assigned-to-your-product": "Qui puoi trovare i prodotti padre associati al tuo prodotto",
    "type-of-event": "Tipo di evento",
    "farm": "Fattoria",
    "link-to-a-farm": "Collega alla fattoria",
    "here-you-can-link-farm-items-to-a-product": "Qui puoi collegare gli elementi della fattoria al prodotto",
    "please-link-farm-items-to-a-product": "Collega il prodotto agli elementi della fattoria",
    "add-herd": "Aggiungi bestiame",
    "add-building": "Aggiungi edifici",
    "add-field": "Aggiungi campi",
    "add-paddock": "Aggiungi recinti",
    "name": "Nome",
    "type": "Tipo",
    "total-area": "Area totale",
    "land-use": "Uso del suolo",
    "crop-type": "Tipo di coltura",
    "colour-on-map": "Colore sulla mappa",
    "qr-code-settings": "Impostazioni QR-Code",
    "company-data": "Dati sull'azienda",
    "select-info-that-you-want-to-show-in-qr-code": "Seleziona le informazioni che desideri mostrare nel QR-Code",
    "location": "Location",
    "documentation": "Documentazione",
    "production-area": "Area di produzione",
    "productivity": "Produttività",
    "history-of-company": "Storia dell'azienda",
    "year-of-foundation": "Anno di fondazione",
    "soils": "Suolo",
    "certifications": "Certificazioni",
    "media": "Media",
    "production-per-year": "Produzione per anno",
    "cellar-work": "Processi",
    "philosophy": "Filosofia",
    "assign-devices": "Assegna Dispositivi",
    "here-you-can-find-devices-assigned-to-your-product": "Qui puoi trovare i dispositivi associati al tuo prodotto",
    "product-chain-data": "Dati della Catena di Produzione",
    "select-info-that-you-want-to-show-in-qr-code": "Seleziona le informazioni che desideri mostrare nel QR-Code",
    "datetime-creation": "Data e ora di creazione",
    "datetime-receive": "Data e ora di ricezione",
    "parent-products": "Prodotto padre",
    "smart-conditions": "Condizioni Smart",
    "rules-business-conditions": "Regole Condizioni Business",
    "datetime-return": "Data di rientro",
    "list-of-dispatches": "Elenco spedizioni",
    "datetime-dispatch": "Data di spedizione",
    "basic-info": "Informazioni di base",
    "linked-iot-devices": "Dispositivi IOT associati",
    "actions-info": "Info Azioni",
    "create-a-product": "Crea un Prodotto",
    "categories": "Categorie",
    "sort-by": "Ordina per",
    "category": "Categoria",
    "number-of-products": "Numero di prodotti",
    "last-update-date": "Data ultimo aggiornamento",
    "date-of-creation": "Data di creazione",
    "add-products": "Aggiungi Prodotti",
    "add-a-category": "Aggiungi una Categoria",
    "here-you-can-create-new-category-and-add-products": "Qui puoi creare una nuova categoria e aggiungerla ai prodotti",
    "basic-info": "Informazioni di base",
    "category-name": "Nome della Categoria",
    "selected-products": "Seleziona Prodotti",
    "to-create-a-category-you-need-to-select-some-products.": "Per creare una categoria devi selezionare dei prodotti",
    "add-new-product": "Aggiungi un Nuovo Prodotto",
    "dispatch": "Spedizione",
    "create-a-dispatch": "Crea una Spedizione",
    "date-of-creation": "Data di creazione",
    "dispatch-id": "ID Spedizione",
    "status": "Stato",
    "owner": "Proprietario",
    "no.-batch": "N° lotto",
    "no.-products": "N° prodotto",
    "smart-condition-status": "Stato Smart Conditions",
    "business-condition-status": "Stato Condizioni Business",
    "view-the-dispatch": "Visualizza la Spedizione",
    "delete-the-dispatch": "Elimina la Spedizione",
    "create-a-dispatch": "Crea una Spedizione",
    "here-you-can-create-dispatch-for-your-products": "Qui puoi creare una spedizione per i tuoi prodotti",
    "basic-info": "Informazioni Base",
    "dispatch-id": "ID Spedizione",
    "dispatch-documents": "Documenti di Spedizione",
    "list-of-documents-attached-to-despatch": "Elenco dei documenti allegati alla spedizione",
    "add-document": "Aggiungi Documento",
    "select-companies": "Seleziona Aziende",
    "add-various-companies-to-your-dispatch": "Aggiungi aziende collegate alla tua spedizione",
    "add-companies": "Aggiungi Azienda",
    "products": "Prodotti",
    "dispatch-products": "Spedizione dei Prodotti",
    "list-of-batches-and-products-attached-to-dispatch": "Elenco dei lotti e prodotti allegati alla spedizione",
    "csv-example": "Esempio CSV",
    "upload-csv": "Carica CSV",
    "add-batch": "Aggiungi Lotto",
    "contract-conditions": "Condizioni di Contratto",
    "add-conditions": "Aggiungi Condizioni",
    "add-conditions-to-your-contract-dispatch": "Aggiungi condizioni al tuo contratto di spedizione",
    "add-smart-condition": "Aggiungi Smart Conditions",
    "select-batch-id": "Seleziona ID del lotto",
    "select-device": "Seleziona Dispositivo",
    "parameter-observed": "Parametri osservati",
    "operator": "Operatore",
    "value": "Valore",
    "enter-number...": "Inserisci numero...",
    "from-date": "Dal giorno",
    "from-time": "Dall'ora",
    "to-date": "Fino a",
    "to-time": "Fino a",
    "add-business-condition": "Aggiungi Condizioni Business",
    "type-1": "Tipo 1",
    "event-to-be-monitored": "Evento da monitorare",
    "description-of-certifications-required...": "Descrizione delle certificazioni richieste",
    "event-to-be-monitored": "Evento da monitorare",
    "incoming-dispatch": "Spedizioni in arrivo",
    "no-incoming-dispatches-yet!": "Non ci sono ancora Spedizioni in arrivo!",
    "transaction-history": "Cronologia delle Transazioni",
    "product-name": "Nome Prodotto",
    "quantity/units": "Quantità/Unità",
    "batch-id": "ID Lotto",
    "name-of-the-dispatch": "Nome della spedizione",
    "date": "Data",
    "type": "Tipo",
    "conditions": "Condizioni",
    "status-of-the-product": "Stato del prodotto",
    "sort-by": "Ordina per",
    "qr-scanner": "QR Scanner",
    "qr-code-scanner": "QR Code Scanner",
    "here-you-can-scan-your-enismaro-qr-code": "Qui puoi scannerizzare il tuo QR-Code Enismaro",
    "start-cam-scanner": "Scansiona da Fotocamera",
    "stop-cam-scanner": "Interrompi la scansione",
    "scan-from--file": "Scansiona da File",
    "farm-map": "Gestisci mappa",
    "filter": "Filtro",
    "tool-/-equipment": "Strumento/ Attrezzatura",
    "herd-zones": "Zone del bestiame",
    "building-zones": "Zone degli edifici",
    "infrastructure-zones": "Zone delle infrastrutture",
    "field-zones": "Zone dei campi",
    "paddock-zones": "Zone delle recinzioni",
    "add-building-/-infrastructure": "Aggiungi Edificio/Infrastruttura",
    "add-paddock-": "Aggiungi Recizione",
    "add-animal-": "Aggiungi Animali",
    "add-tool-/-equipment": "Aggiungi Strumento / Attrezzatura",
    "basic-info": "Informazioni di base",
    "type": "Tipo",
    "select-option": "Seleziona Opzioni",
    "name": "Nome",
    "description": "Descrizione",
    "write-description-here...": "Scrivi qui la descrizione...",
    "position": "Posizione",
    "brand": "Marchio",
    "model": "Modello",
    "working-width-(meters)": "Larghezza (metri)",
    "power-(kw-or-hp)": "Potenza (Kw o Hp)",
    "production-year": "Anno di produzione",
    "plate-number": "Targa",
    "frame-number": "Numero di telaio",
    "date-of-purchase": "Data di acquisto",
    "revision-date": "Data di revisione",
    "date-of-technical-check": "Data del controllo tecnico",
    "registration-document": "Documento di registrazione",
    "attach-file": "Allega file",
    "insurance-document": "Documento di assicurazione",
    "user-manual": "Manuale d'uso",
    "photo": "Foto",
    "drag-&-drop-or-browse-to-choose-a-file": "Trascina e rilascia o sfoglia per scegliere un file",
    "add-point": "Aggiungi punto",
    "add-field": "Aggiungi campo",
    "draw-and-describe-your-field-on-map": "Disegna e definisci il tuo campo sulla mappa",
    "borders-definition": "Definizione dei confini",
    "pivot-area": "Area Pivot",
    "map": "Mappa",
    "basic-info": "Informazioni di base",
    "field-name": "Nome del campo",
    "total-area": "Area totale",
    "colour-on-map": "Colore sulla mappa",
    "crop": "Coltura",
    "variety": "Varietà",
    "crop-year": "Anno del raccolto",
    "description": "Descrizione",
    "land-register": "Registro fondiario",
    "add-cadastral-parcel": "Aggiungi parcella catastale",
    "country": "Paese",
    "city": "Città",
    "sheet-land-registry": "Registro catastale",
    "particle-land-registry": "Particella catastale",
    "area-land-registry": "Superficie catastale",
    "actions": "Azioni",
    "soil": "Terreno",
    "soil-map": "Mappa del terreno",
    "granulometry": "Granulometria",
    "%-sand": "% Sabbia",
    "%-silt": "% sedimenti",
    "%-clay": "% Argilla",
    "ph": "pH",
    "carbonates-%": "Carbonati %",
    "organic-substance-%": "% Sostanze organiche",
    "organic-carbon-%": "% Carbonio organico",
    "mineral-nitrogen": "Azoto minerale",
    "assign-devices": "Assegna Dispositivi",
    "here-you-can-find-devices-assigned-to-your-field": "Qui puoi visualizzare i dispositivi associati al tuo campo",
    "add-building-/-infrastructure": "Aggiungi Edificio /Infrastruttura",
    "draw-and-describe-your-building-/-infrastructure-on-map": "Disegna e definisci il tuo edificio/ infrastruttura sulla mappa",
    "basic-info": "Informazioni di base",
    "name-of-the-building-/-infrastructure": "Nome dell'edificio/ infrastruttura",
    "type-of-building-/-infrastructure": "Tipo di edificio/ infrastruttura",
    "colour-on-map": "Colore sulla mappa",
    "description": "Descrizione",
    "add-paddock": "Aggiungi recinto",
    "draw-and-describe-your-paddock-on-map": "Disegna e definisci la recinzione sulla mappa",
    "basic-info": "Informazioni di base",
    "paddock-name": "Nome della recinzione",
    "total-area": "Area totale",
    "land-use": "Utilizzo del suolo",
    "colour-on-map": "Colore sulla mappa",
    "crop": "Coltura",
    "variety": "Varietà",
    "crop-year": "Anno del raccolto",
    "add-animal": "Aggiungi Animali",
    "draw-and-describe-your-animal-on-map": "Disegna e descrivi i tuoi animali sulla mappa",
    "basic-info": "Informazioni di base",
    "name-of-the-animal": "Nome dell'animale",
    "type-of-animal": "Tipo di animale",
    "breed": "Razza",
    "status": "Stato",
    "sex": "Sesso",
    "tag-number": "Numero etichetta",
    "date-of-birth": "Data di nascita",
    "birth-weight": "Peso alla nascita",
    "raised-or-purchased": "Allevato o acquistato",
    "disposal": "Disposizione",
    "assign-paddock-/-building-/-field": "Assegna Recinzione / Edificio / Campo",
    "here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your-herd": "Qui puoi trovare recinzioni / edifici / campi assegnati al tuo bestiame",
    "fields-/-paddock-list": "Lista Campi / Recinti",
    "date-creation-(from)": "Data di creazione (da)",
    "date-creation-(to)": "Data di creazione (a)",
    "livestock-list": "Lista Bestiame",
    "actions-list": "Lista Azioni",
    "reports": "Reports",
    "herd-movement-records": "Registri dei movimenti del bestiame",
    "feed-records": "Registri dei mangimi",
    "edx-analysis": "Analisi EDX",
    "treatments/action-details": "Dettagli dei Trattamenti/Azioni",
    "this-report-is-empty": "Questo report è vuoto",
    "create-edx-report": "Crea un Report EDX",
    "edx-analysis-create": "Crea un'analisi EDX",
    "add--entry": "Aggiungi voce",
    "name": "Nome",
    "vet1-map-sum-spectrum": "Spettro somma mappa VET1",
    "line-type": "Tipo di linea",
    "apparent-concentration": "Concentrazione apparente",
    "k-ratio": "k Ratio",
    "wt%": "Wt%",
    "wt%-sigma": "Wt% Sigma",
    "standard-label": "Etichetta standard",
    "actions": "Azioni",
    "report-name": "Nome del Report",
    "image": "Immagine",
    "comments": "Commenti",
    "add-comment": "Aggiungi un Commento",
    "comment-decsription": "Descrizione del Commento",
    "no-data": "Nessun dato",
    "field-logbook": "Quaderno di campagna",
    "date": "Data",
    "name-of-the-operation": "Nome dell'operazione",
    "name-of-the-product": "Nome del prodotto",
    "weight-": "Peso",
    "size": "Grandezza",
    "cultivation-type": "Tipo di coltivazione",
    "adversity": "Avversità",
    "field-details": "Dettagli del campo",
    "paddock-details": "Dettagli della recinzione",
    "building-details": "Dettagli degli edifici",
    "iot-sensors": "Sensori IOT",
    "custom-reports": "Reports personalizzati",
    "create-custom-report": "Crea un report personalizzato",
    "add-column": "Aggiungi Colonna",
    "add-entry": "Aggiungi Voce",
    "report-name": "Nome del Report",
    "actions": "Azioni",
    "teams": "Teams",
    "add-user": "Aggiungi utente",
    "role": "Ruolo",
    "grants": "Profilo",
    "status": "Stato",
    "add-a-user": "Aggiungi un utente",
    "basic-info": "Informazioni di base",
    "add-a-temporal-validity": "Aggiungi una validità temporale",
    "grants": "Profilo",
    "all": "Tutti",
    "iot-management": "Gestione IOT",
    "products": "Prodotti",
    "dispatch": "Spedizione",
    "farm-management": "Gestione della Fattoria",
    "location": "Location",
    "assign-device": "Assegna Dispositivo",
    "settings": "Impostazioni",
    "account-settings": "Impostazioi Account",
    "profile-photo": "Foto Profilo",
    "upload": "Carica",
    "remove": "Rimuovi",
    "full-name": "Nome completo",
    "password": "Password",
    "change-password": "Cambia Password",
    "old-password": "Vecchia Password",
    "new-password": "Nuova Password",
    "repeat-new-password": "Riscrivi la nuova Password",
    "confirm": "Conferma",
    "delete-account": "Elimina Account",
    "by-deleting-your-account-you-will-lose-all-your-data": "Cancellando il tuo account perderai tutti i tuoi dati",
    "do-you-confirm-delete-for-your-account?": "Sei sicuro di voler cancellare il tuo account?",
    "company-info": "Info Azienda",
    "company-photo": "Foto Azienda",
    "company-name": "Nome Azienda",
    "year-of-foundation": "Anno di fondazione",
    "website": "Sito web",
    "history-of-the-company": "Storia dell'Azienda",
    "product-&-farm-info": "Info Prodotto & Fattoria",
    "production-/-year": "Produzione/ anno",
    "production-area": "Area di Produzione",
    "soils": "Terreno",
    "cellar-work": "Processi",
    "productivity": "Produttività",
    "philosophy": "Filosofia",
    "vat-number": "Numero IVA",
    "fiscal-code": "Codice Fiscale",
    "location": "Location",
    "country": "Paese",
    "city": "Città",
    "province": "Provincia",
    "certifications": "Certificazioni",
    "download": "Download",
    "documentation": "Documentazione",
    "media": "Media",
    "user-management": "Gestione utenti",
    "plan-&-billing": "Piano e Fatturazione",
    "payment-method": "Metodo di pagamento",
    "expires": "Scadenza",
    "your-plan": "Il tuo piano",
    "see-plan-details": "Vedi i dettagli del piano",
    "upgrade-plan": "Effettua l'upgrade del piano",
    "cancel-plan": "Cancella il Piano",
    "iot-dashboard": "Dashboard IOT",
    "devices": "Dispositivi",
    "device-groups": "Gruppi Dispositivi",
    "rules": "Regole",
    "products": "Prodotti",
    "categories": "Categorie",
    "teams": "Teams",
    "settings": "Impostazioni",
    "billing-cycles": "Fatturazione",
    "next-payment": "Prossimo pagamento",
    "switch-billing-cycle": "Cambia le opzioni di Fatturazione",
    "new-to-enismaro?": "Non sei registrato?",
    "create-an-account": "Crea un Account",
    "login": "Accedi",
    "password": "Password",
    "forgot-your-password?": "Hai dimenticato la password?",
    "all-rights-reserved": "Tutti i diritti riservati",
    "terms": "Termini",
    "privacy": "Privacy",
    "security": "Sicurezza",
    "support": "Supporto",
    "create-an-account": "Crea un Account",
    "confirm-password": "Conferma la Password",
    "sign-up": "Registrati",
    "already-have-an-account?": "Hai già un account?",
    "email-recepients": "Ricevitori e-mail"
}