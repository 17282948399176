/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripe_id
        title
        is_custom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlobalSettings = /* GraphQL */ `
  query GetGlobalSettings($id: ID!) {
    getGlobalSettings(id: $id) {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
    }
  }
`;
export const listGlobalSettings = /* GraphQL */ `
  query ListGlobalSettings(
    $filter: ModelGlobalSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlobalSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripe_public_key
        stripe_secret_key
        stripe_sandbox_publick_key
        stripe_sandbox_secret_key
        stripe_sandbox
        google_maps_api_key
        position_stack_api_key
        centaur_username
        centaur_password
        xfarm_username
        xfarm_password
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
        }
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyCertificationsId
        }
        nextToken
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        nextToken
      }
      categories {
        items {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      team
      company_photo {
        key
        company
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      nextToken
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
        companyMediaId
        productMediaId
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        title
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
        companyDocumentationId
        productDocumentationId
      }
      nextToken
    }
  }
`;
export const getCertificate = /* GraphQL */ `
  query GetCertificate($id: ID!) {
    getCertificate(id: $id) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyCertificationsId
    }
  }
`;
export const listCertificates = /* GraphQL */ `
  query ListCertificates(
    $filter: ModelCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        title
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
        companyCertificationsId
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      parent_products {
        id
        type
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
      }
      createdAt
      updatedAt
      companyProductsId
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      location {
        lat
        lng
        address
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          ruleId
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeviceCategory = /* GraphQL */ `
  query GetDeviceCategory($id: ID!) {
    getDeviceCategory(id: $id) {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceCategories = /* GraphQL */ `
  query ListDeviceCategories(
    $filter: ModelDeviceCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeviceGroup = /* GraphQL */ `
  query GetDeviceGroup($id: ID!) {
    getDeviceGroup(id: $id) {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceGroups = /* GraphQL */ `
  query ListDeviceGroups(
    $filter: ModelDeviceGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRules = /* GraphQL */ `
  query GetRules($id: ID!) {
    getRules(id: $id) {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRules = /* GraphQL */ `
  query ListRules(
    $filter: ModelRulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        deviceID
        device {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        behavior {
          observation_period
          parameter
          behavior
          day_of_the_month
          day_of_the_week
          time_of_observation_from
          time_of_observation_to
          operator
          value
          temporal_settings
          telemetry
          aggregation
        }
        alert_actions {
          sms_email
          dashboard
          m2m
          webhook
          display_name
          note
          mode
          email
          tel
          deviceID
          device_action
          callback_url
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      ruleId
      createdAt
      updatedAt
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        datetime
        description
        deviceID
        device {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        ruleId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDispatch = /* GraphQL */ `
  query GetDispatch($id: ID!) {
    getDispatch(id: $id) {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
      }
      documents {
        key
        title
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDispatches = /* GraphQL */ `
  query ListDispatches(
    $filter: ModelDispatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDispatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFarm = /* GraphQL */ `
  query GetFarm($id: ID!) {
    getFarm(id: $id) {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFarms = /* GraphQL */ `
  query ListFarms(
    $filter: ModelFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaddock = /* GraphQL */ `
  query GetPaddock($id: ID!) {
    getPaddock(id: $id) {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
        }
        radius
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
      }
      land_register
      createdAt
      updatedAt
    }
  }
`;
export const listPaddocks = /* GraphQL */ `
  query ListPaddocks(
    $filter: ModelPaddockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaddocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFarmAction = /* GraphQL */ `
  query GetFarmAction($id: ID!) {
    getFarmAction(id: $id) {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      createdAt
      updatedAt
    }
  }
`;
export const listFarmActions = /* GraphQL */ `
  query ListFarmActions(
    $filter: ModelFarmActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFarmActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        content_for_100g
        type_of_acivity
        phenological_stadium
        tool_equipment
        name_of_the_operation
        date_of_the_operation
        name_of_the_product
        active_substance
        dose_hectar
        total_quantity
        total_water_distributed
        adversity
        type_of_adversity
        reason_for_the_treatment
        name_of_the_person
        date_of_start_of_the_operation
        date_of_end_of_the_operation
        time_of_start_of_the_operation
        time_of_end_of_the_operation
        hours_worked
        wind_indensity
        weatcher_conditions
        notes_on_animals_and_plants
        notes_on_furrows
        pest_noticed
        irrigation_system_conditions
        irrigation_system_notes
        other_notes
        related
        company_id
        related_persons
        type_of_vaccine_treatment
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        description
        type_of_product
        product_category
        intervention_reason
        visible_on_qrcode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFarmReports = /* GraphQL */ `
  query GetFarmReports($id: ID!) {
    getFarmReports(id: $id) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const listFarmReports = /* GraphQL */ `
  query ListFarmReports(
    $filter: ModelFarmReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFarmReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        treatment
        datetime
        category
        related
        actions
        companyID
        related_persons
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        treatment
        datetime
        category
        related
        actions
        companyID
        related_persons
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomReport = /* GraphQL */ `
  query GetCustomReport($id: ID!) {
    getCustomReport(id: $id) {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const listCustomReports = /* GraphQL */ `
  query ListCustomReports(
    $filter: ModelCustomReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        number
        product
        images
        comments
        tableColumns
        tableData
        related_persons
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupportTickets = /* GraphQL */ `
  query GetSupportTickets($id: ID!) {
    getSupportTickets(id: $id) {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
    }
  }
`;
export const listSupportTickets = /* GraphQL */ `
  query ListSupportTickets(
    $filter: ModelSupportTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        status
        category
        subject
        description
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUpcomingCalls = /* GraphQL */ `
  query GetUpcomingCalls($id: ID!) {
    getUpcomingCalls(id: $id) {
      id
      date
      time
      user
      createdAt
      updatedAt
    }
  }
`;
export const listUpcomingCalls = /* GraphQL */ `
  query ListUpcomingCalls(
    $filter: ModelUpcomingCallsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpcomingCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        time
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFaq = /* GraphQL */ `
  query GetFaq($id: ID!) {
    getFaq(id: $id) {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyDispatch = /* GraphQL */ `
  query GetCompanyDispatch($id: ID!) {
    getCompanyDispatch(id: $id) {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyDispatches = /* GraphQL */ `
  query ListCompanyDispatches(
    $filter: ModelCompanyDispatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDispatches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        dispatchID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        dispatch {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductsCategory = /* GraphQL */ `
  query GetProductsCategory($id: ID!) {
    getProductsCategory(id: $id) {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProductsCategories = /* GraphQL */ `
  query ListProductsCategories(
    $filter: ModelProductsCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productID
        categoryID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        category {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      nextToken
    }
  }
`;
