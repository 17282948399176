<template>
    <div class="date-double-picker relative w-full" @click="showMyPicker" >
        <el-date-picker
            ref="picker"
            v-model="value"
            type="daterange"
            format="dd.MM.yyyy"
            start-placeholder="Start date"
            end-placeholder="End date"
            :unlink-panels="false"
            :picker-options="pickerOptions"
            />
        <div ref="customFooter" class="date-picker__footer double invisible">
          <div class="date-picker__buttons">
            <div class="date-double-picker__footer flex flex-col">
              <p>Selected date(s):</p>
              <span>{{ dates!==''? dates:''}}</span>
            </div>
            <button  class="date-picker__btn-cancel date-picker__btn " @click.prevent="cancelPicker" >Cancel</button>
            <!-- <button  class="date-picker__btn-apply date-picker__btn" @click.prevent="applyPicker" >Apply</button> -->
          </div>
        </div>
    </div>
  </template>
  
  <script>
import moment from 'moment';

  export default {
    data() {
      return {
        dates:'',
        pickerOptions: {
          shortcuts: [{
            text:'Today',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate());
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'Yesterday',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate()-1);
              end.setDate(end.getDate()-1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 7 days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate()-7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 30 days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate()-30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'This month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth(),1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(end.getMonth(),1);
              end.setDate(end.getDate()-1)
              start.setMonth(end.getMonth(),1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text:'This year',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setFullYear(start.getFullYear(),0,1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text:'Last year',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setFullYear(end.getFullYear(),0,1);
              end.setDate(end.getDate()-1)
              start.setFullYear(end.getFullYear(),0,1)
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'Custom',
          },],
          firstDayOfWeek: 1,
        },
        value: '',
      }; 
    },
    methods: {
      cancelPicker() {
        this.$refs.picker.handleClose()
      },
      // applyPicker() {
      //   !this.isValidFullDate ? this.dateValidation : ''
      //   this.cancelPicker()
      // },
      showMyPicker() {
        const customFooter = this.$refs.customFooter
        const picker = document.querySelector('.el-date-range-picker.has-sidebar .el-picker-panel__body')

        picker.appendChild(customFooter)
        customFooter.classList.remove('invisible')
      },
    },
    watch:{
        value(){
           this.dates =` ${moment(this.value[0]).format('DD.MM.YYYY')} - ${moment(this.value[1]).format('DD.MM.YYYY')} `
          this.$emit('dateRange',this.value)
        }
      }
  };
</script>
