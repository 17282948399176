<template>
    <div v-if="!mapFullscreen" class="card measurements-map">
        <div class="card__header flex items-center style-2">
            <span class="flex-auto">{{ $translate('location') }}</span>
            <button class="btn btn-transp"
                @click="switchMapFullscreen(!mapFullscreen)">
                <inline-svg :src="require(`@/assets/img/Full-Screen.svg`)" />
            </button>
        </div>
        <div class="map relative"
            style="max-height:auto; border-radius: 0 0 7px 7px; overflow: hidden">
            <div class="map__coordinates">
                <span>X: {{ device.location.lat.toFixed(4) }}</span>
                <span>Y: {{ device.location.lng.toFixed(4) }}</span>
            </div>
            <div id="device-map" style="width: 100%; height: 550px"></div>
            <div class="map__control">
                <div class="map__zoom mb-8">
                    <button @click="handleZoomIn" class="map__zoom-control">
                        <inline-svg
                            :src="require(`@/assets/img/ico-plus.svg`)" />
                    </button>
                    <button @click="handleZoomOut" class="map__zoom-control">
                        <inline-svg
                            :src="require(`@/assets/img/ico-minus.svg`)" />
                    </button>
                </div>
                <button @click="handleCompas" class="map__compas">
                    <inline-svg :src="require(`@/assets/img/ico-compas.svg`)" />
                </button>
            </div>
        </div>
    </div>

    <div v-else class="cards flex w-full">
        <div class="cards__col">
            <div class="flex flex-col">
                <div class="card flex-auto">
                    <div class="card__header flex items-center style-2">
                        <span class="flex-auto">{{ $translate('location') }}</span>
                        <button class="btn btn-transp"
                            @click="switchMapFullscreen(!mapFullscreen)">
                            <inline-svg
                                :src="require(`@/assets/img/Full-Screen.svg`)" />
                        </button>
                    </div>
                    <div class="map"
                        style="border-radius: 0 0 7px 7px; overflow: hidden">
                        <div id="device-map" style="width: 100%; height: 610px"></div>
                        <!-- <PaddocksMap :paddocks="paddocksWithDevices"/> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: ['device'],
    data() {
        return {
            mapFullscreen: false,
        }
    },
    computed: {
        // devices() {
        //     return this.$store.state.devices;
        // },
        paddocks() {
            return this.$store.state.paddocks;
        },
        // paddocksWithDevice() {
        //     return this.$store.state.paddocks.filter(item => this.devices.find(dFind => dFind.id === this.id).paddockID === item.id);
        // },
    },
    methods: {
        async initMap(disableDefaultUI) {
            let location = null;
            let devicePaddok = null;

            if (this.device.location) {
                location = {
                    lat: this.device.location.lat,
                    lng: this.device.location.lng
                }
            }

            if (this.device.paddockID) {
                devicePaddok = this.paddocks.find(item => item.id === this.device.paddockID);
            }

            if (devicePaddok) {
                if (devicePaddok.map_info.center_lat && devicePaddok.map_info.center_lng) {
                    location = {
                        lat: devicePaddok.map_info.center_lat,
                        lng: devicePaddok.map_info.center_lng
                    }
                }

                if (devicePaddok.map_info.points && devicePaddok.map_info.points.length) {
                    let latValue = 0;
                    let lngValue = 0;

                    for (let index = 0; index < devicePaddok.map_info.points.length; index++) {
                        latValue += devicePaddok.map_info.points[index].lat;
                        lngValue += devicePaddok.map_info.points[index].lng;
                    }
                    latValue = latValue / devicePaddok.map_info.points.length;
                    lngValue = lngValue / devicePaddok.map_info.points.length;

                    location = {
                        lat: latValue,
                        lng: lngValue
                    }
                }
            }

            let payload = {
                markerType: "product",
                disableDefaultUI: disableDefaultUI,
                ...location,
            }
            this.$provider.gmap.createMap('device-map', payload, (res) => {
                this.setLocation(res)
            });

        },
        handleZoomIn() {
            const map = this.$provider.gmap.getMap('device-map').map;
            if (!map) return
            const currentZoom = map.getZoom();
            map.setZoom(currentZoom + 1);
        },

        handleZoomOut() {
            const map = this.$provider.gmap.getMap('device-map').map;
            if (!map) return
            const currentZoom = map.getZoom();
            map.setZoom(currentZoom - 1);
        },

        handleCompas() {
            const map = this.$provider.gmap.getMap('device-map').map;
            if (!map) return
            const newCenter = {
                lat: this.device.location.lat,
                lng: this.device.location.lng
            }
            map.setCenter(newCenter);
        },

        switchMapFullscreen(value) {
            this.mapFullscreen = value
            this.$emit('change', value)
        }
    },
    watch: {
        mapFullscreen() {
            this.$nextTick(() => {
                this.initMap(!this.mapFullscreen)
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>