<template>
    <div class="datepicker-time">
        <div class="datepicker-time__body" style="display: none;">
            <div class="datepicker-time__time-block">
                <input type="text" class="input" value="12:00" placeholder="00:00">
                <span class="datepicker-time__time-hyphen"></span>
                <input type="text" class="input" value="16:20" placeholder="00:00">
            </div>
            <div class="datepicker-time__btns">
                <button type="button" class="btn btn-w-shadow btn-standart-2 w-full datepicker-time-btn">Cancel</button>
                <button type="button" class="btn btn-primary btn-standart-2 w-full datepicker-time-btn">Apply</button>
            </div>
        </div>
        <div style="height: 0; overflow: hidden;">
            <span ref="calendar"></span>
        </div>
        <a href="#" class="datepicker-timer-value datepicker-time-btn" ref="trigger">
            13.08.2024, 12:00 – 16:20
            <!-- {{ value }} -->
            <!-- ref="trigger" -->
            <div class="datepicker-timer-value__icon">
                <inline-svg :src="require(`@/assets/img/ico-calendar.svg`)" />
            </div>
        </a>

    </div>
</template>

<style></style>

<script>
import bulmaCalendar from 'bulma-calendar';
export default {
    name: 'DatePickerMap',
    props: ['value', 'dateFormat', 'canClear', 'displayMode'],
    calendar: null,
    computed: {
        formattedDate() {
            return new Date(this.value).toLocaleDateString();
        }
    },
    watch: {
        value(val) {
            if (val) {
                this.calendar.value(val);
            } else {
                this.calendar.clear()
            }
        }
    },

    methods: {
        clear() {
            this.calendar.clear();
            this.$emit('input', this.calendar.value());
            setTimeout(() => {
                this.calendar.hide();
            }, 0);
        },
        moveToBody() {
            const calendarElement = this.$refs.calendar; 
            console.log(this.calendar);
            document.body.appendChild(calendarElement); 
        },
        adjustCalendarPosition() {
            const triggerRect = this.$refs.trigger.getBoundingClientRect();
            const calendarElement = this.calendar.element;

            calendarElement.style.position = 'absolute';
            calendarElement.style.top = `${triggerRect.bottom + window.scrollY}px`;
            calendarElement.style.left = `${triggerRect.left + window.scrollX}px`;
            calendarElement.style.zIndex = '9999';
        }
    },

    mounted() {
        let checkPickersPosition = function () {
            let pickers = document.querySelectorAll('.datetimepicker');
            pickers.forEach(element => {
                let pickerClientRect = element.getBoundingClientRect();

                if (pickerClientRect.top + pickerClientRect.height + 68 > document.querySelector('body').getBoundingClientRect().height) {
                    element.classList.add('top_position');
                } else {
                    element.classList.remove('top_position');
                }
            });
        };

        this.calendar = new bulmaCalendar(this.$refs.calendar, {
            showHeader: false,
            showFooter: true,
            dateFormat: this.dateFormat || 'dd.MM.yyyy',
            startDate: this.value,
            displayMode: this.displayMode ? this.displayMode : 'default',
        });

        this.$refs.trigger.addEventListener('click', () => {
            const isOpen = this.calendar.isOpen()

            setTimeout(() => {
                if(isOpen) {
                    this.calendar.hide()
                } else {
                    this.calendar.show()
                    this.adjustCalendarPosition();
                }
            }, 0)
        });

        this.calendar.on('save', () => {
            this.$emit('input', this.calendar.value());
            setTimeout(() => {
                this.calendar.hide();
            }, 0)
        });

        this.calendar.on('hide', () => {
            this.$emit('change');
            checkPickersPosition();
        });

        this.calendar.on('show', () => {
            checkPickersPosition();
            this.moveToBody(); 
            this.adjustCalendarPosition();
        });
    },

    beforeDesctroy() {
        this.calendar = null;
    }
}
</script>