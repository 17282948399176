import $translate from '../translate';

export default {
    ADMIN: [
        {
            cat: 'IOT management',
            links: [
                {
                    title: $translate('iot-dashboard'),
                    path: '/',
                    icon: 'ico-speedo',
                    class: 'tutorial_tooltip_link_dashboard',
                },
                {
                    title: $translate('devices'),
                    path: '/devices',
                    icon: 'ico-signal',
                    class: 'tutorial_tooltip_link_devices',
                },
                {
                    title: $translate('device-groups'),
                    path: '/device-groups',
                    icon: 'ico-folder',
                    class: 'tutorial_tooltip_link_device-groups',
                },
                {
                    title: $translate('rules'),
                    path: '/rules',
                    icon: 'ico-setting',
                    class: 'tutorial_tooltip_link_rules',
                }
            ]
        },
        {
            cat: 'Products',
            links: [
                {
                    title: $translate('products'),
                    path: '/products',
                    icon: 'ico-grapes',
                    class: 'tutorial_tooltip_link_products',
                },
                {
                    title: $translate('categories'),
                    path: '/categories',
                    icon: 'bullet-list-text',
                    class: 'tutorial_tooltip_link_categories',
                }
            ]
        },
        {
            cat: 'Dispatch',
            links: [
                {
                    title: $translate('dispatch'),
                    path: '/dispatch',
                    icon: 'ico-package',
                    class: 'tutorial_tooltip_link_dispatch',
                },
                {
                    title: $translate('incoming-dispatch'),
                    path: '/incoming-dispatch',
                    icon: 'Money-track',
                    class: 'tutorial_tooltip_link_incoming-dispatch',
                },
                {
                    title: $translate('transaction-history'),
                    path: '/transaction-history',
                    icon: 'ico-arrow-clock',
                    class: 'tutorial_tooltip_link_transaction-history',
                },
                {
                    title: $translate('qr-scanner'),
                    path: '/qr-scanner',
                    icon: 'ico-qr-code',
                    class: 'tutorial_tooltip_link_qr-scanner',
                },
            ]
        },
        {
            cat: 'Farm management',
            links: [
                {
                    title: $translate('farm-map'),
                    path: '/farm-map',
                    icon: 'ico-map',
                    class: 'tutorial_tooltip_link_farm-map',
                },
                {
                    title: $translate('fields-/-paddock-list'),
                    path: '/fields-list',
                    icon: 'ico-checklist-pin',
                    class: 'tutorial_tooltip_link_fields-list',
                },
                {
                    title: $translate('livestock-list'),
                    path: '/livestock-list',
                    icon: 'ico-lamb',
                    class: 'tutorial_tooltip_link_livestock-list',
                },
                {
                    title: $translate('actions-list'),
                    path: '/actions-list',
                    icon: 'ico-checklist-round',
                    class: 'tutorial_tooltip_link_actions-list',
                },
                {
                    title: $translate('reports'),
                    path: '/reports',
                    icon: 'ico-file-chart',
                    class: 'tutorial_tooltip_link_reports',
                }
            ]
        },
        {
            cat: 'Settings',
            links: [
                {
                    title: $translate('teams'),
                    path: '/teams',
                    icon: 'ico-users',
                    class: 'tutorial_tooltip_link_teams',
                },
                {
                    title: $translate('settings'),
                    path: '/settings',
                    icon: 'ico-settings',
                    class: 'tutorial_tooltip_link_settings',
                }
            ]
        }
    ],
    BASIC: [
        {
            cat: 'IOT management',
            links: [
                {
                    title: $translate('iot-dashboard'),
                    path: '/',
                    icon: 'ico-speedo'
                },
                {
                    title: $translate('devices'),
                    path: '/devices',
                    icon: 'ico-signal'
                },
                {
                    title: $translate('device-groups'),
                    path: '/device-groups',
                    icon: 'ico-folder'
                },
                {
                    title: $translate('rules'),
                    path: '/rules',
                    icon: 'ico-setting'
                }
            ]
        },
        {
            cat: 'Products',
            links: [
                {
                    title: $translate('products'),
                    path: '/products',
                    icon: 'ico-grapes'
                },
                {
                    title: $translate('categories'),
                    path: '/categories',
                    icon: 'bullet-list-text'
                }
            ]
        },
        {
            cat: 'Settings',
            links: [
                {
                    title: $translate('teams'),
                    path: '/teams',
                    icon: 'ico-users'
                },
                {
                    title: $translate('settings'),
                    path: '/settings',
                    icon: 'ico-settings'
                }
            ]
        }
    ],
    PRO: [
        {
            cat: 'IOT management',
            links: [
                {
                    title: $translate('iot-dashboard'),
                    path: '/',
                    icon: 'ico-speedo'
                },
                {
                    title: $translate('devices'),
                    path: '/devices',
                    icon: 'ico-signal'
                },
                {
                    title: $translate('device-groups'),
                    path: '/device-groups',
                    icon: 'ico-folder'
                },
                {
                    title: $translate('rules'),
                    path: '/rules',
                    icon: 'ico-setting'
                }
            ]
        },
        {
            cat: 'Products',
            links: [
                {
                    title: $translate('products'),
                    path: '/products',
                    icon: 'ico-grapes'
                },
                {
                    title: $translate('categories'),
                    path: '/categories',
                    icon: 'bullet-list-text'
                }
            ]
        },
        {
            cat: 'Farm management',
            links: [
                {
                    title: $translate('farm-map'),
                    path: '/farm-map',
                    icon: 'ico-map'
                },
                {
                    title: $translate('fields-/-paddock-list'),
                    path: '/fields-list',
                    icon: 'ico-checklist-pin'
                },
                {
                    title: $translate('livestock-list'),
                    path: '/livestock-list',
                    icon: 'ico-lamb'
                },
                {
                    title: $translate('actions-list'),
                    path: '/actions-list',
                    icon: 'ico-checklist-round'
                },
                {
                    title: $translate('reports'),
                    path: '/reports',
                    icon: 'ico-file-chart'
                }
            ]
        },
        {
            cat: 'Settings',
            links: [
                {
                    title: $translate('teams'),
                    path: '/teams',
                    icon: 'ico-users'
                },
                {
                    title: $translate('settings'),
                    path: '/settings',
                    icon: 'ico-settings'
                }
            ]
        }
    ],
    GOLD: [
        {
            cat: 'IOT management',
            links: [
                {
                    title: $translate('iot-dashboard'),
                    path: '/',
                    icon: 'ico-speedo'
                },
                {
                    title: $translate('devices'),
                    path: '/devices',
                    icon: 'ico-signal'
                },
                {
                    title: $translate('device-groups'),
                    path: '/device-groups',
                    icon: 'ico-folder'
                },
                {
                    title: $translate('rules'),
                    path: '/rules',
                    icon: 'ico-setting'
                }
            ]
        },
        {
            cat: 'Products',
            links: [
                {
                    title: $translate('products'),
                    path: '/products',
                    icon: 'ico-grapes'
                },
                {
                    title: $translate('categories'),
                    path: '/categories',
                    icon: 'bullet-list-text'
                }
            ]
        },
        {
            cat: 'Dispatch',
            links: [
                {
                    title: $translate('dispatch'),
                    path: '/dispatch',
                    icon: 'ico-package'
                },
                {
                    title: $translate('incoming-dispatch'),
                    path: '/incoming-dispatch',
                    icon: 'Money-track'
                },
                {
                    title: $translate('transaction-history'),
                    path: '/transaction-history',
                    icon: 'ico-arrow-clock'
                },
                {
                    title: $translate('qr-scanner'),
                    path: '/qr-scanner',
                    icon: 'ico-qr-code'
                },
            ]
        },
        {
            cat: 'Farm management',
            links: [
                {
                    title: $translate('farm-map'),
                    path: '/farm-map',
                    icon: 'ico-map'
                },
                {
                    title: $translate('fields-/-paddock-list'),
                    path: '/fields-list',
                    icon: 'ico-checklist-pin'
                },
                {
                    title: $translate('livestock-list'),
                    path: '/livestock-list',
                    icon: 'ico-lamb'
                },
                {
                    title: $translate('actions-list'),
                    path: '/actions-list',
                    icon: 'ico-checklist-round'
                },
                {
                    title: $translate('reports'),
                    path: '/reports',
                    icon: 'ico-file-chart'
                }
            ]
        },
        {
            cat: 'Settings',
            links: [
                {
                    title: $translate('teams'),
                    path: '/teams',
                    icon: 'ico-users'
                },
                {
                    title: $translate('settings'),
                    path: '/settings',
                    icon: 'ico-settings'
                }
            ]
        }
    ],
    PLATINUM: [
        {
            cat: 'IOT management',
            links: [
                {
                    title: $translate('iot-dashboard'),
                    path: '/',
                    icon: 'ico-speedo'
                },
                {
                    title: $translate('devices'),
                    path: '/devices',
                    icon: 'ico-signal'
                },
                {
                    title: $translate('device-groups'),
                    path: '/device-groups',
                    icon: 'ico-folder'
                },
                {
                    title: $translate('rules'),
                    path: '/rules',
                    icon: 'ico-setting'
                }
            ]
        },
        {
            cat: 'Products',
            links: [
                {
                    title: $translate('products'),
                    path: '/products',
                    icon: 'ico-grapes'
                },
                {
                    title: $translate('categories'),
                    path: '/categories',
                    icon: 'bullet-list-text'
                }
            ]
        },
        {
            cat: 'Dispatch',
            links: [
                {
                    title: $translate('dispatch'),
                    path: '/dispatch',
                    icon: 'ico-package'
                },
                {
                    title: $translate('incoming-dispatch'),
                    path: '/incoming-dispatch',
                    icon: 'Money-track'
                },
                {
                    title: $translate('transaction-history'),
                    path: '/transaction-history',
                    icon: 'ico-arrow-clock'
                },
                {
                    title: $translate('qr-scanner'),
                    path: '/qr-scanner',
                    icon: 'ico-qr-code'
                },
            ]
        },
        {
            cat: 'Farm management',
            links: [
                {
                    title: $translate('farm-map'),
                    path: '/farm-map',
                    icon: 'ico-map'
                },
                {
                    title: $translate('fields-/-paddock-list'),
                    path: '/fields-list',
                    icon: 'ico-checklist-pin'
                },
                {
                    title: $translate('livestock-list'),
                    path: '/livestock-list',
                    icon: 'ico-lamb'
                },
                {
                    title: $translate('actions-list'),
                    path: '/actions-list',
                    icon: 'ico-checklist-round'
                },
                {
                    title: $translate('reports'),
                    path: '/reports',
                    icon: 'ico-file-chart'
                }
            ]
        },
        {
            cat: 'Settings',
            links: [
                {
                    title: $translate('teams'),
                    path: '/teams',
                    icon: 'ico-users'
                },
                {
                    title: $translate('settings'),
                    path: '/settings',
                    icon: 'ico-settings'
                }
            ]
        }
    ]
}