/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
    }
  }
`;
export const createGlobalSettings = /* GraphQL */ `
  mutation CreateGlobalSettings(
    $input: CreateGlobalSettingsInput!
    $condition: ModelGlobalSettingsConditionInput
  ) {
    createGlobalSettings(input: $input, condition: $condition) {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
    }
  }
`;
export const updateGlobalSettings = /* GraphQL */ `
  mutation UpdateGlobalSettings(
    $input: UpdateGlobalSettingsInput!
    $condition: ModelGlobalSettingsConditionInput
  ) {
    updateGlobalSettings(input: $input, condition: $condition) {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlobalSettings = /* GraphQL */ `
  mutation DeleteGlobalSettings(
    $input: DeleteGlobalSettingsInput!
    $condition: ModelGlobalSettingsConditionInput
  ) {
    deleteGlobalSettings(input: $input, condition: $condition) {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
        }
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
        }
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
        }
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyCertificationsId
        }
        nextToken
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        nextToken
      }
      categories {
        items {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      team
      company_photo {
        key
        company
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyCertificationsId
        }
        nextToken
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        nextToken
      }
      categories {
        items {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      team
      company_photo {
        key
        company
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyCertificationsId
        }
        nextToken
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        nextToken
      }
      categories {
        items {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      team
      company_photo {
        key
        company
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
    }
  }
`;
export const createCertificate = /* GraphQL */ `
  mutation CreateCertificate(
    $input: CreateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    createCertificate(input: $input, condition: $condition) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyCertificationsId
    }
  }
`;
export const updateCertificate = /* GraphQL */ `
  mutation UpdateCertificate(
    $input: UpdateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    updateCertificate(input: $input, condition: $condition) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyCertificationsId
    }
  }
`;
export const deleteCertificate = /* GraphQL */ `
  mutation DeleteCertificate(
    $input: DeleteCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    deleteCertificate(input: $input, condition: $condition) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyCertificationsId
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      parent_products {
        id
        type
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
      }
      createdAt
      updatedAt
      companyProductsId
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      parent_products {
        id
        type
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
      }
      createdAt
      updatedAt
      companyProductsId
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      parent_products {
        id
        type
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
      }
      createdAt
      updatedAt
      companyProductsId
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      location {
        lat
        lng
        address
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          ruleId
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      location {
        lat
        lng
        address
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          ruleId
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      location {
        lat
        lng
        address
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          ruleId
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
    }
  }
`;
export const createDeviceCategory = /* GraphQL */ `
  mutation CreateDeviceCategory(
    $input: CreateDeviceCategoryInput!
    $condition: ModelDeviceCategoryConditionInput
  ) {
    createDeviceCategory(input: $input, condition: $condition) {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceCategory = /* GraphQL */ `
  mutation UpdateDeviceCategory(
    $input: UpdateDeviceCategoryInput!
    $condition: ModelDeviceCategoryConditionInput
  ) {
    updateDeviceCategory(input: $input, condition: $condition) {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceCategory = /* GraphQL */ `
  mutation DeleteDeviceCategory(
    $input: DeleteDeviceCategoryInput!
    $condition: ModelDeviceCategoryConditionInput
  ) {
    deleteDeviceCategory(input: $input, condition: $condition) {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDeviceGroup = /* GraphQL */ `
  mutation CreateDeviceGroup(
    $input: CreateDeviceGroupInput!
    $condition: ModelDeviceGroupConditionInput
  ) {
    createDeviceGroup(input: $input, condition: $condition) {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceGroup = /* GraphQL */ `
  mutation UpdateDeviceGroup(
    $input: UpdateDeviceGroupInput!
    $condition: ModelDeviceGroupConditionInput
  ) {
    updateDeviceGroup(input: $input, condition: $condition) {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceGroup = /* GraphQL */ `
  mutation DeleteDeviceGroup(
    $input: DeleteDeviceGroupInput!
    $condition: ModelDeviceGroupConditionInput
  ) {
    deleteDeviceGroup(input: $input, condition: $condition) {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRules = /* GraphQL */ `
  mutation CreateRules(
    $input: CreateRulesInput!
    $condition: ModelRulesConditionInput
  ) {
    createRules(input: $input, condition: $condition) {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRules = /* GraphQL */ `
  mutation UpdateRules(
    $input: UpdateRulesInput!
    $condition: ModelRulesConditionInput
  ) {
    updateRules(input: $input, condition: $condition) {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRules = /* GraphQL */ `
  mutation DeleteRules(
    $input: DeleteRulesInput!
    $condition: ModelRulesConditionInput
  ) {
    deleteRules(input: $input, condition: $condition) {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      ruleId
      createdAt
      updatedAt
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      ruleId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      ruleId
      createdAt
      updatedAt
    }
  }
`;
export const createDispatch = /* GraphQL */ `
  mutation CreateDispatch(
    $input: CreateDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    createDispatch(input: $input, condition: $condition) {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
      }
      documents {
        key
        title
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDispatch = /* GraphQL */ `
  mutation UpdateDispatch(
    $input: UpdateDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    updateDispatch(input: $input, condition: $condition) {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
      }
      documents {
        key
        title
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDispatch = /* GraphQL */ `
  mutation DeleteDispatch(
    $input: DeleteDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    deleteDispatch(input: $input, condition: $condition) {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
      }
      documents {
        key
        title
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFarm = /* GraphQL */ `
  mutation CreateFarm(
    $input: CreateFarmInput!
    $condition: ModelFarmConditionInput
  ) {
    createFarm(input: $input, condition: $condition) {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFarm = /* GraphQL */ `
  mutation UpdateFarm(
    $input: UpdateFarmInput!
    $condition: ModelFarmConditionInput
  ) {
    updateFarm(input: $input, condition: $condition) {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFarm = /* GraphQL */ `
  mutation DeleteFarm(
    $input: DeleteFarmInput!
    $condition: ModelFarmConditionInput
  ) {
    deleteFarm(input: $input, condition: $condition) {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPaddock = /* GraphQL */ `
  mutation CreatePaddock(
    $input: CreatePaddockInput!
    $condition: ModelPaddockConditionInput
  ) {
    createPaddock(input: $input, condition: $condition) {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
        }
        radius
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
      }
      land_register
      createdAt
      updatedAt
    }
  }
`;
export const updatePaddock = /* GraphQL */ `
  mutation UpdatePaddock(
    $input: UpdatePaddockInput!
    $condition: ModelPaddockConditionInput
  ) {
    updatePaddock(input: $input, condition: $condition) {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
        }
        radius
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
      }
      land_register
      createdAt
      updatedAt
    }
  }
`;
export const deletePaddock = /* GraphQL */ `
  mutation DeletePaddock(
    $input: DeletePaddockInput!
    $condition: ModelPaddockConditionInput
  ) {
    deletePaddock(input: $input, condition: $condition) {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
        }
        radius
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
      }
      land_register
      createdAt
      updatedAt
    }
  }
`;
export const createFarmAction = /* GraphQL */ `
  mutation CreateFarmAction(
    $input: CreateFarmActionInput!
    $condition: ModelFarmActionConditionInput
  ) {
    createFarmAction(input: $input, condition: $condition) {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      createdAt
      updatedAt
    }
  }
`;
export const updateFarmAction = /* GraphQL */ `
  mutation UpdateFarmAction(
    $input: UpdateFarmActionInput!
    $condition: ModelFarmActionConditionInput
  ) {
    updateFarmAction(input: $input, condition: $condition) {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      createdAt
      updatedAt
    }
  }
`;
export const deleteFarmAction = /* GraphQL */ `
  mutation DeleteFarmAction(
    $input: DeleteFarmActionInput!
    $condition: ModelFarmActionConditionInput
  ) {
    deleteFarmAction(input: $input, condition: $condition) {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      createdAt
      updatedAt
    }
  }
`;
export const createFarmReports = /* GraphQL */ `
  mutation CreateFarmReports(
    $input: CreateFarmReportsInput!
    $condition: ModelFarmReportsConditionInput
  ) {
    createFarmReports(input: $input, condition: $condition) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const updateFarmReports = /* GraphQL */ `
  mutation UpdateFarmReports(
    $input: UpdateFarmReportsInput!
    $condition: ModelFarmReportsConditionInput
  ) {
    updateFarmReports(input: $input, condition: $condition) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const deleteFarmReports = /* GraphQL */ `
  mutation DeleteFarmReports(
    $input: DeleteFarmReportsInput!
    $condition: ModelFarmReportsConditionInput
  ) {
    deleteFarmReports(input: $input, condition: $condition) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const createCustomReport = /* GraphQL */ `
  mutation CreateCustomReport(
    $input: CreateCustomReportInput!
    $condition: ModelCustomReportConditionInput
  ) {
    createCustomReport(input: $input, condition: $condition) {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomReport = /* GraphQL */ `
  mutation UpdateCustomReport(
    $input: UpdateCustomReportInput!
    $condition: ModelCustomReportConditionInput
  ) {
    updateCustomReport(input: $input, condition: $condition) {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomReport = /* GraphQL */ `
  mutation DeleteCustomReport(
    $input: DeleteCustomReportInput!
    $condition: ModelCustomReportConditionInput
  ) {
    deleteCustomReport(input: $input, condition: $condition) {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const createSupportTickets = /* GraphQL */ `
  mutation CreateSupportTickets(
    $input: CreateSupportTicketsInput!
    $condition: ModelSupportTicketsConditionInput
  ) {
    createSupportTickets(input: $input, condition: $condition) {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
    }
  }
`;
export const updateSupportTickets = /* GraphQL */ `
  mutation UpdateSupportTickets(
    $input: UpdateSupportTicketsInput!
    $condition: ModelSupportTicketsConditionInput
  ) {
    updateSupportTickets(input: $input, condition: $condition) {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupportTickets = /* GraphQL */ `
  mutation DeleteSupportTickets(
    $input: DeleteSupportTicketsInput!
    $condition: ModelSupportTicketsConditionInput
  ) {
    deleteSupportTickets(input: $input, condition: $condition) {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
    }
  }
`;
export const createUpcomingCalls = /* GraphQL */ `
  mutation CreateUpcomingCalls(
    $input: CreateUpcomingCallsInput!
    $condition: ModelUpcomingCallsConditionInput
  ) {
    createUpcomingCalls(input: $input, condition: $condition) {
      id
      date
      time
      user
      createdAt
      updatedAt
    }
  }
`;
export const updateUpcomingCalls = /* GraphQL */ `
  mutation UpdateUpcomingCalls(
    $input: UpdateUpcomingCallsInput!
    $condition: ModelUpcomingCallsConditionInput
  ) {
    updateUpcomingCalls(input: $input, condition: $condition) {
      id
      date
      time
      user
      createdAt
      updatedAt
    }
  }
`;
export const deleteUpcomingCalls = /* GraphQL */ `
  mutation DeleteUpcomingCalls(
    $input: DeleteUpcomingCallsInput!
    $condition: ModelUpcomingCallsConditionInput
  ) {
    deleteUpcomingCalls(input: $input, condition: $condition) {
      id
      date
      time
      user
      createdAt
      updatedAt
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $input: CreateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    createFaq(input: $input, condition: $condition) {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $input: UpdateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    updateFaq(input: $input, condition: $condition) {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $input: DeleteFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    deleteFaq(input: $input, condition: $condition) {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyDispatch = /* GraphQL */ `
  mutation CreateCompanyDispatch(
    $input: CreateCompanyDispatchInput!
    $condition: ModelCompanyDispatchConditionInput
  ) {
    createCompanyDispatch(input: $input, condition: $condition) {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyDispatch = /* GraphQL */ `
  mutation UpdateCompanyDispatch(
    $input: UpdateCompanyDispatchInput!
    $condition: ModelCompanyDispatchConditionInput
  ) {
    updateCompanyDispatch(input: $input, condition: $condition) {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyDispatch = /* GraphQL */ `
  mutation DeleteCompanyDispatch(
    $input: DeleteCompanyDispatchInput!
    $condition: ModelCompanyDispatchConditionInput
  ) {
    deleteCompanyDispatch(input: $input, condition: $condition) {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProductsCategory = /* GraphQL */ `
  mutation CreateProductsCategory(
    $input: CreateProductsCategoryInput!
    $condition: ModelProductsCategoryConditionInput
  ) {
    createProductsCategory(input: $input, condition: $condition) {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProductsCategory = /* GraphQL */ `
  mutation UpdateProductsCategory(
    $input: UpdateProductsCategoryInput!
    $condition: ModelProductsCategoryConditionInput
  ) {
    updateProductsCategory(input: $input, condition: $condition) {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductsCategory = /* GraphQL */ `
  mutation DeleteProductsCategory(
    $input: DeleteProductsCategoryInput!
    $condition: ModelProductsCategoryConditionInput
  ) {
    deleteProductsCategory(input: $input, condition: $condition) {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
