<template>
	<div class="categories time-piker dropdown-wrapper ">
		<!-- <input type="time" class="time" ref="trigger" :value="value" @input="$emit('input', $event.target.value)"> -->
		<!-- <a class="datepicker_clear_btn" v-if="canClear && value && value !== initValue" @click.self.stop="clear">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none">
				<path d="M15 5L5 15" stroke="#9AA6AC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M5 5L15 15" stroke="#9AA6AC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</a> -->
		<vue-timepicker format="HH:mm" v-model="manualStringValue" @change="changeHandler" drop-direction="auto" manual-input append-to-body close-on-complete />
	</div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
	name: 'TimePicker',
	props: ['value', 'canClear', 'initValue'],
	components: { VueTimepicker },
	data(){
		return {
			manualStringValue: null,
		}
	},
	methods: {
		clear(){
			this.$emit('input', this.initValue);
		},
		changeHandler(){
			// this.$emit('input', this.manualStringValue);
			// console.log('this.manualStringValue:', this.manualStringValue, `${this.manualStringValue.HH}:${this.manualStringValue.mm}`);
			this.$emit('input', `${this.manualStringValue.HH}:${this.manualStringValue.mm}`);
		}
	},
	created(){
		this.manualStringValue = this.value;
	}
}
</script>

<style>
.vue__time-picker {
	width: 100%;
}
.vue__time-picker input.display-time {
	height: 40px;
    background: #FFFFFF;
    border-radius: 7px;
    width: 100%;
    border: 1px solid #DDE2E4;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #00283A;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    position: relative;
    min-width: 110px;
}
</style>